import * as React from "react"
import { OverlayView, OverlayViewF } from "@react-google-maps/api"
import { LatLng } from "./Resource"

type PopupGoogleProps = {
  children?: React.ReactNode
  position?: LatLng
}

export function PopupGoogle(props: PopupGoogleProps): React.JSX.Element {
  return (
    <OverlayViewF
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={props.position}
      getPixelPositionOffset={(width, height) => ({
        x: -(width / 2),
        y: -(height + 45),
      })}>
      {props.children}
    </OverlayViewF>
  )
}
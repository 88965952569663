import * as React from "react"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { InputSearch } from "@allied/react-web/Input"
import { Link } from "@allied/react-web/Router"
import { Spinner } from "@allied/react-web/Spinner"
import { useAutoPosition } from "@allied/react-web/hooks"
import { FiClock } from "react-icons/fi"
import { useHero } from "@allied/react-web/Header"
import { JobFilter } from "../features/Job/JobFilter"
import { CompanyHero } from "../features/Company/CompanyHero"
import { Job as JobService } from "../services/gsheet/Job"
import { JobPagination } from "../features/Job/JobPagination"

type JobItem = {
  id: string
  title: string
  description: string
  responsibility: string
  requirement: string
  company_name: string
  department_name: string
  employment_type: string
  posted_at?: Date
}

const jobService = new JobService()

export default function Career(): React.JSX.Element {
  const { hero } = useHero({
    factor: 0.1
  })

  const [teams] = React.useState([
    {
      image: `/image/team-collaboration.jpg`,
      values: [
        {
          name: "Collaborative Spirit",
          description: "At Allied Containers, we go beyond colleagues - we're a community. We foster an environment of mutual respect and support, where teamwork and collaboration are key to our success.",
          icon: `/image/user-collaborate-icon.svg`
        },
        {
          name: "Wellbeing First",
          description: "We prioritize mental health and well-being, recognizing that our valued employees are our greatest asset. We offer comprehensive resources and support to ensure everyone feels empowered and cared for, both professionally and personally.",
          icon: `/image/happy-face-icon.svg`
        }
      ],
    },
    {
      image: `/image/team-innovation.jpg`,
      values: [
        {
          name: "Inclusive and Diverse",
          description: "We celebrate diversity and believe in the power of every voice. Our vibrant and inclusive culture fosters transparency and honesty, focusing on outcomes that drive results.",
          icon: `/image/family-icon.svg`,
        },
        {
          name: "Innovation at the Core",
          description: "We encourage innovative thinking in a dynamic work environment where you can make a real impact in the ever-evolving logistics industry.",
          icon: `/image/rocket-icon.svg`,
        }
      ],
    },
    {
      image: `/image/team-work.jpg`,
      values: [
        {
          name: "Continuous Learning",
          description: "We believe in fostering a culture of continuous learning and personal development. We offer opportunities for training, skill development, and career advancement, helping you reach your full potential.",
          icon: `/image/global-learning-icon.svg`,
        },
        {
          name: "Work-Life Balance",
          description: "We understand the importance of work-life balance. We promote efficiency and smart work, allowing our team members to rechargeand enjoy their weekends through initiatives like early finishes on Fridays.",
          icon: `/image/love-flag-icon.svg`,
        }
      ],
    }
  ])
  const [keyword, setKeyword] = React.useState("")
  const [filter, setFilter] = React.useState({
    keyword: "",
    employment: "",
    department: "",
    page: 1,
    total_items: 5,
  })
  const [job, setJob] = React.useState({
    items: [] as JobItem[],
    total_items: 0,
  })
  const [loading, setLoading] = React.useState<boolean>(false)

  async function searchJob(filter?: Record<string, any>) {
    setLoading(true)
    const searchJob = await jobService.SearchJob({
      ...filter,
    })
    setLoading(false)
    if (searchJob.error) {
      return
    }

    setJob((prevState) => {
      return {
        ...prevState,
        items: searchJob.data.items,
        total_items: searchJob.data.summary.total_items
      }
    })
  }

  const handlePageSelected = ({ page }: { page: number }) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        page
      }
    })
  }

  const handleSubmitKeyword = () => {
    if (!keyword) {
      return
    }

    searchJob(filter)
  }

  const handleFilterUpdate = ({ filter }: { filter: Record<string, string> }) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        ...filter,
      }
    })
  }

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setFilter((prevState) => {
        return {
          ...prevState,
          keyword
        }
      })
    }, 1000)
    return () => clearTimeout(timer)
  }, [keyword])

  React.useEffect(() => {
    searchJob(filter)
  }, [filter])

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "Careers",
          description: "Allied Container Recruitment. Join our team and make a difference!"
        }}
      </Meta>

      <div ref={hero} className="relative hidden lg:block w-full h-full">
        <div className="min-h-[500px] 3xl:min-h-[700px] bg-cover" style={{ backgroundImage: `url(${"/image/team-together.jpg"})` }} />
        <div className="flex w-full h-full absolute left-0 top-0 bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.95)_19.77%,_rgba(255,_255,_255,_0)_100%)]">
          <Container size="lg">
            <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
              <div className="w-full max-w-[650px] flex flex-col gap-4 bg-[rgba(255,_255,_255,_0.15)] backdrop-blur p-2 rounded">
                <span className="font-manrope font-bold text-sm text-acem2-red">
                  Forge Your Path in Logistics
                </span>
                <div className="flex flex-col gap-2.5">
                  <p className="font-bold text-2xl lg:text-4xl">
                    <span className="text-acem2-gray">Shape the future of global trade with a rewarding career at </span>
                    <span className="text-acem2-red">Allied </span>
                  </p>
                  <hr className="w-[100px] h-1 border-acem2-red my-2" />
                </div>
                <p className="text-lg font-medium text-acem2-gray">
                  At Allied Containers, we're more than just a logistics provider; we're a community of passionate individuals shaping a connected and sustainable supply chain ecosystem.
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="lg:hidden grid grid-cols-12">
        <div className="col-span-12 w-full bg-cover bg-center h-[200px]" style={{ backgroundImage: `url(${"/image/team-together.jpg"})` }} />
        <div className="col-span-12">
          <Container size="lg">
            <div className="flex flex-col gap-2 justify-center items-start my-2">
              <span className="w-full font-manrope font-medium text-acem2-red text-sm">
                Forge Your Path in Logistics
              </span>
              <p className="font-medium text-2xl">
                Shape the future of global trade with a rewarding career at Allied Containers
              </p>
              <hr className="w-[100px] h-1 border-acem2-red my-2" />
              <p>
                At Allied Containers, we're more than just a logistics provider; we're a community of passionate individuals shaping a connected and sustainable supply chain ecosystem.
              </p>
            </div>
          </Container>
        </div>
      </div>

      <Container size="lg">
        <div className="grid grid-cols-12 gap-6 my-10 md:my-20">
          <div className="col-span-12 md:col-span-5">
            <div className="w-full h-full flex flex-col gap-2 md:gap-4 justify-center items-start">
              <p className="font-bold text-2xl lg:text-4xl">
                <span className="text-acem2-red">Allied's</span>&nbsp;
                <span className="text-acem2-gray">People</span>
              </p>
              <hr className="w-[100px] h-1 border-acem2-red" />
              <p className="text-acem2-gray text-lg md:text-xl">
                Welcome to Life at Allied Containers, where you're not just a member of our team, but part of our Allied family.
                Take a moment to experience our values, daily routines, and what sets Allied Containers apart.
              </p>
            </div>
          </div>
          <div className="col-span-12 md:col-span-1"></div>
          <div className="col-span-12 md:col-span-6">
            <div className="flex flex-col gap-2">
              <img alt="allied-team" src={`/image/allied-people.png`}
                className="object-cover w-full h-full" />
            </div>
          </div>
        </div>
      </Container>

      <div className="bg-transparent md:bg-acem2-cloud">
        <Container size="lg">
          <div className="grid grid-cols-12 gap-6">
            <div className="hidden md:flex col-span-12 md:col-span-7">
              <img alt="team-work" src={`/image/team-discussion.jpg`}
                className="object-cover w-full max-w-[1024px] min-h-[400px] aspect-cinema" />
            </div>
            <div className="col-span-12 md:col-span-5">
              <div className="w-full h-full flex flex-col gap-2 lg:gap-4 justify-center items-start">
                <span className="font-manrope font-medium text-sm text-acem2-red">
                  Why Allied
                </span>
                <p className="text-2xl xl:text-3xl 3xl:text-4xl font-medium text-acem2-gray">
                  Why Choose Us?
                </p>
                <hr className="w-[100px] h-1 border-acem2-red" />
                <p className="text-lg lg:text-xl py-4 text-acem2-gray">
                  Become part of a team that values collaboration, prioritizes well-being, and is dedicated to making a difference in the world of logistics.
                  We offer competitive compensation packages and growth opportunities for individuals who are passionate about logistics and share our commitment to excellence.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Container size="lg">
        <div className="my-10 md:my-20">
          {
            teams.map((team, i: number) => {
              return (
                <React.Fragment key={`team-item-${i}`}>
                  <div className="grid grid-cols-12">
                    <div className="col-span-6">
                      <div className="w-full h-full flex flex-col gap-6 justify-center items-start px-6 py-6">
                        {
                          team.values.map((value, j: number) => {
                            return (
                              <React.Fragment key={`team-value-item-${i}-${j}`}>
                                <div className="flex flex-col gap-5">
                                  <div className="flex flex-row gap-5 justify-start items-center">
                                    <img alt="value" src={value.icon} className="w-8 h-8 hidden lg:flex" />
                                    <p className="font-medium text-lg xl:text-2xl 3xl:text-3xl text-acem2-gray">
                                      {value.name}
                                    </p>
                                  </div>
                                  <p className="hidden lg:flex text-xl text-acem2-gray">
                                    {value.description}
                                  </p>
                                </div>
                              </React.Fragment>
                            )
                          })
                        }
                      </div>
                    </div>
                    <div className={
                      "col-span-6 " +
                      (i % 2 === 0 ? "order-first" : "")
                    }>
                      <img alt="team" src={team.image}
                        className="object-cover object-center h-full aspect-video" />
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
      </Container>

      <Container size="lg">
        <div className="mb-10 md:mb-10">
          <div className="flex flex-col justify-center items-center">
            <div className="w-full lg:w-2/3 xl:w-2/5 flex flex-col gap-4">
              <p className="font-medium text-acem2-gray text-3xl md:text-4xl text-center">
                Drive innovation in logistics
              </p>
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-12">
                  <InputSearch size="lg"
                    id="filter-keyword"
                    name="filter-keyword"
                    placeholder="Search Jobs"
                    value={keyword}
                    onChange={(e) => {
                      setKeyword(e.target.value)
                    }}
                    onSubmit={handleSubmitKeyword}
                    disabled={loading}
                    actionPosition="left"
                    showAction />
                </div>
              </div>
              <JobFilter loading={loading} onUpdate={handleFilterUpdate} />
            </div>
          </div>
          <div className="my-4 md:my-10">
            <div className="hidden md:grid grid-cols-12 gap-2 text-center">
              <div className="col-span-4">
                <span className="font-medium text-lg">Job Title</span>
              </div>
              <div className="col-span-2">
                <span className="font-medium text-lg">Job Field</span>
              </div>
              <div className="col-span-2">
                <span className="font-medium text-lg">Date Posted</span>
              </div>
              <div className="col-span-2">
                <span className="font-medium text-lg">Job Type</span>
              </div>
              <div className="col-span-2">
                <span className="font-medium text-lg">#</span>
              </div>
            </div>

            {
              loading &&
              <div className="m-4 text-center">
                <Spinner labeled label="Loading..." variant="primary" />
              </div>
            }

            {
              !loading && job.items.length === 0 &&
              <div className="m-4 text-center">
                <span className="font-medium">
                  No matched job at the moment
                </span>
              </div>
            }

            {
              !loading && job.items.length > 0 &&
              job.items.map((job, i: number) => {
                return (
                  <React.Fragment key={`job-item=${i}`}>
                    <div className="grid grid-cols-12 gap-2 border-b py-5 md:py-10">
                      <div className="col-span-12 flex flex-col gap-2 md:hidden">
                        <span className="text-sm">{job.department_name}</span>
                        <p className="font-medium underline text-xl">{job.title}</p>
                        <div className="flex flex-row gap-2 justify-between items-center">
                          <div className="flex flex-col gap-2">

                            <div className="flex flex-row gap-2 justify-start items-center">
                              <FiClock className="w-4 h-4" aria-hidden="true" />
                              <span className="text-base">
                                {
                                  job.posted_at ?
                                    job.posted_at.toLocaleDateString("en-SG", { year: "numeric", month: "2-digit", day: "2-digit" })
                                    :
                                    <>-</>
                                }
                              </span>
                            </div>
                          </div>
                          <div className="">
                            <Link to={`/jobs/${job.id}`} className="text-acem2-red text-lg">
                              View Job
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="hidden md:flex col-span-4 justify-start">
                        <p className="text-lg">{job.title}</p>
                      </div>
                      <div className="hidden md:flex col-span-2 justify-center">
                        <span className="text-lg">{job.department_name}</span>
                      </div>
                      <div className="hidden md:flex col-span-2 justify-center">
                        <span className="text-base">
                          {
                            job.posted_at ?
                              job.posted_at.toLocaleDateString("en-SG", { year: "numeric", month: "2-digit", day: "2-digit" })
                              :
                              <>-</>
                          }
                        </span>
                      </div>
                      <div className="hidden md:flex col-span-2 justify-center">
                        <span className="text-lg">{job.employment_type}</span>
                      </div>
                      <div className="hidden md:flex col-span-2 justify-center">
                        <Link to={`/jobs/${job.id}`} className="text-acem2-red text-lg">
                          View Job
                        </Link>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })
            }

            <div className="grid grid-cols-12 my-5">
              <div className="col-span-12">
                <JobPagination
                  pageRange={3}
                  itemPerPage={filter.total_items}
                  currentPage={filter.page}
                  totalItems={job.total_items}
                  onSelected={handlePageSelected}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>

      <CompanyHero />
    </>
  )
}
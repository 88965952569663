import * as React from "react"
import { HiOutlineEnvelope } from "react-icons/hi2"
import { IoCallOutline } from "react-icons/io5"
import { LuSmartphone } from "react-icons/lu"
import { FaFax } from "react-icons/fa6"
import { Link } from "@allied/react-web/Router"

type ContactTableProps = {
  children?: {
    headers?: ContactHeader[]
    items?: ContactBody[]
  }
}

type ContactHeader = {
  content: React.ReactNode
}

type ContactBody = {
  name: string
  designation?: string
  emails: string[]
  telephones: string[]
  mobiles: string[]
  fax: string[]
  department: Set<string>
}

export function ContactTable(props: ContactTableProps): React.JSX.Element {
  const {
    children = {}
  } = props

  const {
    headers = [],
    items = [],
  } = children

  return (
    <>
      <div className="hidden lg:block">
        <table className="w-full table-auto">
          {
            headers.length > 0 &&
            <thead className="bg-acem2-red text-white text-left">
              <tr>
                {
                  headers.map((header, i: number) => {
                    return (
                      <React.Fragment key={`header-item-${i}`}>
                        <th className={
                          "px-4 py-2 " +
                          (i === 0 ? "rounded-l " : " ") +
                          (i === headers.length - 1 ? "rounded-r " : " ")
                        }>
                          {header.content}
                        </th>
                      </React.Fragment>
                    )
                  })
                }
              </tr>
            </thead>
          }

          {
            items.length > 0 &&
            <tbody>
              {
                items.map((team, i: number) => {
                  return (
                    <React.Fragment key={`body-item-${i}`}>
                      <tr className="border-b-1 border-b-[#72727266]">
                        <td className="px-2 py-4">
                          <p className="font-medium text-xl">
                            {team.name}
                          </p>
                          {
                            team.designation &&
                            <span className="text-base">
                              {team.designation}
                            </span>
                          }
                        </td>

                        {
                          items.filter((team) => team.emails.length > 0).length > 0 &&
                          <td>
                            <ul>
                              {
                                team.emails.map((email: string, j: number) => {
                                  return (
                                    <li key={`email-item-${i}-${j}`}>
                                      <Link to={`mailto:${email}`}
                                        className="inline-block underline text-base"
                                        hideCrawl>
                                        {email}
                                      </Link>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </td>
                        }

                        {
                          items.filter((team) => team.telephones.length > 0).length > 0 &&
                          <td>
                            <ul>
                              {
                                team.telephones.map((telephone: string, j: number) => {
                                  return (
                                    <li key={`telephone-item-${i}-${j}`}>
                                      <Link to={`tel:${telephone.replace(" ", "")}`}
                                        className="inline-block underline text-base"
                                        hideCrawl>
                                        {telephone}
                                      </Link>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </td>
                        }

                        {
                          items.filter((team) => team.mobiles.length > 0).length > 0 &&
                          <td>
                            <ul>
                              {
                                team.mobiles.map((mobile: string, j: number) => {
                                  return (
                                    <li key={`mobile-item-${i}-${j}`}>
                                      <Link to={`tel:${mobile.replace(" ", "")}`}
                                        className="inline-block underline text-base"
                                        hideCrawl>
                                        {mobile}
                                      </Link>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </td>
                        }

                        {
                          items.filter((team) => team.fax.length > 0).length > 0 &&
                          <td>
                            <ul>
                              {
                                team.fax.map((fax: string, j: number) => {
                                  return (
                                    <li key={`fax-item-${i}-${j}`}>
                                      <Link to={`tel:${fax.replace(" ", "")}`}
                                        className="inline-block underline text-base"
                                        hideCrawl>
                                        {fax}
                                      </Link>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </td>
                        }
                      </tr>
                    </React.Fragment>
                  )
                })
              }
            </tbody>
          }
        </table>
      </div>
      <div className="block lg:hidden">
        {
          items.length > 0 &&
          <>
            {
              items.map((team, i: number) => {
                return (
                  <React.Fragment key={`m-body-item-${i}`}>
                    <div className="w-full flex flex-col gap-6 py-4 [&:not(:last-child)]:border-b">
                      <div>
                        <p className="font-medium text-xl">
                          {team.name}
                        </p>
                        {
                          team.designation &&
                          <span className="text-base">
                            {team.designation}
                          </span>
                        }
                      </div>

                      <div className="flex flex-col gap-2">
                        {
                          items.filter((team) => team.emails.length > 0).length > 0 &&
                          team.emails.length > 0 &&
                          <div className="flex flex-row gap-2.5">
                            <HiOutlineEnvelope className="w-[18px] h-[22px] text-acem2-primary" aria-hidden="true" />
                            <ul>
                              {
                                team.emails.map((email: string, j: number) => {
                                  return (
                                    <li key={`email-item-${i}-${j}`}>
                                      <Link to={`mailto:${email}`}
                                        className="inline-block underline text-base"
                                        hideCrawl>
                                        {email}
                                      </Link>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        }

                        {
                          items.filter((team) => team.telephones.length > 0).length > 0 &&
                          team.telephones.length > 0 &&
                          <div className="flex flex-row gap-2.5">
                            <IoCallOutline className="w-[18px] h-[22px] text-acem2-primary" aria-hidden="true" />
                            <ul>
                              {
                                team.telephones.map((telephone: string, j: number) => {
                                  return (
                                    <li key={`telephone-item-${i}-${j}`}>
                                      <Link to={`tel:${telephone.replace(" ", "")}`}
                                        className="inline-block underline text-base"
                                        hideCrawl>
                                        {telephone}
                                      </Link>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        }

                        {
                          items.filter((team) => team.mobiles.length > 0).length > 0 &&
                          team.mobiles.length > 0 &&
                          <div className="flex flex-row gap-2.5">
                            <LuSmartphone className="w-[18px] h-[22px] text-acem2-primary" aria-hidden="true" />
                            <ul>
                              {
                                team.mobiles.map((mobile: string, j: number) => {
                                  return (
                                    <li key={`mobile-item-${i}-${j}`}>
                                      <Link to={`tel:${mobile.replace(" ", "")}`}
                                        className="inline-block underline text-base"
                                        hideCrawl>
                                        {mobile}
                                      </Link>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        }

                        {
                          items.filter((team) => team.fax.length > 0).length > 0 &&
                          team.fax.length > 0 &&
                          <div className="flex flex-row gap-2.5">
                            <FaFax className="w-[18px] h-[22px] text-acem2-primary" aria-hidden="true" />
                            <ul>
                              {
                                team.fax.map((fax: string, j: number) => {
                                  return (
                                    <li key={`fax-item-${i}-${j}`}>
                                      <Link to={`tel:${fax.replace(" ", "")}`}
                                        className="inline-block underline text-base"
                                        hideCrawl>
                                        {fax}
                                      </Link>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        }
                      </div>
                    </div>
                  </React.Fragment>
                )
              })
            }
          </>
        }
      </div>
    </>
  )
}
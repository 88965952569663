import {
  Status,
  ACTION_SUCCESS,
  RESOURCE_NOTFOUND,
  UNEXPECTED_ERROR,
} from "../Status"
import {
  SearchServiceParam, SearchServiceResult,
  GetServiceParam, GetServiceResult,
} from "../Service"

type ServiceItem = {
  slug: string
  name: string
  shortName?: string
  description?: string
  thumbnail: string
  primary?: string
  icon: string
  darkIcon: string
  categories: string[]
  meta: Map<string, any>,
  advantages: AdvantageItem[]
}

type AdvantageItem = {
  name: string
  description: string
}

export class Service {

  private services: ServiceItem[] = []

  constructor() {
    this.services = [
      {
        slug: "storage-handling",
        name: "Storage and Handling",
        shortName: "Storage and Handling",
        description: "Looking for secure and efficient storage solutions? Our container storage and handling services maximize space and ensure easy access to your goods whenever you need them.",
        thumbnail: `/image/container-thumbnail.png`,
        primary: `/image/container-primary.jpg`,
        icon: `/image/shelves-icon.svg`,
        darkIcon: `/image/shelves-icon-dark.svg`,
        categories: ["container"],
        meta: new Map([
          ["ctaTitle", "Store securely, handle efficiently. Our container storage and handling services keep your goods safe and accessible, optimizing space and reducing downtime."]
        ]),
        advantages: [
          {
            name: "Strategic Locations",
            description: "Our depots are strategically located to provide easy access to major transportation hubs, reducing transit times and costs."
          },
          {
            name: "Qualified Staff",
            description: "Our qualified staff has extensive experience in container handling and maintenance, ensuring efficient and reliable services."
          },
          {
            name: "Modern Equipment",
            description: "Our fleet of advanced equipment, including double box stackers and forklifts, maximizes capacity and efficiency."
          },
          {
            name: "Quality Assurance",
            description: "We adhere to strict quality standards and conduct rigorous internal checks to ensure the integrity of our services."
          },
          {
            name: "Customer Focus",
            description: "We prioritize customer satisfaction and work closely with you to understand your needs and provide tailored solutions"
          }
        ],
      },
      {
        slug: "repair-maintenance",
        name: "Repair and Maintenance",
        shortName: "Repair and Maintenance",
        description: "Need to keep your containers in top shape? Our container repair and maintenance services ensure durability and efficiency, extending the life of your assets while minimizing downtime.",
        thumbnail: `/image/repair-and-maintenance-thumbnail.png`,
        primary: `/image/repair-and-maintenance-primary.jpg`,
        icon: `/image/tools-icon.svg`,
        darkIcon: `/image/tools-icon-dark.svg`,
        categories: ["container"],
        meta: new Map([
          ["ctaTitle", "Operate longer, worry less. Our repair and maintenance services ensure your equipment stays in top condition, reducing downtime and extending lifespan. Contact us."]
        ]),
        advantages: [
          {
            name: "Structural Repairs",
            description: "We specialize in repairing structural damage, including cracks, dents, and corrosion, ensuring the integrity of your containers."
          },
          {
            name: "Door and Seal Maintenance",
            description: "We maintain and repair container doors and seals to prevent damage and ensure proper closure."
          },
          {
            name: "Electrical and Refrigerated Unit Repairs",
            description: "Our technicians are skilled in diagnosing and repairing electrical systems and refrigerated units, ensuring your reefer containers operate efficiently"
          },
          {
            name: "Cleaning and Sanitation",
            description: "We offer thorough cleaning and sanitation services to maintain the cleanliness and hygiene of your containers, especially for food-grade and pharmaceutical shipments."
          },
          {
            name: "Safety Inspections",
            description: "We conduct regular safety inspections to identify potential issues and ensure your containers comply with industry standards."
          }
        ],
      },
      {
        slug: "data-interchange-solutions",
        name: "Data Interchange Solutions",
        shortName: "Data Interchange Solutions",
        description: "Looking to streamline your data exchange? Our data interchange solutions facilitate seamless communication across your supply chain, enhancing accuracy and operational efficiency.",
        thumbnail: `/image/data-interchange-solutions-thumbnail.png`,
        primary: `/image/data-interchange-solutions-primary.jpg`,
        icon: `/image/coins-icon.svg`,
        darkIcon: `/image/coins-icon-dark.svg`,
        categories: ["container"],
        meta: new Map([
          ["ctaTitle", "Connect seamlessly, share effortlessly. Our data interchange solutions streamline communication across your supply chain, enhancing accuracy and efficiency. Contact us."]
        ]),
        advantages: [
          {
            name: "Data Interchange Solutions",
            description: `<ul class="pl-6 list-outside list-disc flex flex-col"><li>Seamlessly exchange business documents (purchase orders, invoices, shipping notices) with trading partners.</li><li>Adhere to industry-standard EDI formats for compatibility.</li><li>Minimize manual data entry and errors.</li></ul>`
          },
          {
            name: "Application Programming Interfaces (APIs)",
            description: `<ul class="pl-6 list-outside list-disc flex flex-col"><li>Integrate our systems with your existing software for seamless data flow.</li><li>Automate data exchange processes, reducing manual effort.</li><li>Facilitate real-time data sharing for up-to-date information.</li></ul>`
          },
          {
            name: "Custom Solutions",
            description: `<ul class="pl-6 list-outside list-disc flex flex-col"><li>Tailor data interchange solutions to your specific needs.</li><li>Flexibility and scalability to adapt to your evolving business.</li></ul>`
          }
        ],
      },
      {
        slug: "container-customized-solution",
        name: "Customized Solution",
        shortName: "Customized Solution",
        description: "",
        thumbnail: `/image/container-thumbnail.png`,
        primary: `/image/container-primary.jpg`,
        icon: `/image/dashboard-icon.svg`,
        darkIcon: `/image/dashboard-icon-dark.svg`,
        categories: ["container"],
        meta: new Map([]),
        advantages: [],
      },

      {
        slug: "inventory-management",
        name: "Inventory Management",
        description: "Want to optimize your supply chain? Allied Containers offers comprehensive inventory management solutions designed to achieve operational excellence and drive business success.",
        thumbnail: `/image/inventory-management.png`,
        primary: `/image/inventory-management-primary.jpg`,
        icon: `/image/package-search-icon.svg`,
        darkIcon: `/image/package-search-icon-dark.svg`,
        categories: ["warehouse"],
        meta: new Map([
          ["ctaTitle", "Stock smarter, not harder. Our inventory management solutions streamline your operations, reducing costs and boosting accuracy. Contact us."],
        ]),
        advantages: [
          {
            name: "Strategic Inventory Planning",
            description: "We work with you to develop a tailored inventory strategy that aligns with your business objectives and market demands.",
          },
          {
            name: "Accurate Inventory Control",
            description: "Our advanced systems ensure precise inventory tracking and management, preventing stock outs and overstocking.",
          },
          {
            name: "Demand Forecasting",
            description: "We analyze historical data and market trends to optimize inventory levels and minimize carrying costs.",
          },
          {
            name: "Warehouse Optimization",
            description: "We maximize warehouse space utilization and improve order fulfillment efficiency.",
          },
          {
            name: "Supply Chain Visibility",
            description: "Gain complete control over your inventory with real-time data and reporting.",
          },
        ],
      },
      {
        slug: "cross-docking",
        name: "Cross-Docking",
        shortName: "Cross-Docking",
        description: "Looking to speed up your logistics? Cross-docking eliminates the need for long-term storage, facilitating the swift movement of goods from inbound to outbound transportation.",
        thumbnail: `/image/cross-docking.png`,
        primary: `/image/cross-docking-primary.jpg`,
        icon: `/image/truck-phosphor-icon.svg`,
        darkIcon: `/image/truck-phosphor-icon-dark.svg`,
        categories: ["warehouse"],
        meta: new Map([
          ["ctaTitle", "Ship smarter, not harder. Our cross-docking services optimize your supply chain for maximum efficiency Contact us."],
        ]),
        advantages: [
          {
            name: "Reduced Inventory Costs",
            description: "Minimize storage expenses and free up valuable warehouse space by bypassing traditional storage methods.",
          },
          {
            name: "Scalability",
            description: "Our cross-docking solutions can adapt to your changing business needs, ensuring flexibility and growth.",
          },
          {
            name: "Enhanced Operational Efficiency",
            description: "Streamline your supply chain operations, reducing handling time and labor costs.",
          },
          {
            name: "Increased Customer Satisfaction",
            description: "Meet customer demands faster with reduced order processing times and timely deliveries.",
          },
          {
            name: "Faster Delivery Times",
            description: "Expedite order fulfillment and reduce lead times through swift product movement from inbound to outbound transportation",
          }
        ],
      },
      {
        slug: "coldchain-logistic",
        name: "Cold Chain Logistics",
        shortName: "Cold Chain Logistics",
        description: "Need reliable temperature control for your products? Our cold chain logistics solutions ensure the safe and efficient transport of temperature-sensitive goods, preserving quality from start to finish",
        thumbnail: `/image/cold-chain-logistic.png`,
        primary: `/image/cold-chain-logistic-primary.jpg`,
        icon: `/image/thermometer-snowflake-icon.svg`,
        darkIcon: `/image/thermometer-snowflake-icon-dark.svg`,
        categories: ["warehouse"],
        meta: new Map([
          ["ctaTitle", "Stay cool under pressure. Our cold chain logistics services maintain product integrity throughout transit, ensuring quality from start to finish. Contact us."],
        ]),
        advantages: [
          {
            name: "Specialized Equipment",
            description: "Our fleet includes refrigerated trucks, containers, and warehousing facilities to maintain optimal temperatures.",
          },
          {
            name: "Temperature Control Expertise",
            description: "Our team has the knowledge and experience to manage temperature-sensitive cargo effectively.",
          },
          {
            name: "Real-Time Tracking",
            description: "Track the temperature and location of your shipment throughout its journey for complete peace of mind.",
          },
          {
            name: "Uninterrupted Cold Chain",
            description: "We ensure seamless cold chain management across all transportation modes (air, sea, land).",
          },
          {
            name: "Reduced Spoilage",
            description: "Minimize product loss and ensure your goods arrive fresh and ready for sale.",
          },
        ],
      },
      {
        slug: "last-mile-delivery",
        name: "Last-Mile Delivery",
        shortName: "Last-Mile Delivery",
        description: "Want to ensure your packages arrive on time? Our last-mile delivery solutions optimize the final step of your logistics process, providing speed and reliability for every delivery.",
        thumbnail: `/image/last-mile-delivery.png`,
        primary: `/image/last-mile-delivery-primary.jpg`,
        icon: `/image/delivery-drone-icon.svg`,
        darkIcon: `/image/delivery-drone-icon-dark.svg`,
        categories: ["warehouse"],
        meta: new Map([
          ["ctaTitle", "Deliver faster, stress less. Our last-mile delivery solutions streamline the final step of your supply chain, ensuring packages reach their destination on time. Contact us."],
        ]),
        advantages: [
          {
            name: "Speed & Efficiency",
            description: "We prioritize fast and reliable delivery to keep your customers satisfied.",
          },
          {
            name: "Flexible Scheduling",
            description: "Choose the delivery timeframe that best suits your customers' needs.",
          },
          {
            name: "Real-Time Tracking",
            description: "Both you and your customers can track the shipment's progress in real-time for complete peace of mind.",
          },
          {
            name: "Proven Track Record",
            description: "Our experienced team has a proven record of successful last-mile deliveries.",
          },
          {
            name: "Multiple Delivery Options",
            description: "We offer a variety of delivery options to cater to your specific needs (e.g., signature required, same-day delivery).",
          },
        ],
      },


      {
        slug: "air-freight",
        name: "Air Freight",
        shortName: "Air Freight",
        description: "Need to get your cargo across the globe quickly? Allied Containers offers air freight solutions designed for speed and efficiency.",
        thumbnail: `/image/air-freight.png`,
        primary: `/image/air-freight-primary.jpg`,
        icon: `/image/plane-icon.svg`,
        darkIcon: `/image/plane-icon-dark.svg`,
        categories: ["freight"],
        meta: new Map([
          ["ctaTitle", "Fly faster, go further. Our air freight services expedite your shipments worldwide, ensuring speed and reliability every step of the way. Contact us."],
        ]),
        advantages: [
          {
            name: "Time-Critical Delivery",
            description: "We prioritize speed, ensuring your urgent shipments reach their destination on time.",
          },
          {
            name: "Global Network",
            description: "Our extensive network of airlines allows us to offer competitive rates and flexible routing options.",
          },
          {
            name: "Expert Handling",
            description: "Our experienced team ensures proper documentation, secure packing, and seamless customs clearance.",
          },
          {
            name: "Real-Time Tracking",
            description: "Track your shipment's progress every step of the way for complete peace of mind.",
          },
          {
            name: "Effective Solutions",
            description: "We work with you to find the most efficient air freight option for your specific needs.",
          }
        ],
      },
      {
        slug: "ocean-freight",
        name: "Ocean Freight (FCL & LCL)",
        shortName: "Ocean Freight",
        description: "Whether you're shipping large volumes of cargo or need a cost-effective option for smaller shipments, Allied Containers offers flexible ocean freight solutions to meet your needs.",
        thumbnail: `/image/ocean-freight.png`,
        primary: `/image/ocean-freight-primary.jpg`,
        icon: `/image/cargo-boat-icon.svg`,
        darkIcon: `/image/cargo-boat-icon-dark.svg`,
        categories: ["freight"],
        meta: new Map([
          ["ctaTitle", "Sail smoothly, ship efficiently. Our ocean freight services, both FCL and LCL, provide flexible and reliable options for global transport. Contact us."],
          ["note", `<p class="font-medium text-xl">FCL (Full Container Load) - Ideal for large shipments</p><ul class="pl-4 list-outside list-disc flex flex-col gap-2"><li>Maximize efficiency and cost savings for high-volume cargo.</li><li>Secure dedicated containers for complete control and faster delivery.</li></ul><p class="font-medium text-xl">LCL (Less-than-Container Load) - Perfect for smaller shipments</p><ul class="pl-4 list-outside list-disc flex flex-col gap-2"><li>Share container space for a cost-effective option.</li><li>Ideal for businesses just starting out or with fluctuating needs.</li></ul>`],
        ]),
        advantages: [
          {
            name: "Competitive Rates",
            description: "Benefit from our strong carrier relationships and global network to secure the best prices.",
          },
          {
            name: "Expert Route Planning",
            description: "Our experienced team optimizes shipping routes for efficiency and cost-effectiveness.",
          },
          {
            name: "Seamless Documentation",
            description: "We handle all necessary paperwork to ensure smooth customs clearance.",
          },
          {
            name: "Real-Time Tracking",
            description: "Monitor your shipment's progress every step of the way for complete peace of mind.",
          },
          {
            name: "Global Network",
            description: "Our extensive network of ports and partners ensures seamless delivery worldwide.",
          },
        ],
      },
      {
        slug: "custom-clearance",
        name: "Customs Clearance",
        shortName: "Customs Clearance",
        description: "Navigating customs regulations can feel like a maze. At Allied Containers, we take the stress out of the process with our efficient and reliable customs clearance services.",
        thumbnail: `/image/custom-clearance.png`,
        primary: `/image/custom-clearance-primary.jpg`,
        icon: `/image/multiple-file-icon.svg`,
        darkIcon: `/image/multiple-file-icon-dark.svg`,
        categories: ["freight"],
        meta: new Map([
          ["ctaTitle", "Clear faster, comply easier. Our customs clearance services simplify border processes, ensuring swift and hassle-free shipments. Contact us."],
        ]),
        advantages: [
          {
            name: "Expert Guidance",
            description: "Our experienced customs brokers handle all the paperwork and ensure your shipments comply with regulations.",
          },
          {
            name: "Seamless Processing",
            description: "We work closely with customs authorities to expedite clearance and minimize delays.",
          },
          {
            name: "Reduced Costs",
            description: "Benefit from our expertise to avoid costly penalties or hold-ups.",
          },
          {
            name: "Global Network",
            description: "Leverage our connections to navigate customs procedures worldwide.",
          },
          {
            name: "Real-Time Tracking",
            description: "Stay informed every step of the way with clear communication and shipment tracking.",
          },
        ],
      },

      {
        slug: "warehouse-management-system",
        name: "Warehouse Management System",
        description: "Looking to optimize your warehousing operations? Allied Containers provides an advanced warehouse management system designed to streamline processes, cut costs, and enhance overall efficiency.",
        thumbnail: `/image/warehouse-management-system.jpg`,
        primary: `/image/warehouse-management-system-primary.jpg`,
        icon: `/image/warehouse-icon.svg`,
        darkIcon: `/image/warehouse-icon-dark.svg`,
        categories: ["software"],
        meta: new Map([
          ["ctaTitle", "Manage smarter, operate seamlessly. Our warehouse management system optimizes inventory control and boosts efficiency across your storage operations. Contact us."],
        ]),
        advantages: [
          {
            name: "Real-time Inventory Tracking",
            description: "Gain complete visibility into your inventory levels, locations, and movements."
          },
          {
            name: "Order Fulfillment Optimization",
            description: "Streamline order picking and packing processes for faster turnaround times."
          },
          {
            name: "Put-away Automation",
            description: "Automate the process of putting away goods in designated storage locations."
          },
          {
            name: "Cycle Counting",
            description: "Implement regular cycle counting to ensure inventory accuracy and prevent stockouts."
          },
          {
            name: "Quality Control",
            description: "Track product quality and identify any issues during the warehousing process."
          },
          {
            name: "Integration with Other Systems",
            description: "Seamlessly integrate our warehouse management system with your existing ERP, transportation management, and other systems."
          }
        ],
      },
      {
        slug: "transport-management-system",
        name: "Transport Management System",
        description: "Struggling with transportation management? Allied Containers offers a state-of-the-art Transportation Management System (TMS) designed to optimize logistics operations, reduce costs, and enhance efficiency.",
        thumbnail: `/image/transport-management-system.jpg`,
        primary: `/image/transport-management-system-primary.jpg`,
        icon: `/image/cargo-transport-icon.svg`,
        darkIcon: `/image/cargo-transport-icon-dark.svg`,
        categories: ["software"],
        meta: new Map([
          ["ctaTitle", "Transport smarter, deliver better. Our transport management system streamlines your logistics, ensuring timely and efficient deliveries every time. Contact us."],
        ]),
        advantages: [
          {
            name: "Route Optimization",
            description: "Our advanced algorithms analyze various factors to determine the most efficient routes, minimizing transportation costs and transit times."
          },
          {
            name: "Carrier Management",
            description: "Manage your network of carriers effectively, negotiate rates, and track performance to ensure optimal service."
          },
          {
            name: "Shipment Tracking",
            description: "Gain real-time visibility into your shipments, from pickup to delivery, with our advanced tracking capabilities."
          },
          {
            name: "Yard Management",
            description: "Optimize your yard operations, including gate management, equipment allocation, and space utilization."
          },
          {
            name: "Reporting and Analytics",
            description: "Generate comprehensive reports on key performance indicators (KPIs) to track your logistics performance and identify areas for improvement."
          },
          {
            name: "Integration with Other Systems",
            description: "Seamlessly integrate our TMS with your existing ERP, warehouse management, and other systems for a unified solution."
          }
        ],
      },
      {
        slug: "depot-management-system",
        name: "Depot Management System",
        description: "Looking to enhance your depot operations? Allied Containers provides an advanced Depot Management System (DMS) designed to streamline processes, improve productivity, and reduce costs.",
        thumbnail: `/image/depot-management-system.jpg`,
        primary: `/image/depot-management-system-primary.jpg`,
        icon: `/image/container-icon.svg`,
        darkIcon: `/image/container-icon-dark.svg`,
        categories: ["software"],
        meta: new Map([
          ["ctaTitle", "Manage efficiently, operate effectively. Our depot management system enhances workflow and optimizes resource allocation for peak performance. Contact us."],
        ]),
        advantages: [
          {
            name: "Yard Management",
            description: "Optimize the utilization of your depot space, minimizing congestion and maximizing storage capacity."
          },
          {
            name: "Equipment Tracking",
            description: "Track the location and status of your equipment, including containers, chassis, and other assets."
          },
          {
            name: "Gate Management",
            description: "Streamline the gate process, reducing waiting times and improving security."
          },
          {
            name: "Labor Management",
            description: "Optimize workforce utilization and improve productivity through efficient task assignment and scheduling."
          },
          {
            name: "Yard Inventory",
            description: "Maintain accurate inventory records of your containers and other assets."
          },
          {
            name: "Reporting and Analytics",
            description: "Generate comprehensive reports on key performance indicators (KPIs) to monitor depot performance and identify areas for improvement."
          },
          {
            name: "Integration with Other Systems",
            description: "Seamlessly integrate our DMS with your TMS, WMS, and other systems for a unified solution."
          }
        ],
      },
      {
        slug: "software-customized-solution",
        name: "Customized Solution",
        description: "",
        thumbnail: `/image/depot-management-system.jpg`,
        primary: `/image/depot-management-system-primary.jpg`,
        icon: `/image/dashboard-icon.svg`,
        darkIcon: `/image/dashboard-icon-dark.svg`,
        categories: ["software"],
        meta: new Map([
          ["ctaTitle", "Our experts are ready to help. Get in touch and we'll find the solution you need."],
        ]),
        advantages: [],
      },
    ]
  }

  public async SearchService(p: SearchServiceParam): Promise<SearchServiceResult> {
    try {
      const {
        page,
        total_items,
        categories = [],
      } = p

      let services = this.services
      if (Array.isArray(categories) && categories.length > 0) {
        const categoryDict = new Set<string>()
        categories.forEach((category) => {
          categoryDict.add(category)
        })

        services = services.filter((service) => {
          return service.categories.some((category) => {
            return categoryDict.has(category)
          })
        })
      }

      const totalItems = services.length

      if (Number.isInteger(page) && Number.isInteger(total_items)) {
        const last = page * total_items
        const first = last - total_items

        services = services.slice(first, last)
      }

      return {
        success: new Status("success search service", ACTION_SUCCESS),
        data: {
          summary: {
            page,
            total_items: totalItems
          },
          items: services
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async GetService(p: GetServiceParam): Promise<GetServiceResult> {
    try {
      const service = this.services.find((service) => {
        return service.slug === p.slug
      })
      if (!service) {
        return {
          error: new Status("service not available", RESOURCE_NOTFOUND),
        }
      }

      return {
        success: new Status("success get service", ACTION_SUCCESS),
        data: service
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

}

import * as React from "react"
import { Link } from "@allied/react-web/Router"
import { MapGoogle, MarkerGoogle, PopupGoogle } from "../../components/Map"

type OfficeLocationProps = {
  items: Office[]
  defaultCenter?: LatLng
  defaultZoom?: number
  mapId?: string
  apiKey?: string
}

type Office = {
  name: string
  address: string
  coordinate: LatLng
}

type OfficeItem = Office & {
  zoom: number
  selected: boolean
}

type LatLng = {
  lat: number
  lng: number
}

function LatLngText(c: LatLng): string {
  return `${c.lat},${c.lng}`
}

export function OfficeLocation(props: OfficeLocationProps): React.JSX.Element {
  const {
    items = [],
    defaultCenter = {
      lat: 1.3251743,
      lng: 103.7853404,
    },
    defaultZoom = 11,
    apiKey = "",
    mapId = "",
  } = props

  const [offices, setOffices] = React.useState<OfficeItem[]>(items.map((item) => {
    return {
      ...item,
      zoom: 17,
      selected: false
    }
  }))
  const [selected, setSelected] = React.useState({
    name: "",
    address: LatLngText(defaultCenter),
    coordinate: defaultCenter,
    zoom: defaultZoom,
    selected: false,
  })

  function selectLocation(i: number) {
    setSelected({
      ...offices[i],
      selected: true,
    })
    setOffices((prevState) => {
      return prevState.map((office, x: number) => {
        return {
          ...office,
          selected: (x === i),
        }
      })
    })
  }

  return (
    <div className="grid grid-cols-12 gap-4 my-4">
      <div className="col-span-12 lg:col-span-7 xl:col-span-8">
        <div className="w-full min-h-[300px] h-full overflow-hidden">
          <MapGoogle
            center={selected.coordinate}
            zoom={selected.zoom}
            apiKey={apiKey}
            mapId={mapId}
            readonly
            fullable>
            {
              offices.map((office, i: number) => {
                return (
                  <React.Fragment key={`office-marker-item-${i}`}>
                    <MarkerGoogle position={office.coordinate} onClick={() => { selectLocation(i) }}>
                      {
                        office.selected &&
                        <PopupGoogle position={office.coordinate}>
                          <div className="bg-white rounded-lg shadow-lg p-2">
                            <p className="font-bold text-base">{office.name}</p>
                            <p className="text-sm">{office.address}</p>
                            <div className="my-1">
                              <Link to={`https://maps.google.com/maps?q=${office.coordinate.lat},${office.coordinate.lng}&ll=${office.coordinate.lat},${office.coordinate.lng}`}
                                target="_blank" className="text-blue-600" hideCrawl>
                                See on Maps
                              </Link>
                            </div>
                          </div>
                        </PopupGoogle>
                      }
                    </MarkerGoogle>
                  </React.Fragment>
                )
              })
            }
          </MapGoogle>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-5 xl:col-span-4">
        <div className="w-full h-[300px] lg:h-[400px] xl:h-[480px] flex flex-col gap-4 overflow-y-auto">
          {
            offices.map((office, i: number) => {
              return (
                <React.Fragment key={`office-item-${i}`}>
                  <div className={
                    "w-full h-[150px] rounded-lg hover:text-white hover:bg-acem2-gray p-6 cursor-pointer " +
                    (office.selected ? "bg-acem2-gray text-white" : "bg-acem2-cloud")
                  }
                    onClick={() => { selectLocation(i) }}>
                    <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
                      <p className="font-medium text-lg text-acem2-red">{office.name}</p>
                      <p className="font-medium text-lg">{office.address}</p>
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
import * as React from "react"
import { Outlet } from "react-router-dom"
import { Header } from "@allied/react-web/Header"
import { Footer } from "@allied/react-web/Footer"
import { RiLinkedinBoxFill, RiFacebookCircleFill } from "react-icons/ri"
import { GrMap } from "react-icons/gr"

export default function Login(): React.JSX.Element {
  return (
    <div className="font-satoshi">
      <Header.AlliedContainer2
        logoImage={`/image/allied-logo.webp`}
        logoUrl="/"
        linkItems={[
          {
            content: <>About Us</>,
            to: "/",
            items: [
              {
                content: <>Allied's Story</>,
                to: "/about-us",
              },
              {
                content: <>Depot Locations</>,
                to: "/depot-locations",
              },
              {
                content: <>Sister Companies</>,
                to: "/sister-companies",
              },
              {
                content: <>Awards & ISO Certifications</>,
                to: "/awards-iso-certifications",
              },
            ]
          },
          {
            content: <>Services</>,
            to: "/services"
          },
          {
            content: <>Projects</>,
            to: "/projects"
          },
          {
            content: <>Careers</>,
            to: "/careers"
          },
          {
            content: <>Sustainability</>,
            to: "/sustainability"
          },
          {
            content: <>Contact</>,
            to: "/contact-us"
          },
          {
            content: <>Tools</>,
            to: "/tools"
          },
        ]}
        menuItems={[
          {
            content: <>
              <span className="hidden 3xl:block">Trucker Subcon Login</span>
              <span className="block 3xl:hidden">Trucker Login</span>
            </>,
            to: "/truckersubcon-login"
          },
          {
            content: <>
              <span className="hidden 3xl:block">Depot Haulier Login</span>
              <span className="block 3xl:hidden">Depot Login</span>
            </>,
            to: "/haulier-login"
          },
          {
            content: <>HR Portal</>,
            to: "https://whyzehr.whyze.com.sg/",
            target: "_blank",
            hideCrawl: true,
          },
          {
            content: <>Login</>,
            to: "/internal-login"
          },
        ]}
        sticky />
      <div className="min-h-full">
        <Outlet />
      </div>
      <Footer.AlliedContainer2
        title="Allied Container"
        description="Your Trusted Partner in Efficient Middle Mile Logistics"
        menuItems={[
          {
            heading: "About Us",
            items: [
              {
                content: "Allied's Story",
                to: "/about-us",
              },
              {
                content: "Depot Locations",
                to: "/depot-locations",
              },
              {
                content: "Sister Companies",
                to: "/sister-companies",
              },
              {
                content: "Awards & ISO Certifications",
                to: "/awards-iso-certifications",
              }
            ]
          },
          {
            heading: "Services",
            items: [
              {
                content: "End-to-End Transportation",
                to: "/services#transportation",
              },
              {
                content: "Warehousing & Distribution",
                to: "/services#distribution",
              },
              {
                content: "Software Solutions",
                to: "/services#software",
              }
            ]
          },
          {
            heading: "Tools",
            items: [
              {
                content: "Invoice Template",
                to: "/tools#invoice",
              },
              {
                content: "Packing List Template",
                to: "/tools#packing-list",
              },
              {
                content: "Product Label Template",
                to: "/tools#shipping-label",
              },
            ]
          },
          {
            heading: "Resources",
            items: [
              {
                content: "Depot Contact",
                to: "/depot-locations",
              },
              {
                content: "HR Portal",
                to: "https://whyzehr.whyze.com.sg/",
                target: "_blank",
                hideCrawl: true,
              },
              {
                content: "Software Solutions",
                to: "/",
              },
            ]
          },
          {
            heading: "Other",
            items: [
              {
                content: "Careers",
                to: "/careers",
              },
              {
                content: "Projects",
                to: "/projects",
              },
              {
                content: "Sustainability",
                to: "/sustainability",
              },
              {
                content: "Support Tools",
                to: "/tools",
              },
            ]
          },
        ]}
        mediaItems={[
          {
            to: "https://www.linkedin.com/company/allied-container-group/",
            target: "_blank",
            hideCrawl: true,
            content: (
              <RiLinkedinBoxFill className="block h-6 w-6 text-[#636363]" aria-hidden="true" />
            )
          },
          {
            to: "https://www.facebook.com/AlliedContainerGroup/",
            target: "_blank",
            hideCrawl: true,
            content: (
              <RiFacebookCircleFill className="block h-6 w-6 text-[#636363]" aria-hidden="true" />
            )
          },
        ]}
        contactItems={[
          {
            to: "https://maps.app.goo.gl/9LDkv5XgNktVJ4Rs9",
            target: "_blank",
            hideCrawl: true,
            content: (
              <span className="flex flex-row gap-2 justify-center items-center">
                <GrMap className="block h-4 w-4" aria-hidden="true" />
                <span className="text-base md:text-lg text-acem2-black hover:text-acem2-maroon">No. 6 Tuas Avenue 6 Singapore 639311</span>
              </span>
            )
          },
          {
            to: "https://maps.app.goo.gl/Uq8JEERGsRZstxKP8",
            target: "_blank",
            hideCrawl: true,
            content: (
              <span className="flex flex-row gap-2 justify-center items-center">
                <GrMap className="block h-4 w-4" aria-hidden="true" />
                <span className="text-base md:text-lg text-acem2-black hover:text-acem2-maroon">20 Penhas Rd, Singapore 208184</span>
              </span>
            )
          },
        ]}
        linkItems={[
          {
            content: <>
              <p className="font-medium text-sm text-[#00000099]">
                &copy; Allied Container Services Pte. Ltd.
              </p>
            </>
          }
        ]}
        associationItems={[
          {
            content: (
              <img className="max-h-12 aspect-video"
                src={`/image/sla-logo.png`}
                alt="sla" />
            )
          },
          {
            content: (
              <img className="max-h-12 aspect-video"
                src={`/image/sta-logo.png`}
                alt="sta" />
            )
          }
        ]}
        certificateItems={[
          {
            content: (
              <img className="w-12 h-12 xl:w-20 xl:h-20 aspect-square"
                src={`/image/iso-9001-logo.jpg`}
                alt="ISO 9001 Quality Management Certification" />
            )
          },
          {
            content: (
              <img className="w-12 h-12 xl:w-20 xl:h-20 aspect-square"
                src={`/image/iso-14001-logo.jpg`}
                alt="ISO 14001 Environmental Management Certification" />
            )
          },
          {
            content: (
              <img className="w-12 h-12 xl:w-20 xl:h-20 aspect-square"
                src={`/image/iso-45001-logo.jpg`}
                alt="ISO 45001 Health & Safety Management Certification" />
            )
          },
          {
            content: (
              <img className="w-20 h-12 xl:w-32 xl:h-20 aspect-classic"
                src={`/image/bizsafe-star-logo.jpg`}
                alt="bizSAFE STAR Workplace Safety and Health Management System Certification" />
            )
          }
        ]}
        certificateDescription="An ISO 9001, ISO 14001, ISO 45001 and bizSAFE STAR Certified Company"
      />
    </div>
  )
}

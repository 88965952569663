import * as React from "react"
import { MarkerF } from "@react-google-maps/api"
import { LatLng } from "./Resource"

type MarkerGoogleProps = {
  children?: React.ReactNode
  position?: LatLng
  icon?: Icon
  label?: Label
  onClick?: ((e: google.maps.MapMouseEvent) => void) | undefined
}

type Icon = {
  url: string
  scaledWidth?: number
  scaledHeight?: number
}

type Label = {
  className?: string
  color?: string
  fontFamily?: string
  fontSize?: string
  fontWeight?: string
  text: string
}

export function MarkerGoogle(props: MarkerGoogleProps): React.JSX.Element {
  const {
    children,
    position,
    label,
    icon,
    onClick,
  } = props

  const markerIcon = parseIcon(icon)

  return (
    <MarkerF
      icon={markerIcon}
      label={label}
      position={position}
      onClick={onClick}>
      {children}
    </MarkerF>
  )
}

function parseIcon(icon?: Icon): google.maps.Icon | undefined {
  if (!icon) {
    return undefined
  }

  let markerIcon = undefined as google.maps.Icon | undefined
  markerIcon = {
    url: icon.url,
  }

  if (icon.scaledWidth && icon.scaledHeight) {
    markerIcon[`scaledSize`] = new google.maps.Size(icon.scaledWidth, icon.scaledHeight)
  }

  return markerIcon
}
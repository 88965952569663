import * as React from "react"
import { ButtonLink } from "@allied/react-web/Button"

type CompanyHeroProps = {
  leftImage?: string
  leftShortText?: string
  leftLongText?: string
  rightImage?: string
  rightShortText?: string
  rightLongText?: string
}

export function CompanyHero(props: CompanyHeroProps): React.JSX.Element {
  const {
    leftImage = "/image/warehouse-management.jpg",
    leftLongText = "Discover How We Can Move Your World",
    leftShortText = "Our Services",
    rightImage = "/image/spiral-staircase.jpg",
    rightLongText = "Get Inspired for Your Next Project",
    rightShortText = "Projects",
  } = props

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-6 flex flex-col justify-center items-center bg-cover bg-center h-[360px]"
        style={{ backgroundImage: `url(${leftImage})` }}>
        <ButtonLink to="/services" variant="primary">
          <span className="hidden md:flex">
            {leftLongText}
          </span>
          <span className="flex md:hidden">
            {leftShortText}
          </span>
        </ButtonLink>
      </div>
      <div className="col-span-6 flex flex-col justify-center items-center bg-cover bg-center h-[360px]"
        style={{ backgroundImage: `url(${rightImage})` }}>
        <ButtonLink to="/projects" variant="primary">
          <span className="hidden md:flex">
            {rightLongText}
          </span>
          <span className="flex md:hidden">
            {rightShortText}
          </span>
        </ButtonLink>
      </div>
    </div>
  )
}
import * as React from "react"
import { ButtonLink } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { useAutoPosition, useHashFragment } from "@allied/react-web/hooks"
import { CompanyHero } from "../features/Company/CompanyHero"

export default function Tool(): React.JSX.Element {
  const [tools] = React.useState([
    {
      slug: "invoice",
      name: "Invoice Templates",
      description: "Download our customizable invoice templates to create clear and professional invoices for your clients, saving you valuable time and effort.",
      image: `/image/tool-invoice-template.png`,
      pdf_url: "/file/Invoice Template.pdf",
      excel_url: "/file/Invoice Template.xltx",
    },
    {
      slug: "packing-list",
      name: "Packing List Templates",
      description: "Download our customizable packing list templates to create clear and professional lists for your shipments, saving you valuable time and effort.",
      image: `/image/tool-packing-list.png`,
      pdf_url: "/file/Packing List Template.pdf",
      excel_url: "/file/Packing List Template.xltx",
    },
    {
      slug: "shipping-label",
      name: "Shipping Label",
      description: "Download our customizable shipping label templates to create clear and professional labels for your packages, saving you valuable time and effort.",
      image: `/image/tool-shipping-label.png`,
      pdf_url: "/file/Shipping Label Templates.pdf",
      excel_url: "/file/Shipping Label Templates.xltx",
    },
  ])

  useAutoPosition()
  useHashFragment()

  return (
    <>
      <Meta>
        {{
          title: "Tools",
        }}
      </Meta>

      <Container size="lg">
        <div className="mb-10 md:mb-20">
          {
            tools.map((tool, i: number) => {
              return (
                <React.Fragment key={`tool-item-${i}`}>
                  <div id={tool.slug} className="scroll-my-20 grid grid-cols-12 mb-20 md:mb-0">
                    <div className={"col-span-12 md:col-span-6 " + (i % 2 === 1 ? "md:order-last " : " ")}>
                      <div className="flex flex-col justify-end items-center bg-acem2-cloud h-[250px] md:h-[350px] lg:h-[500px]">
                        <img alt="tool" src={tool.image}
                          className="object-fit w-[300px] lg:w-[450px] h-[200px] md:h-[250px] lg:h-[350px]" />
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <div className="w-full h-full flex flex-col gap-2 justify-center items-start p-8">
                        <div className="w-full flex flex-row gap-2 justify-between items-center">
                          <div className="w-full flex flex-col gap-2">
                            <p className="text-xl lg:text-2xl font-medium text-acem2-gray">
                              {tool.name}
                            </p>
                          </div>
                        </div>
                        <p className="text-base md:text-xl text-acem2-gray">
                          {tool.description}
                        </p>
                        {
                          (tool.pdf_url || tool.excel_url) &&
                          <div className="w-full flex flex-row gap-2 justify-center md:justify-start items-center">
                            {
                              tool.pdf_url &&
                              <ButtonLink to={tool.pdf_url} target="_blank" variant="primary" hideCrawl>
                                Download PDF
                              </ButtonLink>
                            }
                            {
                              tool.excel_url &&
                              <ButtonLink to={tool.excel_url} target="_blank" variant="primary" hideCrawl>
                                Download Excel
                              </ButtonLink>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
      </Container>

      <CompanyHero />
    </>
  )
}
import * as React from "react"
import { Carousel2 } from "@allied/react-web/Carousel"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { useAutoPosition } from "@allied/react-web/hooks"
import { useHero } from "@allied/react-web/Header"
import { CompanyHero } from "../features/Company/CompanyHero"
import { CompanyJourney } from "../features/Company/CompanyJourney"

export default function Story(): React.JSX.Element {
  const { hero } = useHero({
    factor: 0.1
  })

  const [values] = React.useState([
    {
      name: "Vision",
      thumbnail: `/image/allied-vision.jpg`,
      tagline: "Empowering Global Trade Through Innovation",
      description: "To become the leading regional logistics powerhouse by pioneering innovative solutions and integrated services across the supply chain."
    },
    {
      name: "Mission",
      thumbnail: `/image/allied-mission.jpg`,
      tagline: "Delivering Seamless Logistics, Exceeding Expectations",
      description: "We are committed to providing exceptional logistics experiences through world-class facilities, cutting-edge technology, and unwavering dedication to exceeding our clients' expectations."
    }
  ])
  const [achievements] = React.useState([
    {
      content: <>
        <div className="grid grid-cols-10">
          <div className="col-span-10 sm:col-span-5 xl:col-span-2">
            <div className="w-full h-fit min-h-[240px] flex flex-col justify-center items-start gap-4 p-4 bg-acem2-cloud">
              <img alt="employee" src={`/image/office-worker-icon.svg`}
                className="w-8 h-8 aspect-square" />
              <p className="text-2xl xl:text-3xl 3xl:text-5xl text-acem2-gray">
                250+
              </p>
              <p className="text-base xl:text-lg text-black">
                Full-Time Employees
              </p>
            </div>
          </div>
          <div className="col-span-10 sm:col-span-5 xl:col-span-4">
            <div className="w-full h-full flex justify-start items-center">
              <img alt="achievement-1" src={`/image/achievement-1.jpg`}
                className="w-full h-full max-h-[240px] xl:max-h-full object-cover object-center aspect-standard" />
            </div>
          </div>
          <div className="col-span-10 xl:col-span-4">
            <div className="grid grid-cols-12">
              <div className="col-span-6"></div>
              <div className="col-span-6">
                <div className="w-full h-fit min-h-[240px] flex flex-col justify-center items-start gap-4 p-4 bg-acem2-cloud">
                  <img alt="employee" src={`/image/container-icon.svg`}
                    className="w-8 h-8 aspect-square" />
                  <p className="text-2xl xl:text-3xl 3xl:text-5xl text-acem2-gray">
                    219,000 TEU
                  </p>
                  <p className="text-base xl:text-lg text-black">
                    Annual Global Volume  of Allied Conatiners
                  </p>
                </div>
              </div>
              <div className="col-span-6">
                <div className="w-full h-fit min-h-[240px] flex flex-col justify-center items-start gap-4 p-4 bg-acem2-cloud">
                  <img alt="employee" src={`/image/map-marker-icon.svg`}
                    className="w-8 h-8 aspect-square" />
                  <p className="text-2xl xl:text-3xl 3xl:text-5xl text-acem2-gray">
                    10+
                  </p>
                  <p className="text-base xl:text-lg text-black">
                    Depot Location internationally
                  </p>
                </div>
              </div>
              <div className="col-span-6"></div>
            </div>
          </div>
        </div>
      </>
    },
    {
      content: <>
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-7 xl:col-span-8">
            <div className="grid grid-cols-12">
              <div className="col-span-6"></div>
              <div className="col-span-6">
                <div className="w-full h-fit min-h-[240px] flex flex-col justify-center items-start gap-4 p-4 bg-acem2-cloud">
                  <img alt="employee" src={`/image/warehouse-icon.svg`}
                    className="w-8 h-8 aspect-square" />
                  <p className="text-base xl:text-lg text-black">
                    One Stop
                  </p>
                  <p className="text-2xl xl:text-3xl 3xl:text-5xl text-acem2-gray">
                    3PL logistics partner
                  </p>
                </div>
              </div>
              <div className="col-span-6">
                <div className="w-full h-fit min-h-[240px] flex flex-col justify-center items-start gap-4 p-4 bg-acem2-cloud">
                  <img alt="employee" src={`/image/earth-globe-icon.svg`}
                    className="w-8 h-8 aspect-square" />
                  <p className="text-base xl:text-lg text-black">
                    We are Operating in
                  </p>
                  <p className="text-2xl xl:text-3xl 3xl:text-5xl text-acem2-gray">
                    9 Countries
                  </p>
                </div>
              </div>
              <div className="col-span-6"></div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-5 xl:col-span-4">
            <div className="w-full h-full flex justify-start items-center">
              <img alt="achievement-1" src={`/image/achievement-2.jpg`}
                className="w-full max-w-[800px] h-full object-cover object-center aspect-standard" />
            </div>
          </div>
        </div>
      </>
    },
    {
      content: <>
        <div className="grid grid-cols-12">
          <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="w-full h-fit min-h-[240px] flex flex-col justify-center items-start gap-4 p-4 bg-acem2-cloud">
              <img alt="employee" src={`/image/container-icon.svg`}
                className="w-8 h-8 aspect-square" />
              <p className="text-base xl:text-lg text-black">
                Annualy Up to
              </p>
              <p className="text-2xl xl:text-3xl 3xl:text-5xl text-acem2-gray">
                2,500,000 MT
                Barging Solutions
              </p>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="w-full h-full flex justify-start items-center">
              <img alt="achievement-1" src={`/image/achievement-3.jpg`}
                className="w-full max-w-[800px] h-full max-h-[240px] xl:max-h-full object-cover object-center aspect-standard" />
            </div>
          </div>
          <div className="col-span-12 xl:col-span-6">
            <div className="grid grid-cols-12">
              <div className="col-span-6">
                <div className="w-full h-fit min-h-[240px] flex flex-col justify-center items-start gap-4 p-4 bg-acem2-cloud">
                  <img alt="employee" src={`/image/shipment-cargo-icon.svg`}
                    className="w-8 h-8 aspect-square" />
                  <p className="text-2xl xl:text-3xl 3xl:text-5xl text-acem2-gray">
                    5 Port terminals
                  </p>
                </div>
              </div>
              <div className="col-span-6"></div>
              <div className="col-span-6"></div>
              <div className="col-span-6">
                <div className="w-full h-fit min-h-[240px] flex flex-col justify-center items-start gap-4 p-4 bg-acem2-cloud">
                  <img alt="employee" src={`/image/shipping-box-icon.svg`}
                    className="w-8 h-8 aspect-square" />
                  <p className="text-base xl:text-lg text-black">
                    We cover
                  </p>
                  <p className="text-2xl xl:text-3xl 3xl:text-5xl text-acem2-gray">
                    Land, Sea & Air
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    }
  ])
  const [journeys] = React.useState([
    {
      cover: "1975: Planting the Seeds of Success - Allied Container Group Established",
      title: "Planting the Seeds of Success - Allied Container Group Established",
      description: "Our journey began in 1975 with the founding of Allied Container Group in Singapore. From these humble beginnings, we've grown into a trusted name within the local container shipping and logistics industry.",
      label: "1975",
      active: true,
    },
    {
      cover: "1996: Expanding Our Horizons: First Mover in Myanmar",
      title: "Expanding Our Horizons: First Mover in Myanmar",
      description: "In 1996, we didn't just think big, we went beyond borders! We established MPA-Allied Yangon Inland Container Depot Ltd, (ICD 1) in Myanmar, solidifying our presence as the first inland container depot in Yangon.",
      label: "1996",
    },
    {
      cover: "1999: A Spirit of Innovation: Pioneering Rail Operations in Malaysia",
      title: "A Spirit of Innovation: Pioneering Rail Operations in Malaysia",
      description: "Our pioneering spirit shone brightly in 1999 when we established Allied Container Depot Services Sdn. Bhd, in Malaysia. We became the first to initiate rail operations between Singapore and the nation, paving the way for efficient and sustainable transportation.",
      label: "1999",
    },
    {
      cover: "2006: Diversifying Expertise: The Birth of Winspec Group",
      title: "Diversifying Expertise: The Birth of Winspec Group",
      description: "Growth and diversification are key to our success. In 2006, we established Winspec Group, a sister company specializing in freight forwarding, logistics, warehousing, and distribution services. This expansion further enriched our portofolio, allowing us to offer a wider range of solutions to our clients.",
      label: "2006",
    },
    {
      cover: "2014: Reaching New Heights: Entering the Middle East Market",
      title: "Reaching New Heights: Entering the Middle East Market",
      description: "In 2014, we set our sights on new horizons. We established Allied Ondock Container Services LLC in the United Arab Emirates, becoming the sole ondock operator in Khalifa Port, Abu Dhabi. This move marked our entry into the dynamic Middle East Market.",
      label: "2014",
    },
    {
      cover: "2017: Strengthening Our Network: Expanding in Southeast Asia",
      title: "Strengthening Our Network: Expanding in Southeast Asia",
      description: "Our commitment to regional dominance continued in 2017. We established Winspec Logistics (Thailand) Co, Ltd, solidifying our presence in Southeast Asia and offering vital rail terminal operations between South Thailand and Malaysia.",
      label: "2017",
    },
    {
      cover: "2017: Embarking on New Ventures: Allied Food (S) Pte Ltd.",
      title: "Embarking on New Ventures: Allied Food (S) Pte Ltd.",
      description: "Never one to shy away from new opportunities, 2017 saw us embark on a new journey with Allied Food (S) Pte Ltd. We ventured into the trading and sales of Japanese & Korean flour, forging partnerships with renowned flour manufacturers.",
      label: "2017",
    }
  ])

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "Allied Story",
        }}
      </Meta>

      <div ref={hero} className="relative hidden lg:block w-full h-full">
        <div className="min-h-[500px] 3xl:min-h-[700px] bg-cover" style={{ backgroundImage: `url(${"/image/allied-group.jpg"})` }} />
        <div className="flex w-full h-full absolute left-0 top-0 bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.95)_19.77%,_rgba(255,_255,_255,_0)_100%)]">
          <Container size="lg">
            <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
              <div className="w-full max-w-[650px] bg-[rgba(255,_255,_255,_0.15)] backdrop-blur p-2 rounded">
                <p className="font-bold text-2xl lg:text-4xl">
                  <span className="text-acem2-red">Allied's</span>&nbsp;
                  <span className="text-acem2-gray">Story</span>
                </p>
                <hr className="w-[100px] h-1 border-acem2-red my-2" />
                <p className="text-lg lg:text-xl font-normal lg:font-medium text-black">
                  Since our inception in 1975, Allied Container Group has been a trusted name in Singapore's container shipping and logistics management industry.
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="lg:hidden grid grid-cols-12">
        <div className="col-span-12 w-full bg-cover h-[200px] sm:h-[300px]" style={{ backgroundImage: `url(${"/image/allied-group.jpg"})` }} />
        <div className="col-span-12">
          <Container size="lg">
            <div className="w-full flex flex-col gap-2 justify-center items-start my-2">
              <p className="font-medium text-3xl">
                <span className="text-acem2-red">Allied’s</span>&nbsp;
                <span className="text-acem2-gray">Story</span>
              </p>
              <hr className="w-[100px] h-1 border-acem2-red my-2" />
              <p>
                Since our inception in 1975, Allied Container Group has been a trusted name in Singapore's container shipping and logistics management industry. With over four decades of experience, we have established ourselves as a cornerstone, known for our commitment to excellence and reliability.
              </p>
            </div>
          </Container>
        </div>
      </div>

      {
        values.length > 0 &&
        <div className="my-10 md:my-20">
          {
            values.map((value, i: number) => {
              return (
                <React.Fragment key={`value-item-${i}`}>
                  <div className="grid grid-cols-12">
                    <div className="col-span-6">
                      <div className="w-full h-full flex justify-center items-center">
                        <img alt="thumbnail" src={value.thumbnail}
                          className="w-full min-h-[250px] aspect-video object-cover object-center" />
                      </div>
                    </div>
                    <div className={
                      "col-span-6 " +
                      (i % 2 === 1 ? "order-first" : "")
                    }>
                      <div className="w-full h-full px-4 lg:px-20 py-4 bg-acem2-cloud flex flex-col gap-4 justify-center items-start">
                        <span className="font-manrope font-medium text-acem2-red text-lg md:text-2xl">
                          {value.name}
                        </span>
                        <p className="text-xl lg:text-3xl font-medium text-acem2-gray">
                          {value.tagline}
                        </p>
                        <p className="hidden md:flex text-acem2-gray text-xl">
                          {value.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
      }

      {
        achievements.length > 0 &&
        <Container size="lg">
          <div className="my-10 md:my-20 flex flex-col gap-4 lg:gap-8">
            <Carousel2
              variant="primary"
              spaceBetween={30} grabable
              autoplay autoplayDelay={5000}
              navigable navPosition="tr"
              navHeading={<>
                <div className="flex flex-col gap-2.5">
                  <span className="font-manrope font-medium text-sm text-acem2-red">
                    Escape the Multi-Partner Maze
                  </span>
                  <p className="font-satoshi font-medium text-acem2-gray text-2xl lg:text-4xl">
                    A Look at <span className="text-acem2-red">Allied Container</span> Group's Reach and Impact
                  </p>
                </div>
              </>}>
              {{
                items: achievements.map((achievement, i: number) => {
                  return {
                    children: achievement.content,
                  }
                })
              }}
            </Carousel2>
          </div>
        </Container>
      }

      {
        journeys.length > 0 &&
        <div className="w-full h-full md:bg-acem2-cloud">
          <Container size="lg">
            <div className="py-10 md:py-20">
              <div className="flex flex-col gap-2.5">
                <span className="w-full font-manrope font-medium text-acem2-red text-sm">Unveiling Our Heritage</span>
                <p className="font-satoshi font-medium text-acem2-gray text-2xl lg:text-4xl">
                  From Humble Beginnings to Logistics Leader
                </p>
              </div>

              <CompanyJourney items={journeys} />
            </div>
          </Container>
        </div>
      }

      <CompanyHero />
    </>
  )
}
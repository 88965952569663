import * as React from "react"
import { Link } from "@allied/react-web/Router"
import { MapGoogle, MarkerGoogle, PopupGoogle } from "../../components/Map"

type DepotLocationProps = {
  items: Depot[]
  defaultCenter?: LatLng
  defaultZoom?: number
  mapId?: string
  apiKey?: string
}

type Depot = {
  name: string
  address: string
  coordinate: LatLng
  initial: string
  pic: string
  contact: Contact[]
}

type DepotItem = Depot & {
  zoom: number
  selected: boolean
}

type Contact = {
  to: string
  label: string
  value: string
}

type LatLng = {
  lat: number
  lng: number
}

function LatLngText(c: LatLng): string {
  return `${c.lat},${c.lng}`
}

export function DepotLocation(props: DepotLocationProps): React.JSX.Element {
  const {
    items = [],
    defaultCenter = {
      lat: 1.3251743,
      lng: 103.7853404,
    },
    defaultZoom = 11,
    apiKey = "",
    mapId = "",
  } = props

  const [depots, setDepots] = React.useState<DepotItem[]>(items.map((item) => {
    return {
      ...item,
      zoom: 17,
      selected: false
    }
  }))
  const [selected, setSelected] = React.useState({
    name: "",
    address: LatLngText(defaultCenter),
    coordinate: defaultCenter,
    zoom: defaultZoom,
    selected: false,
  })

  function selectLocation(i: number) {
    setSelected({
      ...depots[i],
      selected: true,
    })
    setDepots((prevState) => {
      return prevState.map((office, x: number) => {
        return {
          ...office,
          selected: (x === i),
        }
      })
    })
  }

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12 md:col-span-5 xl:col-span-4 3xl:col-span-3">
        <div className="grid grid-cols-12 gap-4 w-full h-[300px] lg:h-[400px] 2xl:h-[600px] overflow-y-auto">
          {
            depots.map((depot, i: number) => {
              return (
                <React.Fragment key={`depot-item-${i}`}>
                  <div className="col-span-12">
                    <div className={
                      "w-full h-full min-h-[150px] rounded-lg p-2 cursor-pointer " +
                      "bg-acem2-cloud border-1 border-[#E3E6EA] hover:border-acem2-primary"
                    }
                      onClick={() => { selectLocation(i) }}>
                      {
                        depot.selected &&
                        <div className="w-full h-full px-4 py-2">
                          <div className="flex flex-col gap-2 justify-center items-start">
                            <p className="font-medium uppercase text-lg lg:text-xl text-acem2-red">
                              {depot.name}
                            </p>
                            <p className="font-medium text-base lg:text-lg">
                              {depot.address}
                            </p>
                            <p className="font-semibold text-lg lg:text-2xl">
                              {depot.pic}
                            </p>
                            {
                              depot.contact.length > 0 &&
                              <ul className="list-none">
                                {
                                  depot.contact.map((contact, i: number) => {
                                    return (
                                      <React.Fragment key={`contact-item-${i}`}>
                                        <li>
                                          <span className="text-base lg:text-lg">{contact.label}</span>: {" "}
                                          <Link className="font-semibold text-base lg:text-lg underline" to={contact.to}>
                                            {contact.value}
                                          </Link>
                                        </li>
                                      </React.Fragment>
                                    )
                                  })
                                }
                              </ul>
                            }
                          </div>
                        </div>
                      }
                      {
                        !depot.selected &&
                        <div className="w-full h-full p-4">
                          <div className="flex flex-col gap-2 justify-center items-start">
                            <p className="font-medium uppercase text-lg lg:text-xl text-acem2-red">
                              {depot.name}
                            </p>
                            <p className="font-medium text-base lg:text-lg">
                              {depot.address}
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
      </div>
      <div className="col-span-12 md:col-span-7 xl:col-span-8 3xl:col-span-9">
        <div className="w-full min-h-[300px] h-full overflow-hidden">
          <MapGoogle
            center={selected.coordinate}
            zoom={selected.zoom}
            apiKey={apiKey}
            mapId={mapId}
            readonly
            fullable>
            {
              depots.map((office, i: number) => {
                return (
                  <React.Fragment key={`office-marker-item-${i}`}>
                    <MarkerGoogle
                      label={{
                        text: office.initial,
                        className: (office.selected ? "font-bold" : ""),
                        fontSize: (office.selected ? "24px" : "18px"),
                        color: "white",
                      }}
                      icon={{
                        url: `/image/map-marker.png`,
                        scaledWidth: 27,
                        scaledHeight: 40,
                      }}
                      position={office.coordinate}
                      onClick={() => { selectLocation(i) }}>
                      {
                        office.selected &&
                        <PopupGoogle position={office.coordinate}>
                          <div className="bg-white rounded-lg shadow-lg p-2">
                            <p className="font-bold text-base">{office.name}</p>
                            <p className="text-sm">{office.address}</p>
                            <div className="my-1">
                              <Link to={`https://maps.google.com/maps?q=${office.coordinate.lat},${office.coordinate.lng}&ll=${office.coordinate.lat},${office.coordinate.lng}`}
                                target="_blank" className="text-blue-600" hideCrawl>
                                See on Maps
                              </Link>
                            </div>
                          </div>
                        </PopupGoogle>
                      }
                    </MarkerGoogle>
                  </React.Fragment>
                )
              })
            }
          </MapGoogle>
        </div>
      </div>
    </div >
  )
}
import * as React from "react"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6"

type JobPaginationProps = {
  currentPage?: number
  itemPerPage?: number
  pageRange?: number
  totalItems?: number
  onSelected?: ({ page }: { page: number }) => void
}

export function JobPagination(props: JobPaginationProps): React.JSX.Element {
  const {
    currentPage = 1,
    itemPerPage = 12,
    pageRange = 5,
    totalItems = 0,
    onSelected = () => { },
  } = props

  const totalPages = Math.ceil(totalItems / itemPerPage)

  function getPageRange() {
    let startPage = Math.max(1, currentPage - Math.floor(pageRange / 2))
    let endPage = startPage + pageRange - 1
    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(1, endPage - pageRange + 1)
    }
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    )
  }

  function selectPage(p: number) {
    onSelected({ page: p })
  }

  return (
    <div className="flex flex-row justify-center items-center gap-4">
      {
        currentPage > 1 &&
        <button onClick={() => { selectPage(currentPage - 1) }}
          className="p-2 flex justify-center items-center">
          <FaChevronLeft className="w-6 h-6" aria-hidden="true" />
        </button>
      }
      {
        getPageRange().map((page: number, i: number) => {
          return (
            <button key={`page-item-${i}`}
              onClick={() => { selectPage(page) }}
              className={
                "w-11 h-11 p-2 flex justify-center items-center " +
                (currentPage === page ? "rounded-full text-white bg-acem2-primary" : "")
              }>
              {page}
            </button>
          )
        })
      }
      {
        currentPage < totalPages &&
        <button onClick={() => { selectPage(currentPage + 1) }}
          className="p-2 flex justify-center items-center">
          <FaChevronRight className="w-6 h-6" aria-hidden="true" />
        </button>
      }
    </div>
  )
}
import * as React from "react"

type JourneyContainerProps = {
	active?: boolean
	cover: string
	title: string
	description: string
	label: React.ReactNode
	position: JourneyContainerPosition
	onSelected?: () => void
}

enum JourneyContainerPosition {
	TOP = "top",
	BOTTOM = "bottom",
}

function JourneyLine({ position }: { position: string }): React.JSX.Element {
	return (
		<>
			<hr className={
				"z-0 animate-ping duration-1000 border-l border-acem2-red absolute left-1/2 h-[25px] " +
				(position === JourneyContainerPosition.BOTTOM ? "bottom-[-10px]" : "top-[-10px]")
			} />
		</>
	)
}

function JourneyContainer(props: JourneyContainerProps): React.JSX.Element {
	const {
		active = false,
		cover,
		title,
		description,
		label,
		position,
		onSelected = () => { }
	} = props

	const handleOnclick = () => {
		onSelected()
	}

	function createLabel({ active }: { active: boolean }): React.JSX.Element {
		return (
			<div className="relative z-[1] flex flex-col justify-center items-center my-2">
				<button className={
					"px-3 py-1 text-sm rounded-full " +
					(active ? "bg-acem2-red text-white" : "bg-white text-black border border-acem2-red")
				}
					onClick={handleOnclick}>
					{label}
				</button>
			</div>
		)
	}

	return (
		<div className={"p-2 md:bg-transparent " + (active ? "bg-acem2-cloud" : "")}>
			{
				position === JourneyContainerPosition.TOP &&
				createLabel({
					active
				})
			}
			{
				active ?
					<div className="flex flex-col gap-2 justify-center items-start">
						<p className="font-medium text-xl">{title}</p>
						<p>{description}</p>
					</div>
					:
					<div className="flex flex-col gap-2 justify-center items-center text-center">
						<p className="font-medium text-xl">{cover}</p>
					</div>
			}
			{
				position === JourneyContainerPosition.BOTTOM &&
				createLabel({
					active
				})
			}
		</div>
	)
}

type CompanyJourneyProps = {
	items?: JourneyItem[]
}

type JourneyItem = {
	cover: string
	title: string
	description: string
	label: string
	active?: boolean
}

export function CompanyJourney(props: CompanyJourneyProps): React.JSX.Element {
	const { items } = props
	const [journeys, setJourneys] = React.useState(items.map((item) => {
		return {
			...item,
			active: item.active || false,
		}
	}))
	const [selectedJourney, setSelectedJourney] = React.useState(0)
	const journeyRef = React.useRef(null)

	function selectJourney(newJourney: number) {
		if (newJourney === selectedJourney) {
			return
		}

		setJourneys((journeys) => {
			return journeys.map((journey, i: number) => {
				return {
					...journey,
					active: (newJourney === i)
				}
			})
		})
		setSelectedJourney(newJourney)
		if (newJourney > selectedJourney) {
			journeyRef.current.scrollLeft += (newJourney * 20)
		} else {
			journeyRef.current.scrollLeft -= ((journeys.length - newJourney) * 20)
		}
	}

	return (
		<div ref={journeyRef} className="w-full my-2 overflow-x-auto">
			<div className={`w-full flex flex-row gap-2 h-[400px]`}
				style={{ minWidth: `${(journeys.length * 250)}px` }}>
				{
					journeys.map((journey, i: number) => {
						return (
							<React.Fragment key={`journey-item-${i}`}>
								<div className="w-[250px] flex flex-col justify-end relative">
									{
										i % 2 === 0 &&
										<>
											<JourneyContainer position={JourneyContainerPosition.BOTTOM}
												{...journey} onSelected={() => selectJourney(i)} />
											{
												journey.active &&
												<JourneyLine position={JourneyContainerPosition.BOTTOM} />
											}
										</>
									}
								</div>
							</React.Fragment>
						)
					})
				}
			</div>
			<div className={`w-full relative h-[100px] flex flex-col justify-center`}
				style={{ minWidth: `${(journeys.length * 250)}px` }}>
				<img alt="container-truck" src="/image/container-truck-right.png"
					className="absolute w-fit h-[80px] z-[1] duration-1000"
					style={{ left: `${(selectedJourney * 250)}px` }} />
				<hr className="w-full border-black absolute bottom-[10px]" />
			</div>
			<div className={`w-full flex flex-row gap-2 h-[400px]`}
				style={{ minWidth: `${(journeys.length * 250)}px` }}>
				{
					journeys.map((journey, i: number) => {
						return (
							<React.Fragment key={`journey-item-${i}`}>
								<div className="w-[250px] flex flex-col justify-start relative">
									{
										i % 2 === 1 &&
										<>
											{
												journey.active &&
												<JourneyLine position={JourneyContainerPosition.TOP} />
											}
											<JourneyContainer position={JourneyContainerPosition.TOP}
												{...journey} onSelected={() => selectJourney(i)} />
										</>
									}
								</div>
							</React.Fragment>
						)
					})
				}
			</div>
		</div>
	)
}
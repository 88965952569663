import * as Papa from "papaparse"
import * as dayjs from "dayjs"
import * as dayjsFormat from "dayjs/plugin/customParseFormat"
import {
  Status,
  ACTION_SUCCESS,
  RESOURCE_NOTFOUND,
  UNEXPECTED_ERROR,
} from "../Status"
import {
  SearchJobParam, SearchJobResult,
  GetJobParam, GetJobResult
} from "../Job"

type JobItem = {
  id: string
  title: string
  description: string
  responsibility: string
  requirement: string
  company_name: string
  department_name: string
  employment_type: string
  posted_at?: Date
}

export class Job {

  private companyName = "Allied Container (Engineers & Manufacturers) Pte Ltd"
  private jobSource = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQddtjFLj6swt4bf-Pfi8cpitZhybwwZHNFg8FbyQyKi3u4c2aLtP6JDnG3xa9O9GKbGl5T-bCkoMz6/pub?gid=0&single=true&output=csv"

  public constructor() {
    dayjs.extend(dayjsFormat)
  }

  public async SearchJob(p: SearchJobParam): Promise<SearchJobResult> {
    try {
      const {
        page = 1,
        total_items = 12,
        keyword,
        department,
        employment,
      } = p

      let jobs = await this.fetchJobs()

      jobs = jobs.filter((item: JobItem) => {
        return item.company_name === this.companyName
      })

      if (keyword) {
        const sanitizedKeyword = keyword.toLowerCase()
        jobs = jobs.filter((item: JobItem) => {
          return (
            item.title.toLowerCase().includes(sanitizedKeyword) ||
            item.description.toLowerCase().includes(sanitizedKeyword) ||
            item.company_name.toLowerCase().includes(sanitizedKeyword) ||
            item.requirement.toLowerCase().includes(sanitizedKeyword) ||
            item.responsibility.toLowerCase().includes(sanitizedKeyword)
          )
        })
      }

      if (department) {
        jobs = jobs.filter((item: JobItem) => {
          return item.department_name === department
        })
      }

      if (employment) {
        jobs = jobs.filter((item: JobItem) => {
          return item.employment_type === employment
        })
      }

      const totalItems = jobs.length
      const last = page * total_items
      const first = last - total_items

      jobs = jobs.slice(first, last)

      return {
        success: new Status("success search job", ACTION_SUCCESS),
        data: {
          summary: {
            page,
            total_items: totalItems
          },
          items: jobs,
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async GetJob(p: GetJobParam): Promise<GetJobResult> {
    try {
      let jobs = await this.fetchJobs()

      const job = jobs.find((job) => {
        return job.id === p.slug
      })
      if (!job) {
        return {
          error: new Status("job not found", RESOURCE_NOTFOUND)
        }
      }

      return {
        success: new Status("success get job", ACTION_SUCCESS),
        data: job
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  private async fetchJobs(): Promise<JobItem[]> {
    const jobRes = await fetch(this.jobSource)
    const text = await jobRes.text()
    const parseRes = Papa.parse(text)

    return parseRes.data.filter((item?: any[]) => {
      return Array.isArray(item) && item.length >= 8
    }).map((item: any[], i: number) => {
      const id = `${i}`

      let postedAt: Date = undefined
      const parsedDate = dayjs(item[8], "DD-MM-YYYY", true)
      if (parsedDate.isValid()) {
        postedAt = parsedDate.toDate()
      }

      return {
        id,
        title: item[4],
        description: item[5],
        responsibility: item[6],
        requirement: item[7],
        company_name: item[1],
        department_name: item[2],
        employment_type: item[3],
        posted_at: postedAt,
      } as JobItem
    }).map((item) => {
      return {
        ...item,
        responsibility: item.responsibility
          .split("\n")
          .map((char) => `<p>${char}</p>`)
          .join(" "),
        requirement: item.requirement
          .split("\n")
          .map((char) => `<p>${char}</p>`)
          .join(" ")
      }
    })
  }

}
import * as React from "react"
import { useNavigate } from "react-router-dom"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { Link } from "@allied/react-web/Router"
import { useAutoPosition, useHashFragment } from "@allied/react-web/hooks"
import { useHero } from "@allied/react-web/Header"
import { CompanyHero } from "../features/Company/CompanyHero"
import { Service as StaticService } from "../services/static/Service"

type ServiceItem = {
  slug: string
  name: string
  description?: string
  thumbnail: any
  icon?: any
  categories: string[]
}

type ObjectItem<T> = T & {
  hovered: boolean
}

const staticService = new StaticService()

export default function Service(): React.JSX.Element {
  const { hero } = useHero({
    factor: 0.1
  })

  const [services, setServices] = React.useState([
    {
      slug: "container",
      name: "Container Services",
      thumbnail: "/image/container-thumbnail.jpg",
      items: [],
    },
    {
      slug: "warehouse",
      name: "Warehouse & Distribution",
      thumbnail: "/image/warehouse-thumbnail.jpg",
      items: [],
    },
    {
      slug: "freight",
      name: "Freight",
      thumbnail: "/image/freight-thumbnail.jpg",
      items: [],
    },
    {
      slug: "software",
      name: "Software Solutions",
      thumbnail: "/image/software-thumbnail.jpg",
      items: [],
    }
  ])

  React.useEffect(() => {
    async function searchService() {
      const searchService = await staticService.SearchService({
        categories: ["container", "warehouse", "freight", "software"]
      })
      if (searchService.error) {
        return
      }

      const services = searchService.data.items.map((item) => {
        return {
          ...item,
          hovered: false
        }
      })

      setServices((prevState) => {
        return prevState.map((category) => {
          return {
            ...category,
            items: services.filter(item => item.categories.includes(category.slug))
          }
        })
      })
    }

    searchService()
  }, [])

  useAutoPosition()
  useHashFragment()

  return (
    <>
      <Meta>
        {{
          title: "Services",
        }}
      </Meta>

      <div ref={hero} className="relative hidden lg:block w-full h-full">
        <div className="min-h-[500px] 3xl:min-h-[700px] bg-cover" style={{ backgroundImage: `url(${"/image/warehouse-lifting.jpg"})` }} />
        <div className="flex w-full h-full absolute left-0 top-0 bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.95)_19.77%,_rgba(255,_255,_255,_0)_100%)]">
          <Container size="lg">
            <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
              <div className="w-full max-w-[650px] flex flex-col gap-4 bg-[rgba(255,_255,_255,_0.15)] backdrop-blur p-2 rounded">
                <span className="font-manrope font-bold text-sm text-acem2-red">
                  Streamlined Supply Chain
                </span>
                <div className="flex flex-col gap-2.5">
                  <p className="font-bold text-2xl lg:text-4xl">
                    <span className="block text-acem2-gray">Your Complete Logistics Solution:</span>
                    <span className="text-acem2-red">Allied </span>
                    <span className="text-acem2-gray">Services</span>
                  </p>
                  <hr className="w-[100px] h-1 border-acem2-red my-2" />
                </div>
                <p className="text-lg font-medium text-acem2-gray">
                  We simplify complex logistics, managing your cargo from origin to destination.
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="grid grid-cols-12 lg:hidden">
        <div className="col-span-12 w-full bg-cover bg-top h-[200px]" style={{ backgroundImage: `url(${"/image/warehouse-lifting.jpg"})` }} />
        <div className="col-span-12">
          <Container size="md">
            <div className="w-full flex flex-col gap-2 justify-center items-start my-2">
              <span className="font-manrope font-medium text-sm text-acem2-red">
                Streamlined Supply Chain
              </span>
              <p className="font-medium text-3xl">
                <span className="text-acem2-gray">Your Complete Logistics Solution: </span>
                <span className="text-acem2-red">Allied </span>
                <span className="text-acem2-gray">Services</span>
              </p>
              <hr className="w-[100px] h-1 border-acem2-red my-2" />
              <p className="text-black">
                We simplify complex logistics, managing your cargo from origin to destination.
              </p>
            </div>
          </Container>
        </div>
      </div>

      <div className="bg-white py-10 md:py-20">
        <Container size="lg">
          <div className="flex flex-col gap-10">
            {
              services.map((service, i: number) => {
                return (
                  <React.Fragment key={`service-item-${i}`}>
                    <div className="grid grid-cols-12 gap-5 md:gap-10">
                      <div className="col-span-12 md:col-span-6">
                        <div className="flex flex-col gap-6">
                          <p className="text-2xl 3xl:text-4xl text-acem2-gray">
                            {service.name}
                          </p>
                          <ServiceContainer items={service.items} />
                        </div>
                      </div>
                      <div className={
                        "col-span-12 md:col-span-6 " +
                        (i % 2 === 1 ? "md:order-first" : "")
                      }>
                        <div className="w-full h-full flex justify-center items-center">
                          <img alt="container" src={service.thumbnail}
                            className="aspect-video object-cover object-center" />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })
            }
          </div>
        </Container >
      </div >

      <CompanyHero
        leftLongText="Discover Our Story"
        leftImage={`/image/goods-checking.jpg`} />
    </>
  )
}

type ServiceContainerProps = {
  items: ObjectItem<ServiceItem>[]
}

function ServiceContainer(props: ServiceContainerProps): React.JSX.Element {
  const {
    items = [],
  } = props

  const navigate = useNavigate()
  const totalItems = items.length

  return (
    <div className="grid grid-cols-12">
      {
        items.map((service, i: number) => {
          const col = i + 1
          const row = Math.round(col / 2)
          const order = col - (2 * (row - 1))
          const oddMode = totalItems % 3 === 0

          return (
            <React.Fragment key={`freight-item-${i}`}>
              <div className={
                "col-span-6 " +
                (oddMode ? "lg:col-span-4 " : "lg:col-span-6 ")
              }>
                <div onClick={() => {
                  navigate(`/services/${service.slug}`)
                }}
                  className={
                    "cursor-pointer w-full h-full p-4  " +
                    (row % 2 === 1 && order % 2 === 1 ? "bg-acem2-cloud " : " ") +
                    (row % 2 === 0 && order % 2 === 0 ? "bg-acem2-cloud " : " ") +
                    (oddMode && col % 2 === 1 ? "lg:bg-acem2-cloud " : " ") +
                    (oddMode ? "min-h-[150px] lg:min-h-[300px] " : "min-h-[150px] ")
                  }>
                  <div className="w-full h-full flex flex-col gap-4 justify-center items-center">
                    <img alt="service" src={service.icon} className="w-6 h-6" />
                    <Link to={`/services/${service.slug}`}
                      className="text-lg text-center text-black">
                      {service.name}
                    </Link>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )
        })
      }
    </div>
  )
}
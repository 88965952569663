import * as React from "react"
import { Select } from "@allied/react-web/Select"

const filterEmployments = [
  {
    value: "",
    label: "All Types"
  },
  {
    value: "Full Time",
    label: "Full Time"
  },
  {
    value: "Part Time",
    label: "Part Time"
  },
  {
    value: "Contract",
    label: "Contract"
  }
]
const filterDepartments = [
  {
    value: "",
    label: "All Departments"
  },
  {
    value: "Operations",
    label: "Operations"
  },
  {
    value: "HR",
    label: "HR"
  },
  {
    value: "Accounts",
    label: "Accounts"
  },
  {
    value: "IT",
    label: "IT"
  },
]

type JobFilterProps = {
  loading?: boolean
  onUpdate?: ({ filter }: { filter: Record<string, string> }) => void
}

export function JobFilter(props: JobFilterProps): React.JSX.Element {
  const {
    loading = false,
    onUpdate = () => { },
  } = props

  const [filter, setFilter] = React.useState({
    employment: "",
    department: ""
  })

  function changeFilter (key: string, value: string) {
    setFilter((prevState) => {
      return {
        ...prevState,
        [key]: value
      }
    })
    onUpdate({
      filter: {
        ...filter,
        [key]: value
      }
    })
  }

  return (
    <div className="grid grid-cols-12 gap-2">
      <div className="col-span-6">
        <Select size="lg"
          id="filter-department"
          name="filter-department"
          placeholder="Select Fields"
          disabled={loading}
          value={filter.department}
          onChange={(e) => {
            changeFilter("department", e.target.value)
          }}>
          {
            filterDepartments.map((filter, i: number) => {
              return (
                <Select.Item key={`department-item-${i}`} value={filter.value}>
                  {filter.label}
                </Select.Item>
              )
            })
          }
        </Select>
      </div>
      <div className="col-span-6">
        <Select size="lg"
          id="filter-employment-type"
          name="filter-employment-type"
          placeholder="Select Employment Type"
          disabled={loading}
          value={filter.employment}
          onChange={(e) => {
            changeFilter("employment", e.target.value)
          }}>
          {
            filterEmployments.map((filter, i: number) => {
              return (
                <Select.Item key={`employment-type-item-${i}`} value={filter.value}>
                  {filter.label}
                </Select.Item>
              )
            })
          }
        </Select>
      </div>
    </div>
  )
}
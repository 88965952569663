import * as React from "react"
import { GoLinkExternal } from "react-icons/go"
import { ButtonLink } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { useAutoPosition } from "@allied/react-web/hooks"
import { useHero } from "@allied/react-web/Header"
import { ContactHero } from "../features/Contact/ContactHero"
import { CompanyHero } from "../features/Company/CompanyHero"
import { DepotLocation as DepotLocations } from "../features/Depot/DepotLocation"

export default function DepotLocation(): React.JSX.Element {
  const { hero } = useHero({
    factor: 0.1
  })

  const [benefits] = React.useState([
    {
      icon: `/image/container-icon.svg`,
      background: "bg-acem2-cloud",
      name: "Storage",
      description: "Secure and spacious facilities to accommodate your containers, both laden and empty."
    },
    {
      icon: `/image/magnifying-glass-icon.svg`,
      background: "",
      name: "Pre-trip inspections",
      description: "Ensuring your containers are in optimal condition for onward journeys."
    },
    {
      icon: `/image/shield-icon.svg`,
      background: "bg-acem2-cloud",
      name: "Washing and repairs",
      description: "Keeping your containers clean and operational."
    },
    {
      icon: `/image/map-marker-icon.svg`,
      background: "",
      name: "Strategic Locations",
      description: "Convenient access to major shipping lanes and transportation networks."
    },
    {
      icon: `/image/app-plus-icon.svg`,
      background: "bg-acem2-cloud",
      name: "Seamless Integration",
      description: "Our network allows for seamless movement of your containers across locations."
    },
    {
      icon: `/image/warehouse-icon.svg`,
      background: "",
      name: "Advanced Equipment",
      description: "State-of-the-art facilities and modern equipment to ensure efficient operations."
    },
  ])
  const [branches] = React.useState([
    {
      country: "Myanmar",
      name: "MPA-Allied Yangon Inland Container Depot Ltd.",
      content: "The first inland container depot in Yangon, boasting a 10-acre facility with a capacity of 5,200 TEUs.",
      url: "https://mpaallied.com/",
      thumbnail: `/image/depot-myanmar.jpg`,
    },
    {
      country: "Malaysia",
      name: "Allied Container Depot Services Sdn. Bhd.",
      content: "A pioneer in rail operations, offering depot services, land feeder operations within Malaysia, and landbridge connections to South Thailand. We also have a recently reopened depot in Port Klang.",
      url: "https://acsmy.com/",
      thumbnail: `/image/depot-malaysia.jpg`,
    },
    {
      country: "United Arab Emirates",
      name: "Allied Ondock Container Services LLC.",
      content: "The sole ondock operator in Khalifa Port of Abu Dhabi. This 2-acre facility handles up to 1,000 TEUs daily, providing container inspection, repair, pre-trip inspection, and washing services.",
      url: "http://aocsae.com/",
      thumbnail: `/image/depot-uae.jpg`,
    },
    {
      country: "Thailand",
      name: "Winspec Logistics (Thailand) Co. Ltd.",
      content: "An authorized State Railway of Thailand (SRT) and Landbridge operator, facilitating container and cargo movement between South Thailand, Malaysia, and Singapore.",
      url: "https://winspecgroup.com/",
      thumbnail: `/image/depot-thailand.jpg`,
    },
    {
      country: "Oman",
      name: "Allied Ondock Container Services LLC (Oman)",
      content: "Located at Sohar Port and Freezone, this 3-hectare facility utilizes advanced logistics solutions and digital infrastructure to offer efficient container handling services, including empty and laden containers, while adhering to environmental regulations.",
      url: "https://aocsom.com/",
      thumbnail: `/image/depot-oman.jpg`,
    }
  ])
  const [depots] = React.useState([
    {
      name: "Allied Depot 1",
      address: "1, Tuas Basin Lane, Singapore 637066",
      initial: "1",
      coordinate: {
        lat: 1.3097969,
        lng: 103.6483882,
      },
      pic: "Mr Eddie Tan",
      contact: [
        {
          label: "DM Mobile",
          value: "+65 9137 4018",
          to: "tel:+6591374018",
        },
        {
          label: "IN COUNTER Tel",
          value: "+65 8940 5895",
          to: "tel:+6589405895",
        },
        {
          label: "OUT COUNTER Tel",
          value: "+65 8940 5895",
          to: "tel:+6589405895",
        }
      ]
    },
    {
      name: "Allied Depot 3",
      address: "25, Penjuru Lane, Singapore 609194",
      initial: "3",
      coordinate: {
        lat: 1.3092167,
        lng: 103.7328285
      },
      pic: "Mr Parthiban",
      contact: [
        {
          label: "Email",
          value: "parthiban@allied.com.sg",
          to: "mailto:parthiban@allied.com.sg",
        },
        {
          label: "DM Mobile",
          value: "+65 9822 9386",
          to: "tel:+6598229386",
        },
        {
          label: "IN COUNTER Tel",
          value: "+65 6867 9248",
          to: "tel:+6568679248",
        },
        {
          label: "OUT COUNTER Tel",
          value: "+65 6867 9241",
          to: "tel:+6568679241",
        },
        {
          label: "RELEASE COUNTER Tel",
          value: "+65 6867 9240",
          to: "tel:+6568679240",
        },
      ],
    },
    {
      name: "Allied Depot 5",
      address: "18A Penjuru Road Singapore 609150",
      initial: "5",
      coordinate: {
        lat: 1.3174885,
        lng: 103.7338996
      },
      pic: "Mr Vincent Phua",
      contact: [
        {
          label: "Email",
          value: "vincent@allied.com.sg",
          to: "mailto:vincent@allied.com.sg",
        },
        {
          label: "DM Mobile",
          value: "+65 9159 4283",
          to: "tel:+6591594283",
        },
        {
          label: "IN COUNTER Tel",
          value: "+65 6261 5816",
          to: "tel:+6562615816",
        },
        {
          label: "OUT COUNTER Tel",
          value: "+65 6261 5819",
          to: "tel:+6562615819",
        },
        {
          label: "RELEASE COUNTER Tel",
          value: "+65 6261 5817",
          to: "tel:+6562615817",
        },
      ],
    },
    {
      name: "Allied Depot 6",
      address: "1, Banyan Place, Jurong Island Singapore 627841",
      initial: "6",
      coordinate: {
        lat: 1.2635207,
        lng: 103.6747036
      },
      pic: "Mr KC Teo",
      contact: [
        {
          label: "Email",
          value: "acs_ji@allied.com.sg",
          to: "mailto:acs_ji@allied.com.sg",
        },
        {
          label: "DM Mobile",
          value: "+65 8154 5012",
          to: "tel:+6581545012",
        },
      ],
    },
    {
      name: "Allied Depot 8",
      address: "15, Pioneer Crescent Singapore 628552",
      initial: "8",
      coordinate: {
        lat: 1.312258,
        lng: 103.6911056
      },
      pic: "Mr Dannie Tan",
      contact: [
        {
          label: "Email",
          value: "dannie@allied.com.sg",
          to: "mailto:dannie@allied.com.sg",
        },
        {
          label: "DM Mobile",
          value: "+65 9733 1036",
          to: "tel:+6597331036",
        },
        {
          label: "DM Tel",
          value: "+65 6513 4542",
          to: "tel:+6565134542",
        },
        {
          label: "IN COUNTER Tel",
          value: "+65 6513 4534",
          to: "tel:+6565134534",
        },
        {
          label: "OUT COUNTER Tel",
          value: "+65 6513 4563",
          to: "tel:+6565134563",
        },
        {
          label: "RELEASE COUNTER Tel",
          value: "+65 6513 4533",
          to: "tel:+6565134533",
        },
        {
          label: "RELEASE COUNTER Tel",
          value: "+65 6513 4536",
          to: "tel:+6565134536",
        },
      ],
    },
  ])

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "Depot Location",
        }}
      </Meta>

      <div ref={hero} className="relative hidden lg:block w-full h-full">
        <div className="min-h-[500px] 3xl:min-h-[700px] bg-cover" style={{ backgroundImage: `url(${"/image/container-moving.jpg"})` }} />
        <div className="flex w-full h-full absolute left-0 top-0 bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.95)_19.77%,_rgba(255,_255,_255,_0)_100%)]">
          <Container size="lg">
            <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
              <div className="w-full max-w-[650px] bg-[rgba(255,_255,_255,_0.15)] backdrop-blur p-2 rounded">
                <p className="font-bold text-2xl lg:text-4xl">
                  <span className="block text-acem2-gray">Your Global Network of Reliable</span>
                  <span className="text-acem2-gray">Container Depots</span>
                </p>
                <hr className="w-1/4 h-1 border-acem2-red my-2" />
                <p className="text-lg lg:text-xl font-normal lg:font-medium text-acem2-gray">
                  Allied Containers maintains a robust network of strategically located depots, ensuring we're closer to your cargo and ready to support your logistics needs efficiently.
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="lg:hidden grid grid-cols-12">
        <div className="col-span-12 w-full bg-cover bg-center h-[200px]" style={{ backgroundImage: `url(${"/image/container-moving.jpg"})` }}>
        </div>
        <div className="col-span-12">
          <Container size="lg">
            <div className="flex flex-col gap-2 justify-center items-start my-2">
              <p className="font-medium text-3xl">
                Your Global Network of Reliable Container Depots
              </p>
              <hr className="w-[100px] h-1 border-acem2-red my-2" />
              <p>
                Allied Containers maintains a robust network of strategically located depots, ensuring we're closer to your cargo and ready to support your logistics needs efficiently.
              </p>
            </div>
          </Container>
        </div>
      </div>

      <Container size="lg">
        <div className="flex flex-col gap-6 my-10 md:my-20">
          <div className="flex flex-col gap-2.5 my-2">
            <span className="w-full font-manrope font-medium text-acem2-red text-sm">
              Seamless Logistics, Wherever You Need It.
            </span>
            <p className="font-satoshi font-medium text-acem2-gray text-2xl lg:text-4xl">
              Benefits of Choosing Allied Group Depots
            </p>
          </div>

          <div className="grid grid-rows-3 md:grid-rows-2 grid-flow-col grid-cols-2 md:grid-cols-3 md:grid-flow-row">
            {
              benefits.map((benefit, i: number) => {
                return (
                  <React.Fragment key={`benefit-item-${i}`}>
                    <div className={"w-full flex flex-col justify-center items-start gap-2 p-6 text-acem2-gray " + benefit.background}>
                      <img alt="benefit-icon" src={benefit.icon} className="w-6 h-6" />
                      <p className="font-medium text-base lg:text-2xl">{benefit.name}</p>
                      <p className="hidden md:block text-base">{benefit.description}</p>
                    </div>
                  </React.Fragment>
                )
              })
            }
          </div>
        </div>
      </Container>

      <Container size="lg">
        <div className="w-full max-w-[1080px] flex flex-col gap-2">
          <span className="w-full font-manrope font-medium text-acem2-red text-sm">
            Explore our diverse locations
          </span>
          <p className="text-2xl lg:text-4xl font-medium text-acem2-gray">
            Let Us Help You Find the Perfect Depot Solution.
          </p>
          <p className="text-acem2-gray text-base md:text-xl">
            Allied Group is headquartered in Singapore, with several conveniently located depots to serve your needs. <br />
            Contact us directly for more information on our Singapore depots.
          </p>
        </div>
        <div className="my-10">
          {
            branches.map((branch, i: number) => {
              return (
                <React.Fragment key={`branch-item-${i}`}>
                  <div className="grid grid-cols-12 gap-4 sm:gap-0">
                    <div className={"col-span-12 sm:col-span-6 " + (i % 2 === 1 ? "sm:order-last" : "")}>
                      <div className="w-full h-full">
                        <img alt="branch" src={branch.thumbnail}
                          className="w-full h-full aspect-[4/3] object-cover object-center" />
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <div className="w-full h-full sm:px-6 sm:py-6 xl:px-12 3xl:px-20 flex flex-col gap-2 justify-center items-start">
                        <div className="w-full flex flex-row gap-2 justify-between items-center">
                          <div className="flex flex-col gap-2">
                            <p className="text-2xl lg:text-4xl font-medium text-acem2-gray">
                              {branch.country}
                            </p>
                            <hr className="w-[100px] h-1 border-acem2-red" />
                          </div>
                          <div className="flex justify-end sm:hidden">
                            <ButtonLink to={branch.url} target="_blank" variant="primary" hideCrawl
                              appendClassNames="flex flex-row gap-2.5 justify-center items-center">
                              <span>Visit Site</span>
                              <GoLinkExternal aria-hidden="true" className="text-white w-[12px] h-[12px]" />
                            </ButtonLink>
                          </div>
                        </div>
                        <div className="text-lg sm:text-xl py-4">
                          <span className="font-bold">
                            {branch.name}: {" "}
                          </span>
                          {branch.content}
                        </div>
                        <div className="hidden sm:flex">
                          <ButtonLink to={branch.url} target="_blank" variant="primary" hideCrawl
                            appendClassNames="flex flex-row gap-2.5 justify-center items-center">
                            <span>Visit Site</span>
                            <GoLinkExternal aria-hidden="true" className="text-white w-[12px] h-[12px]" />
                          </ButtonLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
      </Container>

      <Container size="lg">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <p className="text-2xl lg:text-4xl font-medium text-acem2-gray">
              Allied Singapore Depot Locations
            </p>
            <hr className="w-[100px] h-1 border-acem2-red" />
          </div>

          <DepotLocations
            items={depots}
            apiKey={process.env.REACT_APP_MAP_API_KEY} />
        </div>
      </Container>

      <ContactHero
        title={<>
          <p className="font-medium text-xl md:text-2xl 2xl:text-3xl text-acem2-gray">
            Ready to Streamline Your Depot Needs?
          </p>
        </>}
        description={<>
          <p className="text-lg">
            <span className="hidden md:flex">
              Our strategically located depots offer convenient access, expert staff, and advanced technology to ensure efficient cargo management.
            </span>
          </p>
        </>}
        ctaText={<>
          <span className="hidden md:flex">Contact our depot team today</span>
          <span className="flex md:hidden">Contact our team</span>
        </>}
        ctaUrl="/contact-us" />

      <CompanyHero />
    </>
  )
}
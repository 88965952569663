import * as React from "react"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { useAutoPosition } from "@allied/react-web/hooks"
import { useHero } from "@allied/react-web/Header"
import { CompanyHero } from "../features/Company/CompanyHero"

export default function Qualification(): React.JSX.Element {
  const { hero } = useHero({
    factor: 0.1
  })

  const [certifications] = React.useState([
    {
      name: "ISO 9001",
      tag: "ISO 9001 Quality Management: Elevating Standards for Allied Containers",
      description: "At Allied Containers, we proudly hold ISO 9001 certification, the internationally recognized Quality Management System (QMS) standard. This certification is a testament to our commitment to excellence and continuous improvement in all aspects of our operations.",
      short_description: "We're ISO 9001 certified! This means top quality & continuous improvement for YOUR shipments. Get a quote & experience the Allied Containers difference!",
      image: `/image/iso-9001-logo.jpg`
    },
    {
      name: "ISO 14001",
      tag: "ISO 14001 Environmental Management: Leading the Way at Allied Containers",
      description: "At Allied Containers, we're proud to be ISO 14001 approved, adhering to the world's most recognized environmental management system standard. Our commitment to sustainability and environmental responsibility drives us to uphold the highest standards in our operations.",
      short_description: "Go green with us! We're ISO 14001 certified for sustainable practices. Get a quote & experience eco-conscious shipping!",
      image: `/image/iso-14001-logo.jpg`
    },
    {
      name: "ISO 45001",
      tag: "ISO 45001 Occupational Health and Safety Management: Prioritizing Safety at Allied Containers",
      description: "Prioritizing Safety at Allied Containers ISO 45001 is more than just a standard; it's a commitment to preventing work-related injury and illness and fostering safe and healthy workplaces. At Allied Containers, we're proud to be ISO 45001 approved, demonstrating our dedication to the safety and well-being of our employees.",
      short_description: "Safety first! We're ISO 45001 certified for top safety standards for our team. Trust Allied Containers for reliable shipping. Get a quote!",
      image: `/image/iso-45001-logo.jpg`
    }
  ])
  const [awards] = React.useState([
    {
      content: <>
        <div className="grid grid-cols-12 gap-2 p-0 lg:p-4">
          <div className="col-span-6 lg:col-span-12">
            <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
              <span className="font-medium text-2xl text-acem2-gray">Industry Leader</span>
              <p className="font-light text-lg">Sarjak Appreciation - Recognized for our contributions to the logistics industry.</p>
            </div>
          </div>
          <div className="col-span-6 lg:col-span-12">
            <div className="w-full h-full flex flex-col justify-center items-center">
              <img alt="award-img" src={`/image/award-sarjak.jpg`} className="w-full aspect-classic" />
            </div>
          </div>
        </div>
      </>
    },
    {
      background: "hidden lg:flex bg-acem2-cloud",
      content: <>
        <div className="w-full flex flex-col justify-center items-center">
          <img alt="award-icon" src={`/image/delivery-drone-icon.svg`} className="w-8 h-8 mx-auto" />
        </div>
      </>
    },
    {
      content: <>
        <div className="grid grid-cols-12 gap-2 p-0 lg:p-4">
          <div className="col-span-6 lg:col-span-12">
            <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
              <span className="font-medium text-2xl text-acem2-gray">Community Champion</span>
              <p className="font-light text-lg">Radin Mas Community Club Appreciation - We're proud to support our community.</p>
            </div>
          </div>
          <div className="col-span-6 lg:col-span-12">
            <div className="w-full h-full flex flex-col justify-center items-center">
              <img alt="award-img" src={`/image/award-katoen.jpg`} className="w-full aspect-classic" />
            </div>
          </div>
        </div>
      </>
    },
    {
      background: "hidden lg:flex bg-acem2-cloud",
      content: <>
        <div className="w-full flex flex-col justify-center items-center">
          <img alt="award-icon" src={`/image/cargo-boat-icon.svg`} className="w-8 h-8 mx-auto" />
        </div>
      </>
    },
    {
      background: "hidden lg:flex bg-acem2-cloud",
      content: <>
        <div className="w-full flex flex-col justify-center items-center">
          <img alt="award-icon" src={`/image/blueprint-helmet-icon.svg`} className="w-8 h-8 mx-auto" />
        </div>
      </>
    },
    {
      content: <>
        <div className="grid grid-cols-12 gap-2 p-0 lg:p-4">
          <div className="col-span-6 lg:col-span-12">
            <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
              <span className="font-medium text-2xl text-acem2-gray">Safety First</span>
              <p className="font-light text-lg">5-Years Without Lost Time Injury - Demonstrating our unwavering commitment to workplace safety.</p>
            </div>
          </div>
          <div className="col-span-6 lg:col-span-12">
            <div className="w-full h-full flex flex-col justify-center items-center">
              <img alt="award-img" src={`/image/award-radin.jpg`} className="w-full aspect-classic" />
            </div>
          </div>
        </div>
      </>
    },
    {
      background: "hidden lg:flex bg-acem2-cloud",
      content: <>
        <div className="w-full flex flex-col justify-center items-center">
          <img alt="award-icon" src={`/image/user-group-icon.svg`} className="w-8 h-8 mx-auto" />
        </div>
      </>
    },
    {
      content: <>
        <div className="grid grid-cols-12 gap-2 p-0 lg:p-4">
          <div className="col-span-6 lg:col-span-12">
            <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
              <span className="font-medium text-2xl text-acem2-gray">Exceeding Expectations</span>
              <p className="font-light text-lg">Compliments from MPA - Recognized by the Maritime and Port Authority of Singapore for exceeding standards.</p>
            </div>
          </div>
          <div className="col-span-6 lg:col-span-12">
            <div className="w-full h-full flex flex-col justify-center items-center">
              <img alt="award-img" src={`/image/award-mpa.jpg`} className="w-full aspect-classic" />
            </div>
          </div>
        </div>
      </>
    },
  ])

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "Awards & ISO Certifications",
        }}
      </Meta>

      <div ref={hero} className="relative hidden lg:block w-full h-full">
        <div className="min-h-[500px] 3xl:min-h-[700px] bg-cover" style={{ backgroundImage: `url(${"/image/team-communication.jpg"})` }} />
        <div className="flex w-full h-full absolute left-0 top-0 bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.95)_19.77%,_rgba(255,_255,_255,_0)_100%)]">
          <Container size="lg">
            <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
              <div className="w-full max-w-[650px] bg-[rgba(255,_255,_255,_0.15)] backdrop-blur p-2 rounded">
                <p className="font-bold text-2xl lg:text-4xl">
                  <span className="text-acem2-red">Allied's</span> <span className="text-acem2-gray">Awards & Certifications</span>
                </p>
                <hr className="w-[100px] h-1 border-acem2-red my-2" />
                <p className="text-2xl font-medium text-acem2-gray">
                  At Allied Containers, we're committed to exceeding expectations and delivering exceptional service across every stage of your supply chain.
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="lg:hidden grid grid-cols-12">
        <div className="col-span-12 w-full bg-cover bg-center h-[200px]" style={{ backgroundImage: `url(${"/image/team-communication.jpg"})` }} />
        <div className="col-span-12">
          <Container size="lg">
            <div className="w-full flex flex-col gap-2 justify-center items-start my-2">
              <p className="font-medium text-3xl">
                <span className="text-acem2-red">Allied's</span> Awards & Certifications
              </p>
              <hr className="w-[100px] h-1 border-acem2-red my-2" />
              <p>
                At Allied Containers, we're committed to exceeding expectations and delivering exceptional service across every stage of your supply chain.
              </p>
            </div>
          </Container>
        </div>
      </div>

      <Container size="lg">
        <div className="grid grid-cols-12 gap-6 my-10 md:my-20">
          <div className="col-span-12 lg:col-span-6">
            <div className="grid grid-cols-12 gap-8">
              {
                certifications.map((certification, i: number) => {
                  return (
                    <React.Fragment key={`certification-item-${i}`}>
                      <div className="col-span-12">
                        <div className="w-full flex flex-row gap-6">
                          <div className="w-fit min-w-[120px] md:min-w-[150px] flex flex-col justify-center items-center">
                            <img alt="certification" src={certification.image}
                              className="w-[120px] md:w-[150px] h-[120px] md:h-[150px] aspect-square" />
                          </div>
                          <div className="w-full flex flex-col gap-4">
                            <p className="font-medium text-2xl md:text-3xl text-acem2-gray">
                              {certification.name}
                            </p>
                            <p className="hidden md:block font-medium text-xl text-acem2-gray">
                              {certification.tag}
                            </p>
                            <p className="hidden md:block text-base md:text-lg text-acem2-gray">
                              {certification.description}
                            </p>
                            <p className="block md:hidden text-base text-acem2-gray">
                              {certification.short_description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })
              }
            </div>
          </div>
          <div className="col-span-12 lg:col-span-6 hidden lg:flex bg-cover bg-right"
            style={{ backgroundImage: `url(${"/image/forklift-lifting.jpg"})` }} />
        </div>
      </Container>

      <Container size="lg">
        <div className="grid grid-cols-12 gap-6">
          <div className="hidden lg:flex lg:col-span-4 xl:col-span-5 3xl:col-span-6">
            <img alt="team-checklist" src={`/image/team-checklist.jpg`}
              className="object-cover w-full h-[320px]" />
          </div>
          <div className="col-span-12 lg:col-span-8 xl:col-span-7 3xl:col-span-6">
            <div className="w-full h-full flex flex-row gap-6 justify-start items-center">
              <div className="flex justify-center items-center">
                <img alt="bizsafe-star" src={`/image/bizsafe-star-logo.jpg`}
                  className="w-full min-w-[160px] md:min-w-[230px] aspect-[4/3]" />
              </div>
              <div className="flex flex-col gap-6">
                <p className="font-medium text-2xl md:text-3xl text-acem2-gray">
                  bizSAFE STAR
                </p>
                <p className="hidden md:flex font-medium text-xl text-acem2-gray">
                  Enhancing Workplace Safety with Allied Containers' bizSAFE STAR Certification
                </p>
                <p className="hidden md:flex text-lg md:text-xl text-acem2-gray">
                  bizSAFE is more than just a certification; it's a commitment to building workplace safety and health capabilities.
                  As an ISO 45001 certified company, Allied Containers prioritizes the well-being of our employees and partners, ensuring safe and healthy workplaces for all.
                </p>
                <p className="flex md:hidden text-lg text-acem2-gray">
                  Safety first! We're ISO 45001 certified for top safety standards for our team.
                  Trust Allied Containers for reliable shipping. Get a quote!
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Container size="lg">
        <div className="flex flex-col gap-8 my-10 md:my-20">
          <div className="flex flex-col gap-2">
            <span className="font-manrope font-medium text-sm text-acem2-red">
              Allied's Achievements
            </span>
            <p className="font-medium w-full text-2xl lg:text-4xl text-acem2-gray">
              Recognitions and Awards
            </p>
          </div>
          <div className="grid grid-cols-12 gap-8 lg:gap-0">
            {
              awards.map((award, i: number) => {
                return (
                  <React.Fragment key={`award-item-${i}`}>
                    <div className={"col-span-12 lg:col-span-3 " + award.background}>
                      {award.content}
                    </div>
                  </React.Fragment>
                )
              })
            }
          </div>
        </div>
      </Container>

      <CompanyHero />
    </>
  )
}
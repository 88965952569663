import * as React from "react"
import { GoLinkExternal } from "react-icons/go"
import { ButtonLink } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { useAutoPosition } from "@allied/react-web/hooks"
import { useHero } from "@allied/react-web/Header"
import { CompanyHero } from "../features/Company/CompanyHero"

export default function Company(): React.JSX.Element {
  const { hero } = useHero({
    factor: 0.1
  })

  const [companies] = React.useState([
    {
      name: "Winspec Group",
      tagline: <>
        <span className="font-light md:font-bold">Your Trusted Partner for Streamlined Logistics in Singapore.</span>
      </>,
      content: "Winspec Group is a leading provider of one-stop logistics solutions, specializing in warehousing & distribution, international freight (air & sea), local distribution, and ship chandler logistics.",
      url: "https://winspecgroup.com/",
      image: `/image/company-winspec.jpg`,
      services: [
        {
          name: "Warehousing & Distribution",
          icon: `/image/warehouse-icon.svg`,
        },
        {
          name: "International Freight (Air & Sea)",
          icon: `/image/building-icon.svg`,
        },
        {
          name: "Local Distribution",
          icon: `/image/belt-icon.svg`,
        },
        {
          name: "Ship Chandler Logistics",
          icon: `/image/cargo-boat-icon.svg`,
        },
      ]
    },
    {
      name: "AG Logis Solutions",
      tagline: <>
        <span className="font-light md:font-bold">Optimize Your Container Needs: Sales, Leasing, and Modifications.</span>
      </>,
      content: "AG Logis is your one-stop shop for new and used containers, offering a wide range of options to meet your specific needs. They also provide container customization and leasing solutions.",
      url: "https://aglogis.com/",
      image: `/image/company-aglogis.jpg`,
      services: [
        {
          name: "New & Used Containers",
          icon: `/image/container-icon.svg`,
        },
        {
          name: "Container Leasing",
          icon: `/image/building-icon.svg`,
        },
        {
          name: "Container Modifications",
          icon: `/image/spray-can-icon.svg`,
        },
        {
          name: "Industrial Supplies",
          icon: `/image/battery-icon.svg`,
        },
      ]
    },
    {
      name: "Allied Food (S)",
      tagline: <>
        <span className="hidden lg:flex font-light md:font-bold">Premium Food Produce, Kitchen Equipment, Maintenance & Support Services, Warehousing & Logistics solutions</span>
        <span className="flex lg:hidden font-light md:font-bold">Premium Food Produce, Kitchen Equipment & Warehousing solutions</span>
      </>,
      content: "Source the finest ingredients, optimize kitchen operations with high-performance equipment and expert support, and ensure seamless delivery with dedicated logistics solutions.",
      url: "https://alliedfood.sg/",
      image: `/image/company-afood.jpg`,
      services: [
        {
          name: "Premium Food Produce",
          icon: `/image/toast-icon.svg`,
        },
        {
          name: "High-Performance Kitchen Equipment",
          icon: `/image/microwave-icon.svg`,
        },
        {
          name: "Expert Maintenance & Support",
          icon: `/image/wrench-hand-icon.svg`,
        },
        {
          name: "Warehousing & Logistics Solutions",
          icon: `/image/warehouse-icon.svg`,
        },
      ]
    },
    {
      name: "GSI Global Energy Pte Ltd",
      tagline: <></>,
      content: null,
      url: "https://gsi-ge.com/",
      image: `/image/company-gsi.jpg`,
      services: [
        {
          name: "Lubricant",
          icon: `/image/wrench-hand-icon.svg`,
        },
        {
          name: "EV Battery",
          icon: `/image/battery-icon.svg`,
        },
        {
          name: "Green Hydrogen",
          icon: `/image/spray-can-icon.svg`,
        },
        {
          name: "Alternate Energy",
          icon: `/image/earth-globe-icon.svg`,
        },
      ]
    },
  ])

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "Sister Company",
        }}
      </Meta>

      <div ref={hero} className="relative hidden lg:block w-full h-full">
        <div className="min-h-[500px] 3xl:min-h-[700px] bg-cover" style={{ backgroundImage: `url(${"/image/package-checking.jpg"})` }} />
        <div className="flex w-full h-full absolute left-0 top-0 bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.95)_19.77%,_rgba(255,_255,_255,_0)_100%)]">
          <Container size="lg">
            <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
              <div className="w-full max-w-[650px] bg-[rgba(255,_255,_255,_0.15)] backdrop-blur p-2 rounded">
                <p className="font-bold text-2xl lg:text-4xl text-acem2-gray">
                  Powerful Partnerships, Powerful Results
                </p>
                <hr className="w-1/4 h-1 border-acem2-red my-2" />
                <p className="text-lg lg:text-xl font-normal lg:font-medium text-acem2-gray">
                  We're not just a logistics provider - we're your strategic partner for all your supply chain needs.
                  But our expertise extends beyond just Allied Containers.  We are part of a powerful network of sister companies, each specializing in a different area to provide you with a comprehensive suite of services under one roof.
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="grid grid-cols-12 lg:hidden">
        <div className="col-span-12 w-full bg-cover bg-center h-[200px]" style={{ backgroundImage: `url(${"/image/package-checking.jpg"})` }} />
        <div className="col-span-12">
          <Container size="lg">
            <div className="w-full flex flex-col gap-2 justify-center items-start my-2">
              <p className="font-medium text-3xl">
                Powerful Partnerships, Powerful Results
              </p>
              <hr className="w-[100px] h-1 border-acem2-red my-2" />
              <p>
                We're not just a logistics provider - we're your strategic partner for all your supply chain needs.
                But our expertise extends beyond just Allied Containers.  We are part of a powerful network of sister companies, each specializing in a different area to provide you with a comprehensive suite of services under one roof.
              </p>
            </div>
          </Container>
        </div>
      </div>

      <Container size="lg">
        <div className="my-10 lg:my-20">
          {
            companies.map((company, i: number) => {
              return (
                <React.Fragment key={`company-item-${i}`}>
                  <div className="grid grid-cols-12 gap-4 md:gap-0 mb-10 md:mb-0">
                    <div className={"col-span-12 md:col-span-6 " + (i % 2 === 0 ? "md:order-last" : "")}>
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-2 md:hidden">
                          <p className="font-medium text-2xl lg:text-4xl text-acem2-gray">
                            {company.name}
                          </p>
                          <div className="font-medium text-lg lg:text-xl text-acem2-gray">
                            {company.tagline}
                          </div>
                        </div>
                        <img alt="company" src={company.image}
                          className="w-full h-full md:min-h-[400px] xl:min-h-[600px] aspect-[4/3] object-cover" />
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <div className="w-full h-full px-0 md:px-4 md:py-4 xl:px-10 xl:py-10 3xl:px-20 bg-transparent md:bg-acem2-cloud flex flex-col gap-4 justify-center items-start">
                        <div className="hidden md:flex flex-col gap-4">
                          <p className="font-medium text-2xl lg:text-4xl text-acem2-gray">
                            {company.name}
                          </p>
                          <hr className="w-[100px] h-1 border-acem2-red" />
                          <div className="font-medium text-lg lg:text-xl text-acem2-gray">
                            {company.tagline}
                          </div>
                        </div>
                        <div className="hidden xl:flex flex-col gap-4">
                          <div className="text-lg lg:text-xl text-acem2-gray">
                            {company.content}
                          </div>
                        </div>
                        <div className="w-full grid grid-cols-12 gap-2 py-2">
                          {
                            company.services.map((service, j: number) => {
                              return (
                                <React.Fragment key={`service-item-${i}-${j}`}>
                                  <div className="col-span-6 md:col-span-12 lg:col-span-6">
                                    <div className="w-full h-full flex flex-row gap-2.5 justify-start items-center">
                                      <img alt="company-service" src={service.icon} className="w-5 h-5" />
                                      <p className="text-lg text-acem2-gray">
                                        {service.name}
                                      </p>
                                    </div>
                                  </div>
                                </React.Fragment>
                              )
                            })
                          }
                        </div>
                        <div className="w-full flex justify-center lg:justify-start items-center">
                          <ButtonLink to={company.url} target="_blank" variant="primary" hideCrawl
                            appendClassNames="flex flex-row gap-2.5 justify-center items-center">
                            <span>Visit Site</span>
                            <GoLinkExternal aria-hidden="true" className="text-white w-[12px] h-[12px]" />
                          </ButtonLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
      </Container>

      <CompanyHero />
    </>
  )
}
import * as React from "react"
import { LayoutGroup, motion, useScroll, useTransform } from "framer-motion"
import styles from "./CompanyTagline.module.css"

type CompanyTaglineProps = {
	transitionContent?: React.ReactNode
	firstContent?: React.ReactNode
	lastContent?: React.ReactNode
}

export function CompanyTagline(props: CompanyTaglineProps): React.JSX.Element {
	const {
		transitionContent,
		firstContent,
		lastContent,
	} = props

	const container = React.useRef()
	const { scrollYProgress: pageScrollYProgress } = useScroll({
		axis: "y",
		offset: ["start start", "0.7 1"],
	})

	const mobileTruckMovement = useTransform(pageScrollYProgress, [0, 1], ["400%", "-400%"])
	const desktopTruckMovement = useTransform(pageScrollYProgress, [0, 1], ["200%", "-200%"])
	const firstOpacity = useTransform(pageScrollYProgress, [0, 0.2, 0.4, 0.5], [1, 0.8, 0.8, 0])
	const lastOpacity = useTransform(pageScrollYProgress, [0.5, 0.6, 0.8, 1], [0, 0.8, 0.9, 1])

	return (
		<LayoutGroup>
			<div ref={container} className={"relative w-full min-h-[550vh] lg:min-h-[550vh] xl:min-h-[550vh] 3xl:min-h-[300vh] " + styles["carousel-section"]}>
				<div className="sticky top-[10rem] w-full box-border">

					<div className="absolute top-[50%] w-full flex justify-center items-center">
						<motion.div className="absolute w-full" style={{ opacity: firstOpacity }}>
							{firstContent}
						</motion.div>
						<motion.div className="absolute w-full" style={{ opacity: lastOpacity }}>
							{lastContent}
						</motion.div>
					</div>

					<motion.div className="relative w-fit h-full flex xl:hidden flex-row flex-nowrap items-stretch gap-10 md:gap-28"
						style={{ x: mobileTruckMovement }}>
						<div className={"relative w-[100vw] max-w-full h-[50vh] " + styles["carousel-item"]}>
							{transitionContent}
						</div>
					</motion.div>

					<motion.div className="relative w-fit h-full hidden xl:flex flex-row flex-nowrap items-stretch gap-10 md:gap-28"
						style={{ x: desktopTruckMovement }}>
						<div className={"relative w-[100vw] max-w-full h-[50vh] " + styles["carousel-item"]}>
							{transitionContent}
						</div>
					</motion.div>
				</div>
			</div>
		</LayoutGroup>
	)
}
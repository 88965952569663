import * as React from "react"
import { ButtonLink } from "@allied/react-web/Button"

export function Info(): React.JSX.Element {
  return (
    <div aria-label="info"> {/*  @note: do not remove to avoid below element attributes disappear */}
      <div className="h-screen flex flex-col justify-center items-center">
        <div className="my-4 flex flex-col gap-2 justify-center items-center">
          <h1 className="text-4xl md:text-8xl">
            404
          </h1>
          <h2 className="text-2xl md:text-2xl">
            Sorry we could not find this page
          </h2>
        </div>
        <div className="my-4">
          <ButtonLink to="/" size="lg">
            Back to Home
          </ButtonLink>
        </div>
      </div>
    </div>
  )
}
import * as React from "react"
import { ButtonLink } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"

type ContactHeroProps = {
  title: React.ReactNode
  description?: React.ReactNode
  background?: string
  ctaText?: React.ReactNode
  ctaUrl?: string
}

export function ContactHero(props: ContactHeroProps): React.JSX.Element {
  const {
    title,
    description,
    background = `/image/airplane-hangar.png`,
    ctaText,
    ctaUrl
  } = props

  return (
    <div className="bg-acem2-twinkle my-10 md:my-20">
      <div className="flex justify-center items-end bg-no-repeat bg-cover bg-center min-h-[350px]" style={{ background: `url(${background})` }}>
        <Container size="lg">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-6">
              <div className="w-full h-full flex justify-start md:justify-center items-end">
                <img alt="contact" src={`/image/chat-operator.png`} />
              </div>
            </div>
            <div className="col-span-6">
              <div className="w-full h-full flex flex-col justify-center items-start gap-3 lg:gap-6 z-[1]">
                <div>
                  {title}
                </div>
                {
                  description &&
                  <div>
                    {description}
                  </div>
                }
                {
                  ctaUrl && ctaText &&
                  <div>
                    <ButtonLink to={ctaUrl} variant="primary">
                      {ctaText}
                    </ButtonLink>
                  </div>
                }
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
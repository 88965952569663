import * as React from "react"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { useAutoPosition } from "@allied/react-web/hooks"
import { useHero } from "@allied/react-web/Header"
import { CompanyHero } from "../features/Company/CompanyHero"
import { ContactHero } from "../features/Contact/ContactHero"

export default function Project(): React.JSX.Element {
  const { hero } = useHero({
    factor: 0.1
  })

  const [goods] = React.useState([
    {
      image: `/image/reactor.png`,
    },
    {
      name: "Reactors",
    },
    {
      image: `/image/boiler.png`,
    },
    {
      name: "Generators",
    },
    {
      image: `/image/oil-rig.png`,
    },
    {
      name: "Turbines",
    },
    {
      image: `/image/turbine.png`,
    },
    {
      name: "Boilers",
    },
    {
      image: `/image/generator.png`,
    },
    {
      name: "Oil Rigs",
    },
  ])
  const [projects] = React.useState([
    {
      name: "Oil and Gas",
      content: "Our expertise extends to supporting upstream, midstream, and downstream sectors of the oil and gas industry. We offer solutions for transporting drilling rigs, pipelines, processing equipment, and other essential components, ensuring they arrive at their destination on time and within budget.",
      thumbnail: `/image/oil-gas.jpg`,
    },
    {
      name: "EPC and Infrastructure",
      content: "Our team has the expertise to engineer and execute complex logistics plans for large-scale EPC (Engineering, Procurement, and Construction) projects and infrastructure developments. We can handle the transportation of industrial plants, modules, and other essential components, ensuring they arrive at the construction site on schedule and within budget",
      thumbnail: `/image/epc-infrastructure.jpg`,
    },
    {
      name: "Power and Industrial",
      content: "Whether you're planning a power plant, grid integration project, or constructing a new industrial facility, Allied Containers can handle the transportation of transformers, generators, heavy machinery, and other large-scale equipment. We provide customized solutions that optimize costs, minimize risks, and ensure the efficient delivery of your essential equipment.",
      thumbnail: `/image/power-industrial.jpg`,
    },
    {
      name: "Renewable Energy",
      content: "We specialize in transporting wind turbines, solar panels, battery storage units, and other critical components for renewable energy projects. We understand the unique challenges associated with these oversized and often fragile components, and we have the specialized equipment and experienced personnel to ensure their safe and secure delivery to the construction site.",
      thumbnail: `/image/renewable-energy.jpg`,
    },
  ])
  const [advantages, setAdvantages] = React.useState([
    {
      order: "01",
      name: "Deep Dive",
      content: "We start by getting to know your project inside-out. We analyze cargo details, timelines, origin-destination data, and your unique needs.",
      opened: true,
    },
    {
      order: "02",
      name: "Industry Insights",
      content: "We don't operate in a vacuum. We research industry best practices and historical data to identify potential bottlenecks and craft solutions that surpass the norm.",
      opened: false,
    },
    {
      order: "03",
      name: "Data-Driven Decisions",
      content: "Real-time traffic flow, fuel costs, and weather forecasts are integrated into our planning. This ensures dynamic route optimization, minimizing delays and maximizing cost-efficiency.",
      opened: false,
    },
    {
      order: "04",
      name: "Picking the Perfect Partners",
      content: "Real-time traffic flow, fuel costs, and weather forecasts are integrated into our planning. This ensures dynamic route optimization, minimizing delays and maximizing cost-efficiency.",
      opened: false,
    },
    {
      order: "05",
      name: "Multi-Modal Magic",
      content: "Air, sea, land – we explore every option to create a cost-effective, multi-modal plan that leverages the most efficient mode for each leg of your project.",
      opened: false,
    },
    {
      order: "06",
      name: "Visualize Your Success",
      content: "Gone are the days of static maps. We utilize advanced software for detailed route simulations, allowing you to collaborate and visualize the optimal transportation strategy.",
      opened: false,
    },
    {
      order: "07",
      name: "Permitting Powerhouse",
      content: "We research and acquire all necessary permits for oversized or hazardous cargo. Our meticulous planning ensures a smooth and compliant journey",
      opened: false,
    },
    {
      order: "08",
      name: "Real-Time Transparency",
      content: "Clear communication is key. Data-sharing platforms provide complete project visibility, keeping you informed every step of the way.",
      opened: false,
    }
  ])

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "Projects",
        }}
      </Meta>

      <div ref={hero} className="relative hidden lg:block w-full h-full">
        <div className="min-h-[500px] 3xl:min-h-[700px] bg-cover" style={{ backgroundImage: `url(${"/image/container-exhaust.jpg"})` }} />
        <div className="flex w-full h-full absolute left-0 top-0 bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.95)_19.77%,_rgba(255,_255,_255,_0)_100%)]">
          <Container size="lg">
            <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
              <div className="w-full max-w-[650px] bg-[rgba(255,_255,_255,_0.15)] backdrop-blur p-2 rounded">
                <span className="font-manrope font-black text-acem2-gray text-sm">
                  Building Success Stories, Together
                </span>
                <p className="font-bold text-2xl lg:text-4xl">
                  <span className="text-acem2-red">Allied </span>
                  <span className="text-acem2-gray">Delivers End-to-End</span>
                  <span className="block text-acem2-gray"> Project Solutions</span>
                </p>
                <hr className="w-[100px] h-1 border-acem2-red my-2" />
                <p className="text-xl font-medium text-acem2-gray">
                  We offer comprehensive project solutions that encompass the entire supply chain, seamlessly integrated with innovative software solutions.
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="lg:hidden grid grid-cols-12">
        <div className="col-span-12 w-full bg-cover bg-top h-[200px]" style={{ backgroundImage: `url(${"/image/container-exhaust.jpg"})` }} />
        <div className="col-span-12">
          <Container size="md">
            <div className="flex flex-col gap-2 justify-center items-start my-2">
              <span className="w-full font-manrope font-medium text-acem2-red text-sm">
                Building Success Stories, Together
              </span>
              <p className="font-medium text-3xl">
                Allied Containers Delivers End-to-End Project Solutions
              </p>
              <hr className="w-[100px] h-1 border-acem2-red my-2" />
              <p>
                We offer comprehensive project solutions that encompass the entire supply chain, seamlessly integrated with innovative software solutions.
              </p>
            </div>
          </Container>
        </div>
      </div>

      <Container size="lg">
        <div className="flex flex-col gap-8 my-10 md:my-20">
          <div className="flex flex-col gap-2 justify-center items-start">
            <span className="w-full font-manrope font-medium text-sm text-acem2-red">
              Your Reliable Partner for Complex Project Logistics
            </span>
            <p className="text-2xl lg:text-3xl 2xl:text-4xl text-acem2-gray">
              No Matter What You Ship, We Ship It Right
            </p>
          </div>
          <div className="grid grid-flow-col auto-rows-fr grid-rows-5 lg:grid-rows-2 lg:grid-flow-row lg:grid-cols-5">
            {
              goods.map((item, i: number) => {
                return (
                  <React.Fragment key={`goods-item-${i}`}>
                    <div className={
                      "h-36 lg:h-72 px-10 py-2 flex justify-center items-center text-center " +
                      (i % 2 === 0 ? "bg-acem2-cloud" : "")
                    }>
                      {
                        item.name &&
                        <span className="font-medium text-lg text-acem2-gray">
                          {item.name}
                        </span>
                      }
                      {
                        item.image &&
                        <img alt="goods-icon" src={item.image}
                          className={
                            "w-[80px] h-[80px] lg:w-[150px] lg:h-[150px] mx-auto " +
                            "[filter:drop-shadow(12px_19px_9px_rgba(0,_0,_0,_0.04))drop-shadow(7px_11px_8px_rgba(0,_0,_0,_0.12))drop-shadow(3px_5px_6px_rgba(0,_0,_0,_0.2))drop-shadow(1px_1px_3px_rgba(0,_0,_0,_0.24))]"
                          } />
                      }
                    </div>
                  </React.Fragment>
                )
              })
            }
          </div>
        </div>
      </Container>

      <div className="bg-acem2-cloud">
        <Container size="lg">
          <div className="grid grid-cols-12 gap-2 2:gap-6 py-6">
            <div className="col-span-12 md:col-span-5 2xl:col-span-4">
              <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
                <span className="font-manrope font-medium text-sm text-acem2-red">
                  Why Allied
                </span>
                <p className="font-semibold text-2xl lg:text-3xl 3xl:text-4xl text-acem2-gray">
                  Allied Containers for Your Project Logistics Needs
                </p>
                <hr className="w-[100px] h-1 border-acem2-red" />
                <p className="text-lg 2xl:text-xl text-acem2-gray py-4">
                  At Allied Containers, we understand the intricate challenges involved in transporting oversized, heavy, or delicate cargo for various industrial projects.
                  We go beyond standard freight services, offering comprehensive project logistics solutions to ensure the safe, efficient, and cost-effective delivery of your critical equipment and materials.
                </p>
              </div>
            </div>
            <div className="hidden md:flex md:col-span-1"></div>
            <div className="col-span-12 md:col-span-6 2xl:col-span-7">
              <div className="w-full h-full flex justify-center items-center">
                <img alt="container-carrry" src={`/image/container-carry.png`}
                  className={
                    "w-full max-w-[920px] aspect-classic " +
                    "[filter:drop-shadow(12px_19px_9px_rgba(0,_0,_0,_0.04))drop-shadow(7px_11px_8px_rgba(0,_0,_0,_0.12))drop-shadow(3px_5px_6px_rgba(0,_0,_0,_0.2))drop-shadow(1px_1px_3px_rgba(0,_0,_0,_0.24))]"
                  } />
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="my-10 md:my-20">
        <Container size="lg">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2 justify-center items-start">
              <span className="w-full font-manrope font-medium text-sm text-acem2-red">
                Feeling overwhelmed by complex project logistics?
              </span>
              <p className="text-2xl lg:text-3xl 3xl:text-4xl text-acem2-gray">
                Allied Containers Optimizes Your Project Network
              </p>
            </div>

            {
              advantages.length > 0 &&
              <div className={
                "grid grid-cols-12 auto-cols-fr " +
                (advantages.filter((advantage) => advantage.opened).length > 0 ? "xl:grid-cols-9" : "xl:rid-cols-8")
              }>
                {
                  advantages.map((advantage, i: number) => {
                    return (
                      <React.Fragment key={`advantage-item-${i}`}>
                        <div className={
                          "col-span-12 md:col-span-6 " +
                          (advantage.opened ? "xl:col-span-2" : "xl:col-span-1")
                        }>
                          <div className={
                            "w-full h-full xl:min-h-[480px] flex flex-col  " +
                            (i % 2 === 0 ? "justify-start " : "justify-end ")
                          }>
                            <div className="w-full min-h-[160px] md:min-h-[200px] xl:min-h-[240px] flex flex-col gap-2.5 justify-center items-start p-4 bg-acem2-cloud cursor-pointer"
                              onClick={() => {
                                setAdvantages((prevState) => {
                                  return prevState.map((advantage, j: number) => {
                                    return {
                                      ...advantage,
                                      opened: (i === j ? !advantage.opened : false)
                                    }
                                  })
                                })
                              }}>
                              <p className="font-semibold text-xl lg:text-2xl 2xl:text-3xl text-acem2-red">
                                {advantage.order}
                              </p>
                              <p className={
                                "text-lg lg:text-xl text-black " +
                                (advantage.opened ? "font-semibold 2xl:text-2xl" : "2xl:text-xl")
                              }>
                                {advantage.name}
                              </p>

                              {
                                advantage.opened &&
                                <div className="overflow-y-auto max-h-[80px] 2xl:max-h-[100px]">
                                  <p className="text-base lg:text-lg text-black">
                                    {advantage.content}
                                  </p>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })
                }
              </div>
            }
          </div>
        </Container>
      </div>

      <ContactHero
        background={`/image/high-propeller.png`}
        title={<>
          <p className="font-medium text-xl lg:text-2xl text-acem2-gray">
            Ready to Streamline Your Project Logistics?
          </p>
        </>}
        ctaText={<>
          <span className="hidden md:block">Talk to a Logistics Expert Now</span>
          <span className="block md:hidden">Talk to Expert</span>
        </>}
        ctaUrl="/contact-us" />

      <div className="my-10 md:my-20">
        <Container size="lg">
          {
            projects.map((project, i: number) => {
              return (
                <React.Fragment key={`project-item-${i}`}>
                  <div className="grid grid-cols-12 gap-4 md:gap-0 mb-10 md:mb-0">
                    <div className={"col-span-12 md:col-span-6 " + (i % 2 === 0 ? "md:order-last" : "")}>
                      <div className="w-full h-full flex flex-col gap-4">
                        <div className="flex flex-col gap-2 md:hidden">
                          <p className="font-medium text-2xl xl:text-3xl 3xl:text-4xl text-acem2-gray">
                            {project.name}
                          </p>
                        </div>
                        <img alt="project" src={project.thumbnail}
                          className="w-full h-full min-h-[350px] aspect-video object-cover" />
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <div className="w-full h-full px-0 md:px-4 md:py-4 xl:px-10 xl:py-10 3xl:px-20 bg-transparent md:bg-acem2-cloud flex flex-col gap-4 justify-center items-start">
                        <div className="hidden md:flex flex-col gap-4">
                          <p className="font-medium text-2xl xl:text-3xl 3xl:text-4xl text-acem2-gray">
                            {project.name}
                          </p>
                          <hr className="w-[100px] h-1 border-acem2-red" />
                        </div>
                        <div className="flex flex-col gap-4">
                          <div className="text-lg lg:text-xl text-acem2-gray">
                            {project.content}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </Container>
      </div>

      <CompanyHero
        rightImage={`/image/goods-checking.jpg`}
        rightShortText="Discover Our Story"
        rightLongText="Discover Our Story" />
    </>
  )
}
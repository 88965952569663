import * as React from "react"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { useAutoPosition } from "@allied/react-web/hooks"
import { useHero } from "@allied/react-web/Header"
import { CompanyHero } from "../features/Company/CompanyHero"

export default function Sustainability(): React.JSX.Element {
  const { hero } = useHero({
    factor: 0.1
  })

  const [solutions] = React.useState([
    {
      name: "Electrifying Our Fleet",
      thumbnail: `/image/comprehensive-1.jpg`,
      advantages: [
        {
          name: "Leading by Example",
          description: "We're actively investing in electric vehicles (EVs) for both our short-haul trucks and depot operations, including electric stackers. This comprehensive approach minimizes emissions across all aspects of our business, promoting cleaner air in urban areas."
        },
        {
          name: "Future-Proofing Our Operations",
          description: "We're constantly evaluating the latest technologies and infrastructure advancements to expand our EV fleet and further reduce our reliance on fossil fuels."
        }
      ]
    },
    {
      name: "Carbon Tracking",
      thumbnail: `/image/comprehensive-2.jpg`,
      advantages: [
        {
          name: "Comprehensive Measurement",
          description: "We meticulously track our carbon footprint across all our operations. This data-driven approach allows us to identify areas for improvement and measure the effectiveness of our sustainability initiatives."
        },
        {
          name: "Collaborative Reduction Strategy",
          description: "We collaborate with fuel-efficient carriers and continuously explore route optimization techniques. This comprehensive strategy reduces carbon emissions across the entire supply chain."
        }
      ]
    },
    {
      name: "Renewable Energy",
      thumbnail: `/image/comprehensive-3.jpg`,
      advantages: [
        {
          name: "Investing in Solar Power",
          description: "We're actively exploring the integration of solar panels at our facilities. This shift towards clean, renewable energy sources will power our operations and set an example for the logistics industry."
        },
        {
          name: "Sustainable Future Focus",
          description: "By embracing solar energy, we're reducing our dependence on fossil fuels and contributing to a more sustainable logistics landscape."
        }
      ]
    }
  ])

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "Sustainability",
        }}
      </Meta>

      <div ref={hero} className="relative hidden lg:block w-full h-full">
        <div className="min-h-[500px] 3xl:min-h-[700px] bg-cover" style={{ backgroundImage: `url(${"/image/plant-tree.jpg"})` }} />
        <div className="flex w-full h-full absolute left-0 top-0 bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.95)_19.77%,_rgba(255,_255,_255,_0)_100%)]">
          <Container size="lg">
            <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
              <div className="w-full max-w-[650px] bg-[rgba(255,_255,_255,_0.15)] backdrop-blur p-2 rounded">
                <span className="font-manrope font-bold text-sm text-acem2-gray">
                  Committed to a Sustainable Future
                </span>
                <p className="font-bold text-2xl lg:text-4xl">
                  <span className="block text-acem2-gray">Go Green, Go Global:</span>
                  <span className="text-acem2-red">Allied </span>
                  <span className="text-acem2-gray">Delivers on Both.</span>
                </p>
                <hr className="w-[100px] h-1 border-acem2-red my-2" />
                <p className="text-lg lg:text-xl font-normal lg:font-medium text-acem2-gray">
                  We're actively reducing our footprint through cleaner fuels, eco-friendly packaging, efficient warehouses, and electric forklifts.
                  Partner with us for a sustainable supply chain.
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="lg:hidden grid grid-cols-12">
        <div className="col-span-12 w-full bg-cover bg-center h-[200px]" style={{ backgroundImage: `url(${"/image/plant-tree.jpg"})` }} />
        <div className="col-span-12">
          <Container size="lg">
            <div className="flex flex-col gap-2 justify-center items-start my-2">
              <span className="w-full font-manrope font-medium text-acem2-red text-sm">
                Committed to a Sustainable Future
              </span>
              <p className="font-medium text-3xl">
                Go Green, Go Global: Allied Delivers on Both.
              </p>
              <hr className="w-[100px] h-1 border-acem2-red my-2" />
              <p>
                We're actively reducing our footprint through cleaner fuels, eco-friendly packaging, efficient warehouses, and electric forklifts.
                Partner with us for a sustainable supply chain.
              </p>
            </div>
          </Container>
        </div>
      </div>

      <div className="bg-acem2-cloud my-10 md:my-20">
        <Container size="lg">
          <div className="grid grid-cols-12 gap-6 py-6">
            <div className="col-span-12 xl:col-span-5 3xl:col-span-4">
              <div className="w-full h-full flex flex-col justify-center items-start gap-2">
                <span className="font-manrope font-medium text-sm text-acem2-red">
                  Why Allied
                </span>
                <p className="text-2xl xl:text-3xl 3xl:text-4xl font-medium text-acem2-gray">
                  Sustainable Logistics: Allied Containers Leads the Way
                </p>
                <hr className="w-[100px] h-1 border-acem2-red" />
                <p className="text-xl text-acem2-gray">
                  At Allied Containers, environmental responsibility is more than just a buzzword – it's a core principle that drives our business practices.
                  We're committed to innovative solutions that minimize our environmental impact and support a sustainable future for the logistics industry.
                </p>
              </div>
            </div>
            <div className="col-span-12 xl:col-span-1 3xl:col-span-1"></div>
            <div className="col-span-12 xl:col-span-6 3xl:col-span-7">
              <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
                <img alt="turbine-mountain" src={`/image/turbine-mountain.jpg`}
                  className="w-full aspect-cinema object-cover object-center" />
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Container size="lg">
        <div className="flex flex-col gap-6 mb-10 md:mb-20">
          <div className="flex flex-col gap-2">
            <span className="font-manrope font-medium text-sm text-acem2-red">
              Escape the Multi-Partner Maze
            </span>
            <p className="font-medium !leading-[150%] text-2xl xl:text-3xl 3xl:text-4xl text-acem2-gray">
              We Don't Just Deliver the Middle: <span className="text-acem2-red">Allied Containers</span>, Your Complete Logistics Partner
            </p>
            <p className="text-base md:text-xl text-acem2-gray">
              While we're renowned for our efficient middle-mile expertise, <span className="text-acem2-red">Allied Containers</span> offers a comprehensive suite of logistics solutions to seamlessly manage your entire supply chain.
            </p>
          </div>
          <div>
            {
              solutions.map((solution, i: number) => {
                return (
                  <React.Fragment key={`solution-item-${i}`}>
                    <div className="grid grid-cols-12">
                      <div className="col-span-6">
                        <div className="w-full h-full px-4 lg:px-20 bg-acem2-cloud flex flex-col gap-4 justify-center items-start">
                          <div className="flex flex-col gap-2">
                            <p className="text-2xl lg:text-3xl font-medium text-acem2-gray">
                              {solution.name}
                            </p>
                            <hr className="w-[100px] h-1 border-acem2-red" />
                          </div>
                          {
                            solution.advantages.length > 0 &&
                            <div className="hidden lg:flex flex-col gap-2">
                              {
                                solution.advantages.map((advantage, i: number) => {
                                  return (
                                    <React.Fragment key={`advantage-item-${i}`}>
                                      <div className="text-lg text-acem2-gray">
                                        <span className="font-bold">
                                          {advantage.name}: {" "}
                                        </span>
                                        {advantage.description}
                                      </div>
                                    </React.Fragment>
                                  )
                                })
                              }
                            </div>
                          }
                        </div>
                      </div>
                      <div className={"col-span-6 " + (i % 2 === 0 ? "order-first" : "")}>
                        <div className="w-full h-full">
                          <img alt="solution" src={solution.thumbnail}
                            className="w-full min-h-[360px] lg:min-h-[480px] object-cover aspect-video" />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })
            }
          </div>
        </div>
      </Container>

      <CompanyHero />
    </>
  )
}
import * as React from "react"
import { useParams } from "react-router-dom"
import { FiClock, FiHome } from "react-icons/fi"
import { useAutoPosition } from "@allied/react-web/hooks"
import { ButtonLink } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { Spinner } from "@allied/react-web/Spinner"
import { Info } from "../components/Page"
import { Job as JobService } from "../services/gsheet/Job"

type JobItem = {
  id: string
  title: string
  description: string
  responsibility: string
  requirement: string
  company_name: string
  department_name: string
  employment_type: string
  posted_at?: Date
}

const jobService = new JobService()

export default function JobDetail(): React.JSX.Element {
  const { slug } = useParams()
  const [loading, setLoading] = React.useState(false)
  const [job, setJob] = React.useState<JobItem>(null)

  React.useEffect(() => {
    async function getJob() {
      setLoading(true)
      const getJob = await jobService.GetJob({
        slug
      })
      setLoading(false)
      if (getJob.error) {
        return
      }

      setJob(getJob.data)
    }

    getJob()
  }, [slug])

  useAutoPosition()

  return (
    <>
      {
        !job && !loading &&
        <>
          <Meta>
            {{
              title: "404 Page not found",
            }}
          </Meta>

          <Info />
        </>
      }

      {
        loading &&
        <>
          <Meta>
            {{
              title: "Job Detail",
            }}
          </Meta>

          <Container size="md">
            <div className="flex justify-center items-center min-h-screen h-full">
              <Spinner label="Loading..." labeled />
            </div>
          </Container>
        </>
      }

      {
        job && !loading &&
        <>
          <Meta>
            {{
              title: `Job: ${job.title}`,
              description: `${job.description || job.title}`
            }}
          </Meta>

          <Container size="md">
            <div className="grid grid-cols-12 gap-2 my-10">
              <div className="col-span-12 lg:col-span-5 xl:col-span-4 lg:bg-acem2-cloud lg:order-last lg:p-4">
                <div className="flex flex-col gap-4 mb-10">
                  <p className="font-medium text-3xl lg:text-4xl">{job.title}</p>
                  <p className="text-xl lg:text-2xl">
                    Join our passionate team and revolutionize the supply chain with cutting-edge technology
                  </p>
                  <div className="flex flex-row lg:flex-col gap-2">
                    <div className="flex flex-row gap-2 justify-start items-center">
                      <FiClock className="w-4 h-4 text-acem2-red" aria-hidden="true" />
                      <span className="text-lg">{job.employment_type}</span>
                    </div>
                    <div className="flex flex-row gap-2 justify-start items-center">
                      <FiHome className="w-4 h-4 text-acem2-red" aria-hidden="true" />
                      <span className="text-lg">{job.department_name}</span>
                    </div>
                  </div>
                  <div className="w-full flex justify-center items-center">
                    <ButtonLink variant="primary" to="mailto:careers@allied.com.sg">
                      Apply for this job
                    </ButtonLink>
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-7 xl:col-span-8">
                <div className="flex flex-col gap-4">
                  {
                    job.description &&
                    <div className="flex flex-col gap-2">
                      <div className="font-bold text-2xl">About us</div>
                      <div className="text-lg" dangerouslySetInnerHTML={{ __html: job.description }}></div>
                    </div>
                  }

                  {
                    job.responsibility &&
                    <div className="flex flex-col gap-2">
                      <div className="font-bold text-2xl">What yo'll do</div>
                      <div className="text-lg" dangerouslySetInnerHTML={{ __html: job.responsibility }}></div>
                    </div>
                  }

                  {
                    job.requirement &&
                    <div className="flex flex-col gap-2">
                      <div className="font-bold text-2xl">What you'll need</div>
                      <div className="text-lg" dangerouslySetInnerHTML={{ __html: job.requirement }}></div>
                    </div>
                  }

                  <div className="w-full flex justify-center items-center">
                    <ButtonLink variant="primary" to="mailto:careers@allied.com.sg">
                      Apply for this job
                    </ButtonLink>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </>
      }
    </>
  )
}
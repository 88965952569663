import * as React from "react"
import { Alert, AlertVariant } from "@allied/react-web/Alert"
import { Validator, useAutoPosition, useRecaptcha, useValidator } from "@allied/react-web/hooks"
import { Container } from "@allied/react-web/Container"
import { Loader } from "@allied/react-web/Loader"
import { Meta } from "@allied/react-web/Meta"
import { Link } from "@allied/react-web/Router"
import { RiLinkedinBoxFill, RiFacebookCircleFill } from "react-icons/ri"
import { useHero } from "@allied/react-web/Header"
import { toast } from "../features/Toast"
import { OfficeLocation } from "../features/Company/OfficeLocation"
import { CompanyHero } from "../features/Company/CompanyHero"
import { ContactForm, SubmitMessage } from "../features/Contact/ContactForm"
import { ContactTable } from "../features/Contact/ContactTable"
import { Contact as ContactService } from "../services/api/Contact"

type ContactEnquiry = {
  verificationToken: string
  companyName: string
  location: string
  name: string
  email: string
  phone: string
  message: string
  about: string
}

const contactService = new ContactService({
  host: process.env.REACT_APP_API_HOST
})

export default function Contact(): React.JSX.Element {
  const { hero } = useHero({
    factor: 0.1
  })

  const [offices] = React.useState([
    {
      name: "Allied Container HQ",
      address: "No 6 Tuas Avenue 6 Singapore 639311",
      coordinate: {
        lat: 1.3261464,
        lng: 103.6498401
      },
    },
    {
      name: "Allied Container HQ",
      address: "No 10 Tuas Avenue 6 Singapore 639298",
      coordinate: {
        lat: 1.3265478,
        lng: 103.6501955
      },
    },
    {
      name: "Allied Container IT Office",
      address: "20 Penhas Rd, Singapore 208184",
      coordinate: {
        lat: 1.3094919,
        lng: 103.862701
      },
    }
  ])
  const [departments, setDepartments] = React.useState([
    {
      name: "Human Resources",
      selected: true,
    },
    {
      name: "Depot",
    },
    {
      name: "Trucking",
    },
    {
      name: "Warehouse",
    },
    {
      name: "General",
    },
  ])
  const [teams] = React.useState([
    {
      name: "MS JOLEAN",
      designation: "HR Manager",
      emails: ["hr@allied.com.sg"],
      telephones: ["+65 6558 6048", "+65 6558 6049"],
      mobiles: ["+65 9836 3756"],
      fax: [],
      department: new Set(["Human Resources"]),
    },
    {
      name: "MS PRIYANKA",
      designation: "Customer Service Manager",
      emails: ["priyanka@allied.com.sg"],
      telephones: ["+65 6980 8151"],
      mobiles: [],
      fax: [],
      department: new Set(["Depot"]),
    },
    {
      name: "MS SUSEE",
      designation: "Sales Executive",
      emails: ["susee@allied.com.sg"],
      telephones: ["+65 6558 6083"],
      mobiles: [],
      fax: [],
      department: new Set(["Depot"]),
    },
    {
      name: "MR FRANCIS TAN",
      designation: "General Manager",
      emails: ["francis@allied.com.sg"],
      telephones: ["+65 6558 6081"],
      mobiles: [],
      fax: [],
      department: new Set(["Depot"]),
    },
    {
      name: "MS ELAINE",
      designation: "Customer Service Manager",
      emails: ["elaine@allied.com.sg"],
      telephones: ["+65 6865 1735"],
      mobiles: [],
      fax: [],
      department: new Set(["Trucking"]),
    },
    {
      name: "MS ANN",
      designation: "Sales",
      emails: ["ann@allied.com.sg"],
      telephones: [],
      mobiles: [],
      fax: [],
      department: new Set(["Trucking", "Warehouse"]),
    },
    {
      name: "MS VIJAY",
      designation: "Customer Service Manager",
      emails: ["vijay@allied.com.sg"],
      telephones: ["+65 6558 6201"],
      mobiles: [],
      fax: [],
      department: new Set(["Warehouse"]),
    },
    {
      name: "General Line",
      emails: ["lena@allied.com.sg"],
      telephones: ["+65 6558 6079"],
      mobiles: [],
      fax: ["+65 6897 8952"],
      department: new Set(["General"]),
    },
  ])
  const [contactForm, setContactForm] = React.useState({
    loading: false,
    data: {
      companyName: "",
      location: "",
      name: "",
      email: "",
      phone: "",
      message: "",
      about: "Air Freight"
    },
    info: new Map<string, string[]>(),
    label: new Map<string, string>([
      ["companyName", "Company Name"],
      ["location", "Location"],
      ["name", "Full Name"],
      ["email", "Company Email"],
      ["phone", "Phone Number"],
      ["about", "What would you like to enquire about"],
      ["message", "Your message"],
      ["submit", "Submit"]
    ]),
    typeItems: [
      {
        label: "Air Freight",
        value: "Air Freight"
      },
      {
        label: "Cold Chain Logistics",
        value: "Cold Chain Logistics"
      },
      {
        label: "Ocean Freight (FCL & LCL)",
        value: "Ocean Freight (FCL & LCL)"
      },
      {
        label: "Warehousing & Distribution",
        value: "Warehousing & Distribution"
      },
      {
        label: "Inland Transportation",
        value: "Inland Transportation"
      },
      {
        label: "Software Solutions",
        value: "Software Solutions"
      },
      {
        label: "Other",
        value: "Other"
      }
    ],
    phoneCode: "sg"
  })
  const [contactAlert, setContactAlert] = React.useState({
    header: <></>,
    content: <></>,
    variant: AlertVariant.DANGER,
    open: false,
    closeable: true,
  })
  const [contactValidator] = useValidator({
    companyName: Validator.string().required().min(3).max(256),
    location: Validator.string().required().min(3).max(256),
    name: Validator.string().required().min(3).max(256),
    email: Validator.string().required().email().min(3).max(256),
    phone: Validator.string().phone("SG").max(17).trim(),
    about: Validator.string().required(),
    message: Validator.string().max(2048),
  })

  const recaptcha = useRecaptcha({
    siteId: process.env.REACT_APP_RECAPTCHAV3_SITE
  })

  async function sendEnquiry(data: ContactEnquiry) {
    setContactAlert((prevState) => {
      return {
        ...prevState,
        open: true,
        closeable: false,
        variant: AlertVariant.INFO,
        header: (
          <>Sending contact enquiry</>
        ),
        content: (
          <>
            <p className="italic">Please wait a moment...</p>
          </>
        )
      }
    })
    setContactForm((prevState) => {
      return {
        ...prevState,
        loading: true,
      }
    })
    const sendEnquiry = await contactService.SendEnquiry(data)
    setContactForm((prevState) => {
      return {
        ...prevState,
        loading: false,
      }
    })
    if (sendEnquiry.error) {
      setContactAlert((prevState) => {
        return {
          ...prevState,
          open: true,
          closeable: true,
          variant: AlertVariant.DANGER,
          header: (
            <>Failed send enquiry</>
          ),
          content: (
            <>
              <p className="my-2 italic">
                Please try again later or contact us directly via phone or email
              </p>
              <p><span className="font-bold">Code:</span> {sendEnquiry.error.code}</p>
              <p><span className="font-bold">Message:</span> {sendEnquiry.error.message}</p>
            </>
          )
        }
      })

      const info = sendEnquiry.error.validationErrors
      setContactForm((prevState) => {
        return {
          ...prevState,
          info,
        }
      })
      return
    }

    setContactAlert((prevState) => {
      return {
        ...prevState,
        open: true,
        closeable: true,
        variant: AlertVariant.SUCCESS,
        header: (
          <>Success send enquiry</>
        ),
        content: (
          <>Your message is being sent</>
        )
      }
    })
  }

  const handleSubmit = async ({ data }: SubmitMessage) => {
    try {
      const validateRes = contactValidator.validate(data)
      if (validateRes.error) {
        const info = validateRes.error.getIssues()

        setContactForm((prevState) => {
          return {
            ...prevState,
            info,
          }
        })
        return
      }

      const token = await recaptcha.execute("submit")
      if (!token) {
        toast.warn("Failed verify reCAPTCHA, please try again.")
        return
      }

      sendEnquiry({
        ...data,
        verificationToken: token
      })
    } catch (e: unknown) {
      const err = e as Error
      console.error(e)
      toast.error(`Unexpected error happened: ${err.message}`)
    }
  }

  const selectedDepartment = departments.find((department) => department.selected)?.name || ""
  const teamDepartment = teams.filter((team) => team.department.has(selectedDepartment))
  const headers = [
    {
      content: "Name & Info"
    }
  ]
  if (teamDepartment.filter((team) => team.emails.length > 0).length > 0) {
    headers.push({
      content: "Email"
    })
  }
  if (teamDepartment.filter((team) => team.telephones.length > 0).length > 0) {
    headers.push({
      content: "Office"
    })
  }
  if (teamDepartment.filter((team) => team.mobiles.length > 0).length > 0) {
    headers.push({
      content: "Mobile"
    })
  }
  if (teamDepartment.filter((team) => team.fax.length > 0).length > 0) {
    headers.push({
      content: "Fax"
    })
  }

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "Contact Us",
          description: "Contact us for further enquiries."
        }}
      </Meta>

      <div ref={hero} className="relative hidden lg:block w-full h-full">
        <div className="min-h-[500px] 3xl:min-h-[700px] bg-cover" style={{ backgroundImage: `url(${"/image/warehouse-checking.jpg"})` }} />
        <div className="flex w-full h-full absolute left-0 top-0 bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.95)_19.77%,_rgba(255,_255,_255,_0)_100%)]">
          <Container size="lg">
            <div className="w-full h-full flex flex-col gap-2 justify-center items-start">
              <div className="w-full max-w-[650px] flex flex-col gap-4 bg-[rgba(255,_255,_255,_0.15)] backdrop-blur p-2 rounded">
                <span className="font-manrope font-bold text-sm text-acem2-red">
                  Let's Get in Touch
                </span>
                <div className="flex flex-col gap-2.5">
                  <p className="font-bold text-2xl lg:text-4xl">
                    <span className="block text-acem2-gray">
                      Ready to Streamline Your Supply Chain? Contact Us Today!
                    </span>
                  </p>
                  <hr className="w-[100px] h-1 border-acem2-red my-2" />
                </div>
                <p className="text-lg text-acem2-gray">
                  At Allied Containers, we're passionate about helping businesses like yours achieve logistics excellence.
                  Whether you have a simple question or a complex shipment to discuss, our friendly and experienced team is here to assist you.
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="grid grid-cols-12 lg:hidden">
        <div className="col-span-12 w-full bg-cover bg-top h-[200px]" style={{ backgroundImage: `url(${"/image/warehouse-checking.jpg"})` }} />
        <div className="col-span-12">
          <Container size="md">
            <div className="w-full flex flex-col gap-2 justify-center items-start my-2">
              <span className="font-manrope font-medium text-sm text-acem2-red">
                Let's Get in Touch
              </span>
              <p className="font-medium text-3xl">
                <span className="text-acem2-gray">
                  Ready to Streamline Your Supply Chain? Contact Us Today!
                </span>
              </p>
              <hr className="w-[100px] h-1 border-acem2-red my-2" />
              <p className="text-black">
                At Allied Containers, we're passionate about helping businesses like yours achieve logistics excellence.
                Whether you have a simple question or a complex shipment to discuss, our friendly and experienced team is here to assist you.
              </p>
            </div>
          </Container>
        </div>
      </div>

      <div className="my-20">
        <Container size="lg">
          <div className="flex flex-col gap-3 xl:gap-6">
            <div className="flex flex-col gap-2">
              <span className="font-manrope font-medium text-sm text-acem2-red">
                Get to Know Your Dedicated Contact
              </span>
              <p className="font-medium !leading-[150%] text-2xl xl:text-3xl 3xl:text-4xl text-acem2-gray">
                Instantly Connect with the Right Expert for Your Needs.
              </p>
            </div>

            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 lg:col-span-8">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-6 p-4 bg-acem2-cloud">
                    <p className="font-semibold text-lg lg:text-xl text-acem2-black">
                      Choose your area of concern, and we’ll suggest the best contact for you:
                    </p>

                    <div className="flex flex-row flex-wrap gap-3 3xl:gap-6">
                      {
                        departments.map((department, i: number) => {
                          return (
                            <React.Fragment key={`department-item-${i}`}>
                              <button
                                className={
                                  "ease-out duration-500 px-4 lg:px-8 py-2.5 lg:py-2 rounded border-1 " +
                                  (department.selected ?
                                    "bg-acem2-red hover:bg-acem2-maroon text-white" :
                                    "bg-white hover:bg-[#DEDEDE] text-acem2-black border-[#72727266]"
                                  )
                                }
                                onClick={() => {
                                  setDepartments((prevState) => {
                                    return prevState.map((department, j: number) => {
                                      return {
                                        ...department,
                                        selected: (i === j)
                                      }
                                    })
                                  })
                                }}>
                                {department.name}
                              </button>
                            </React.Fragment>
                          )
                        })
                      }
                    </div>
                  </div>

                  <div className="px-4">
                    <ContactTable>
                      {{
                        headers,
                        items: teamDepartment
                      }}
                    </ContactTable>
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-4 order-first lg:order-last">
                <img alt="team" src={`/image/team-standing.jpg`}
                  className="w-full lg:max-w-[384px] object-cover object-top lg:object-center aspect-video lg:aspect-square" />
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="bg-acem2-cloud p-2">
        <Container size="lg">
          <div className="grid grid-cols-12 gap-4 my-4">
            <div className="col-span-12 lg:col-span-6">
              <div className="w-full h-full flex flex-col gap-4 justify-between items-start">
                <div className="flex flex-col gap-2">
                  <span className="font-manrope font-medium text-sm text-acem2-red">
                    Need Help or Have a Question?
                  </span>
                  <p className="font-medium !leading-[150%] text-2xl xl:text-3xl 3xl:text-4xl text-acem2-gray">
                    Chat to Our Team
                  </p>
                  <hr className="w-[100px] h-1 border-acem2-red" />
                  <p className="text-xl text-acem2-gray">
                    Get in touch with our logistic experts to supporting you and your business.
                  </p>
                </div>

                <div className="flex flex-row flex-wrap gap-4 lg:gap-6 justify-start items-center">
                  <Link to={`https://www.linkedin.com/company/allied-container-group/`} target="_blank" hideCrawl>
                    <RiLinkedinBoxFill className="block w-[28px] lg:w-[38px] h-[28px] lg:h-[38px] text-[#636363]" aria-hidden="true" />
                  </Link>
                  <Link to={`https://www.facebook.com/AlliedContainerGroup/`} target="_blank" hideCrawl>
                    <RiFacebookCircleFill className="block w-[28px] lg:w-[38px] h-[28px] lg:h-[38px] text-[#636363]" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <Alert
                variant={contactAlert.variant}
                opened={contactAlert.open}
                closeable={contactAlert.closeable}
                onClose={() => {
                  setContactAlert((prevState) => {
                    return {
                      ...prevState,
                      open: false
                    }
                  })
                }}>
                {{
                  header: contactAlert.header,
                  content: contactAlert.content
                }}
              </Alert>

              <ContactForm
                onSubmit={handleSubmit}
                typeItems={contactForm.typeItems}
                data={contactForm.data}
                info={contactForm.info}
                label={contactForm.label}
                disabled={recaptcha.loading}
                loading={contactForm.loading} />
            </div>
          </div>
        </Container>
      </div>

      <Container size="lg">
        <div className="my-10 lg:my-20">
          <div className="flex flex-col gap-2">
            <p className="font-satoshi font-medium text-acem2-gray text-2xl lg:text-4xl">
              Allied Container HQ
            </p>
          </div>

          <Loader lazy>
            <OfficeLocation
              apiKey={process.env.REACT_APP_MAP_API_KEY}
              items={offices} />
          </Loader>
        </div>
      </Container>

      <CompanyHero />
    </>
  )
}
import * as React from "react"
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api"
import { LatLng } from "./Resource"

type MapGoogleProps = {
  children?: React.ReactNode
  apiKey?: string
  mapId?: string
  zoom?: number
  center?: LatLng
  readonly?: boolean
  fullable?: boolean
  onLoad?: (() => void | Promise<void>) | undefined
}

const MapGoogle = React.memo((props: MapGoogleProps): React.JSX.Element => {
  const {
    readonly = false,
    fullable = false,
    apiKey = "",
    mapId = "",
  } = props

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
    version: "3.55"
  })

  return isLoaded ?
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "100%",
      }}
      options={{
        mapId,
        fullscreenControl: fullable,
        mapTypeControl: false,
      }}
      center={props.center}
      zoom={props.zoom}
      clickableIcons={!readonly}
      onLoad={props.onLoad}>
      {props.children}
    </GoogleMap>
    :
    <></>
})

export {
  MapGoogle
}
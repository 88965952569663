import * as React from "react"
import { ButtonLink } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { Video } from "@allied/react-web/Video"
import { useAutoPosition } from "@allied/react-web/hooks"
import { useHero } from "@allied/react-web/Header"
import { CompanyTagline } from "../features/Company/CompanyTagline"
import { ContactHero } from "../features/Contact/ContactHero"

export default function Home(): React.JSX.Element {
	const { hero } = useHero({
		factor: 0.1
	})

	const [advantages] = React.useState([
		{
			icon: `/image/bar-increase-icon.svg`,
			name: "Simplify Your Supply Chain",
			description: "Reduce complexities and optimize your logistics for faster delivery times."
		},
		{
			icon: `/image/earth-globe-icon.svg`,
			name: "We Ship It All",
			description: "From medical supplies to auto parts, we handle diverse cargo types safely and securely."
		},
		{
			icon: `/image/module-puzzle-icon.svg`,
			name: "One-Time or Long-Term",
			description: "Choose the partnership that fits your business, from single shipments to ongoing projects."
		},
		{
			icon: `/image/monitor-code-icon.svg`,
			name: "Software-Powered Solutions",
			description: "Benefit from technology that optimizes routes, manages inventory, and simplifies logistics."
		},
		{
			icon: `/image/handshake-icon.svg`,
			name: "Scalable Solutions",
			description: "Adapt your logistics as your business grows, without changing providers."
		},
		{
			icon: `/image/shipping-truck-icon.svg`,
			name: "Secure Warehousing & Handling",
			description: "Your goods are safe with our secure warehousing and experienced personnel."
		},
	])
	const [solutions] = React.useState([
		{
			slug: "transportation",
			label: "Seamless Start",
			name: "Effortless End-to-End Transportation",
			description: "We efficiently pick up your goods from the origin, sort and process them for onward travel, and consolidate them with other shipments for cost-effective transportation.",
			thumbnail: `/image/solution-1.jpg`,
			longCtaText: "End-to-End Transportation",
			shortCtaText: "End-to-End Transportation"
		},
		{
			slug: "distribution",
			label: "Sorting and Processing",
			name: "Streamlined Warehousing & Distribution",
			description: "Preparing your shipment for onward transit. This could include tasks like packaging, labeling, sorting by size or weight, and attaching any necessary documentation.",
			thumbnail: `/image/solution-2.jpg`,
			longCtaText: "Streamlined Warehousing & Distribution",
			shortCtaText: "Warehousing & Distribution"
		},
		{
			slug: "software",
			label: "Efficiency",
			name: "Software",
			description: "Logistics software services are digital tools that streamline and automate various aspects of managing your supply chain. They can be used by businesses of all sizes, from small startups to large corporations, to improve efficiency and gain better visibility into their operations.",
			thumbnail: `/image/solution-3.jpg`,
			longCtaText: "Powerful Software Solutions",
			shortCtaText: "Software Solutions"
		}
	])
	const [shipmentGoods] = React.useState([
		{
			icon: `/image/tablet-capsule-icon.svg`
		},
		{
			icon: `/image/blood-drop-icon.svg`
		},
		{
			icon: `/image/two-door-icon.svg`
		},
		{
			icon: `/image/ladder-icon.svg`
		},
		{
			icon: `/image/watermelon-icon.svg`
		},
	])
	const [services] = React.useState([
		{
			background: "bg-acem2-cloud",
			icon: `/image/container-icon.svg`,
		},
		{
			name: "Warehousing & Distribution",
		},
		{
			background: "bg-acem2-cloud",
			icon: `/image/toast-icon.svg`,
		},
		{
			name: "Industrial Supplies",
		},
		{
			background: "bg-acem2-cloud",
			icon: `/image/microwave-icon.svg`,
		},
		{
			name: "Container Leasing",
		},
		{
			background: "bg-acem2-cloud",
			icon: `/image/warehouse-icon.svg`,
		},
		{
			name: "Premium Food Produce",
		},
		{
			background: "bg-acem2-cloud",
			icon: `/image/battery-icon.svg`,
		},
		{
			name: "Kitchen Equipment",
		},
	])
	const [depotLocations] = React.useState([
		{
			icon: `/image/flag-sg-icon.svg`,
			name: "Singapore (Multiple Locations)",
		},
		{
			icon: `/image/flag-th-icon.svg`,
			name: "Thailand",
		},
		{
			icon: `/image/flag-mm-icon.svg`,
			name: "Yangon, Myanmar",
		},
		{
			icon: `/image/flag-my-icon.svg`,
			name: "Port Klang, Malaysia ",
		},
		{
			icon: `/image/flag-ae-icon.svg`,
			name: "Abu Dhabi, UAE",
		},
		{
			icon: `/image/flag-om-icon.svg`,
			name: "Sohar, Oman",
		}
	])
	const [commitments] = React.useState([
		{
			name: "Sustainable Packaging & Materials",
			thumbnail: `/image/commitment-1.png`
		},
		{
			name: "Warehousing & Storage Efficiency",
			thumbnail: `/image/commitment-2.png`
		},
		{
			name: "Minimizing Forklift Emissions",
			thumbnail: `/image/commitment-3.png`
		},
		{
			name: "Electric Vehicle Integration",
			thumbnail: `/image/commitment-4.png`
		}
	])

	React.useEffect(() => {
		const htmlElement = document.querySelector(`html`)
		if (htmlElement) {
			htmlElement.classList.add("overflow-x-hidden")
		}

		const viewportElement = document.querySelector(`meta[name="viewport"]`)
		if (viewportElement) {
			viewportElement.setAttribute("content", "width=device-width, initial-scale=1.0, minimum-scale=1.0")
		}
	}, [])

	useAutoPosition()

	return (
		<>
			<Meta disableTemplate>
				{{
					title: "Allied Container Group - Yard Operator, Haulier Operator and 3PL Provider",
					description: "Official website for Allied Containers Services and Allied Container (Engineers and Manufacturers) - Allied Group, Singapore | Largest container yard operator and container haulier | Allied depot"
				}}
			</Meta>

			{/* 
        @note: do not remove, make sure to match the html element on the 404 page
        @issue: react-snap do not handle 404 page correctly
        @ref: https://gitlab.allied.com.sg/web/acem-singapore/-/merge_requests/30
      */}
			<div></div>

			<div ref={hero} className="relative w-full h-full xl:h-[calc(100vh)] min-h-[500px] xl:min-h-[600px] 3xl:min-h-[850px]">
				<Video className="hidden sm:block w-full h-full xl:h-[calc(100vh)] min-h-[500px] xl:min-h-[600px] 3xl:min-h-[850px]"
					style={{ objectFit: "initial" }} autoPlay muted loop>
					<source src={`/video/cloudy-container.mp4`} type="video/mp4" />
				</Video>
				<Video className="block sm:hidden w-full h-[calc(100vh)] max-h-[800px]"
					style={{ objectFit: "initial" }} autoPlay muted loop>
					<source src={`/video/cloudy-container-mobile.mp4`} type="video/mp4" />
				</Video>
				<div className="py-8 xl:py-32 w-full h-full absolute left-0 top-0 bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.95)_19.77%,_rgba(255,_255,_255,_0)_100%)]">
					<Container size="md">
						<div className="w-full h-full max-w-[600px] md:max-w-[800px] 2xl:max-w-[1000px] flex flex-col gap-2 justify-center items-start">
							<p className="font-manrope font-light lg:font-medium xl:!leading-[60px] 3xl:!leading-[70px] text-4xl lg:text-5xl 3xl:text-6xl">
								<span className="block lg:inline">Bridging the gap: </span>
								<span className="text-acem2-red">Efficient middle mile solutions </span>
								<span>for a smooth </span>
								<span className="inline lg:block">supply chain</span>
							</p>
						</div>
					</Container>
				</div>
			</div>

			<Container size="lg">
				<div className="grid grid-cols-12 gap-6 my-10 lg:my-20">
					<div className="col-span-12 lg:col-span-5 lg:order-last">
						<div className="w-full h-full flex flex-col gap-4 justify-center items-start">
							<span className="font-manrope font-medium text-sm text-acem2-red">Company</span>
							<p className="font-medium text-2xl xl:text-3xl 3xl:text-4xl text-acem2-gray">
								<span className="block sm:inline xl:block">Welcome to </span>
								<span className="block sm:inline xl:block text-acem2-red">Allied Containers!</span>
								<span className="block">We simplify the complex world of logistics, empowering your business to thrive.</span>
							</p>
							<hr className="w-[100px] h-1 border-acem2-red" />
							<div className="hidden lg:flex justify-center lg:justify-start items-center">
								<ButtonLink to="/about-us" variant="primary">
									Explore Allied's Story
								</ButtonLink>
							</div>
						</div>
					</div>
					<div className="col-span-12 lg:col-span-7 ">
						<div className="flex flex-col gap-6">
							<div className="bg-no-repeat bg-cover bg-center aspect-cinema"
								style={{ backgroundImage: `url(${"/image/sonar-agreement.jpg"})` }}></div>
							<div className="lg:hidden flex justify-center items-center">
								<ButtonLink to="/about-us" variant="primary">
									Explore Allied's Story
								</ButtonLink>
							</div>
						</div>
					</div>
				</div>
			</Container>

			<div className="bg-acem2-cloud pt-6">
				<Container size="lg">
					<div className="flex flex-col gap-6">
						<div className="flex flex-col gap-2.5 my-2">
							<span className="w-full font-manrope font-medium text-acem2-red text-sm">Allied Advantage</span>
							<p className="font-satoshi font-medium text-acem2-gray text-2xl lg:text-4xl">
								40+ Years of Moving Your Business Forward
							</p>
							<hr className="w-[100px] h-1 border-acem2-red" />
						</div>
						<div className="grid grid-rows-3 lg:grid-rows-2 grid-cols-2 lg:grid-cols-3 grid-flow-col lg:grid-flow-row">
							{
								advantages.map((advantage, i: number) => {
									return (
										<React.Fragment key={`advantage-item-${i}`}>
											<div className={
												"w-full flex flex-col justify-start lg:justify-center items-start gap-2 px-2 lg:px-4 py-10 text-acem2-gray " +
												(i % 2 === 0 ? "bg-acem2-twinkle" : "bg-acem2-cloud")
											}>
												<img alt="advantage-icon" src={advantage.icon} className="w-6 h-6" />
												<p className="font-medium text-2xl">{advantage.name}</p>
												<p className="text-lg">{advantage.description}</p>
											</div>
										</React.Fragment>
									)
								})
							}
						</div>
					</div>
				</Container>
			</div>

			<CompanyTagline
				firstContent={<>
					<p className="px-4 w-full font-medium mx-auto text-center text-xl sm:text-2xl md:text-3xl xl:text-4xl">
						<span className="block">Ready to unlock efficient, </span>
						<span className="block">scalable logistics solutions?</span>
					</p>
				</>}
				lastContent={<>
					<p className="px-4 w-full font-medium mx-auto text-center text-xl sm:text-xl md:text-3xl xl:text-4xl">
						<span className="text-acem2-red">Allied Containers</span> is your one-stop solution for
						<span className="inline sm:block"> streamlined logistics that fuel your business growth.</span>
					</p>
				</>}
				transitionContent={<>
					<div className="w-full h-full flex justify-center items-center">
						<img alt="container-truck" src={`/image/container-truck.png`}
							className="w-full max-w-[512px] xl:max-w-[600px] 3xl:max-w-[768px] aspect-[21/7]" />
					</div>
				</>}
			/>

			<Container size="lg">
				<div className="flex flex-col gap-6 my-20">
					<div className="flex flex-col gap-2">
						<span className="font-manrope font-medium text-sm text-acem2-red">
							Escape the Multi-Partner Maze
						</span>
						<p className="font-medium !leading-[150%] text-2xl xl:text-3xl 3xl:text-4xl text-acem2-gray">
							We Don't Just Deliver the Middle: <span className="text-acem2-red">Allied Containers</span>, Your Complete Logistics Partner
						</p>
						<p className="text-base md:text-xl text-acem2-gray">
							While we're renowned for our efficient middle-mile expertise, <span className="text-acem2-red">Allied Containers</span> offers a comprehensive suite of logistics solutions to seamlessly manage your entire supply chain.
						</p>
					</div>
					<div>
						{
							solutions.map((solution, i: number) => {
								return (
									<React.Fragment key={`solution-item-${i}`}>
										<div className="grid grid-cols-12">
											<div className="col-span-6">
												<div className="w-full h-full px-4 lg:px-20 bg-acem2-cloud flex flex-col gap-2 justify-center items-start">
													<span className="w-full font-manrope font-medium text-acem2-red text-sm">
														{solution.label}
													</span>
													<p className="text-2xl lg:text-4xl font-medium text-acem2-gray">
														{solution.name}
													</p>
													<hr className="w-[100px] h-1 border-acem2-red" />
													<p className="hidden lg:flex text-xl py-4">
														{solution.description}
													</p>
													<ButtonLink to={`/services#${solution.slug}`} variant="primary">
														<span className="hidden lg:inline-block">
															{solution.longCtaText}
														</span>
														<span className="inline-block lg:hidden text-xs">
															{solution.shortCtaText}
														</span>
													</ButtonLink>
												</div>
											</div>
											<div className={"col-span-6 " + (i % 2 === 0 ? "order-first" : "")}>
												<img alt="solution" src={solution.thumbnail}
													className="w-full min-h-[360px] lg:min-h-[480px] object-cover aspect-video" />
											</div>
										</div>
									</React.Fragment>
								)
							})
						}
					</div>
				</div>
			</Container>

			<div className="h-[800px] lg:h-[600px] bg-cover bg-center flex flex-col justify-center items-start"
				style={{ backgroundImage: `url(${"/image/oversize-cargo.jpg"})` }}>
				<Container size="lg">
					<div className="w-full max-w-[750px] flex flex-col gap-4 md:gap-8 justify-center items-start bg-[rgba(255,_255,_255,_0.5)] p-2 rounded">
						<div className="flex flex-col gap-4">
							<span className="font-manrope font-bold text-acem2-red text-sm">
								We Ship Your World
							</span>
							<p className="text-2xl lg:text-4xl font-bold text-acem2-gray">
								Need to move something complex? Allied Containers has the expertise to handle your unique shipping needs.
							</p>
							<hr className="w-[100px] h-1 border-acem2-red" />
						</div>
						<div className="flex flex-row gap-6">
							{
								shipmentGoods.map((goods, i: number) => {
									return (
										<React.Fragment key={`goods-item-${i}`}>
											<img alt="gooods" src={goods.icon} className="w-6 h-6" />
										</React.Fragment>
									)
								})
							}
						</div>
						<ButtonLink to="/projects" variant="primary">
							Discover How We Can Help
						</ButtonLink>
					</div>
				</Container>
			</div>

			<Container size="lg">
				<div className="grid grid-cols-12 gap-6 my-20">
					<div className="col-span-12 md:col-span-6 lg:col-span-7 xl:col-span-8">
						<div className="w-full h-full flex flex-col gap-2 justify-center items-center">
							<img alt="location" src={`/image/depot-location.png`}
								className="w-full max-w-[900px] aspect-classic" />
						</div>
					</div>
					<div className="col-span-12 md:col-span-6 lg:col-span-5 xl:col-span-4">
						<div className="flex flex-col gap-8">
							<div className="flex flex-col gap-4">
								<span className="font-manrope font-medium text-sm text-acem2-red">
									Your Global Logistics Partner
								</span>
								<p className="font-medium text-2xl lg:text-3xl text-acem2-gray">
									Strategic Locations to Support Your Supply Chain
								</p>
								<hr className="w-[100px] h-1 border-acem2-red" />
							</div>
							<p className="text-lg lg:text-xl text-acem2-gray">
								Allied Containers offers a robust network of container depots across key regions, ensuring seamless logistics for your business.
							</p>
							<div className="flex flex-col gap-4">
								{
									depotLocations.map((location, i: number) => {
										return (
											<React.Fragment key={`location-item-${i}`}>
												<div className="flex flex-row gap-4 justify-start items-center">
													<img alt="location-icon" src={location.icon} className="w-9 h-6" />
													<span className="text-lg text-black">{location.name}</span>
												</div>
											</React.Fragment>
										)
									})
								}
							</div>
							<div className="flex justify-start items-center">
								<ButtonLink to="/depot-locations" variant="primary">
									Find Your Perfect Depot
								</ButtonLink>
							</div>
						</div>
					</div>
				</div>
			</Container>

			<Container size="lg">
				<div className="flex flex-col gap-8 justify-center items-start">
					<div className="flex flex-col gap-4">
						<span className="w-full font-manrope font-medium text-acem2-red text-sm">
							Our Strength: A Network of Expertise
						</span>
						<p className="text-2xl lg:text-4xl font-medium text-acem2-gray">
							We offer a seamless blend of services through our specialized subsidiaries
						</p>
						<hr className="w-[100px] h-1 border-acem2-red" />
					</div>
					<div>
						<ButtonLink to="/services" variant="primary">
							Our Sister Companies
						</ButtonLink>
					</div>
				</div>
				<div className="grid grid-flow-col grid-rows-5 lg:grid-rows-2 lg:grid-flow-row grid-cols-2 lg:grid-cols-5 my-7">
					{
						services.map((service, i: number) => {
							return (
								<React.Fragment key={`service-item-${i}`}>
									<div className={"h-36 lg:h-72 px-4 lg:px-10 py-2 flex justify-center items-center text-center " + service.background}>
										{
											service.name &&
											<span className="text-lg text-acem2-gray">
												{service.name}
											</span>
										}

										{
											service.icon &&
											<img alt="service-icon" src={service.icon}
												className={
													"w-8 h-8 md:w-12 md:h-12 mx-auto " +
													"[filter:drop-shadow(12px_19px_9px_rgba(0,_0,_0,_0.04))drop-shadow(7px_11px_8px_rgba(0,_0,_0,_0.12))drop-shadow(3px_5px_6px_rgba(0,_0,_0,_0.2))drop-shadow(1px_1px_3px_rgba(0,_0,_0,_0.24))]"
												} />
										}
									</div>
								</React.Fragment>
							)
						})
					}
				</div>
			</Container>

			<ContactHero
				title={<>
					<p className="font-medium text-xl lg:text-2xl text-acem2-gray">
						Simplify Your Supply Chain. Grow Your Business.
					</p>
				</>}
				description={<>
					<p className="text-lg text-acem2-gray">
						<span className="hidden md:flex">
							At Allied Containers, we understand that navigating the complexities of logistics can be a challenge, no matter the size or industry of your business.
							We're here to help!
						</span>
					</p>
				</>}
				ctaText={<>
					<span className="hidden md:block">Talk to a Logistics Expert Now</span>
					<span className="block md:hidden">Talk to Expert</span>
				</>}
				ctaUrl="/contact-us" />

			<Container size="lg">
				<div className="flex flex-col gap-6 justify-center items-start my-20">
					<div className="flex flex-col gap-4">
						<span className="w-full font-manrope font-medium text-acem2-red text-sm">
							Committed to a Sustainable Future
						</span>
						<p className="font-medium text-2xl lg:text-4xl text-acem2-gray">
							Go Green, Go Global: Allied Containers Delivers on Both.
						</p>
						<hr className="w-[100px] h-1 border-acem2-red" />
					</div>
					<p className="text-lg lg:text-xl text-acem2-gray">
						We're actively reducing our footprint through cleaner fuels, eco-friendly packaging, efficient warehouses, and electric forklifts. Partner with us for a sustainable supply chain.
					</p>
					<div>
						<ButtonLink to="/sustainability" variant="primary">
							Our Sustainability Initiatives
						</ButtonLink>
					</div>
					<div className="w-full grid grid-cols-12 gap-6">
						{
							commitments.map((commitment, i: number) => {
								return (
									<React.Fragment key={`commitment-item-${i}`}>
										<div className="col-span-6 lg:col-span-3">
											<div className="w-full h-full 3xl:min-h-[350px] flex flex-col justify-center items-center gap-2.5 p-4">
												<img alt="commitment" src={commitment.thumbnail}
													className="w-[100px] sm:w-[120px] xl:w-[180px] aspect-square [filter:drop-shadow(12px_19px_9px_rgba(0,_0,_0,_0.04))drop-shadow(7px_11px_8px_rgba(0,_0,_0,_0.12))drop-shadow(3px_5px_6px_rgba(0,_0,_0,_0.2))drop-shadow(1px_1px_3px_rgba(0,_0,_0,_0.24))]" />
												<p className="text-center text-lg text-black">
													{commitment.name}
												</p>
											</div>
										</div>
									</React.Fragment>
								)
							})
						}
					</div>
				</div>
			</Container>

			<div className="w-full h-[500px] bg-cover bg-no-repeat bg-center flex flex-col justify-center items-start"
				style={{ backgroundImage: `url(${`/image/team-container-field.jpg`})` }}>
				<Container size="lg">
					<div className="w-1/2 max-w-[700px] flex flex-col justify-center items-start">
						<div className="flex flex-col gap-4 bg-[rgba(255,_255,_255,_0.15)] backdrop-blur p-2 rounded">
							<span className="font-manrope font-medium text-black text-sm">
								Shape the Future of Logistics
							</span>
							<p className="text-2xl lg:text-4xl font-medium lg:font-bold text-black">
								Build Your Career with Allied Containers
							</p>
							<hr className="w-[100px] h-1 border-acem2-red" />
							<p className="hidden md:flex text-lg lg:text-2xl text-black">
								At Allied Containers, we're more than just a shipping company. We're a team of passionate individuals dedicated to innovation and excellence.
							</p>
							<div>
								<ButtonLink to="/careers" variant="primary">
									Explore All Openings
								</ButtonLink>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</>
	)
}
import * as React from "react"
import { Accordion } from "@allied/react-web/Accordion"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { Link } from "@allied/react-web/Router"
import { useAutoPosition } from "@allied/react-web/hooks"
import { useParams } from "react-router-dom"
import { ContactHero } from "../features/Contact/ContactHero"
import { Service } from "../services/static/Service"
import { Info } from "../components/Page"

type ServiceItem = {
  slug: string
  name: string
  shortName?: string
  description?: string
  thumbnail?: string
  primary?: string
  icon: string
  darkIcon: string
  categories: string[]
  meta: Map<string, any>
  advantages: {
    name: string
    description: string
  }[]
}

type ObjectItem<T> = T & {
  hovered: boolean
}

const staticService = new Service()

export default function ServiceDetail(): React.JSX.Element {
  const { slug } = useParams()
  const [service, setService] = React.useState<ObjectItem<ServiceItem>>(null)
  const [services, setServices] = React.useState<ObjectItem<ServiceItem>[]>([])

  React.useEffect(() => {
    async function getService() {
      const getService = await staticService.GetService({
        slug
      })
      if (getService.error) {
        return
      }

      setService({
        ...getService.data,
        hovered: false
      })
    }

    getService()
  }, [slug])

  React.useEffect(() => {
    async function searchService(categories: string[]) {
      const searchService = await staticService.SearchService({
        categories
      })
      if (searchService.error) {
        return
      }

      const services: ObjectItem<ServiceItem>[] = [
        ...searchService.data.items.filter(item => item.slug !== service.slug),
        {
          slug: ``,
          icon: `/image/gift-media-icon.svg`,
          darkIcon: `/image/gift-media-icon-dark.svg`,
          name: `All Services`,
          advantages: [],
          categories: [],
          meta: new Map(),
        }
      ].map((item) => {
        return {
          ...item,
          hovered: false
        }
      })
      setServices(services)
    }

    if (!service) {
      return
    }
    searchService(service.categories)
  }, [service])

  useAutoPosition()

  return (
    <>
      {
        !service &&
        <>
          <Meta>
            {{
              title: "404 Page not found",
            }}
          </Meta>

          <Info />
        </>
      }

      {
        service &&
        <>
          <Meta>
            {{
              title: `${service.name}`,
              description: `${service.description || service.name}`
            }}
          </Meta>

          <div className="my-10 md:my-20">
            <Container size="lg">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 lg:col-span-6">

                  <div className="flex flex-col gap-12">
                    <div className="w-full flex flex-col gap-2">
                      <p className="font-medium text-2xl lg:text-3xl 2xl:text-4xl text-acem2-gray">
                        {service.name}
                      </p>
                      <hr className="w-[100px] h-1 border-acem2-red" />
                      {
                        service.description &&
                        <p className="font-medium text-lg lg:text-xl text-acem2-gray">
                          {service.description}
                        </p>
                      }
                      {
                        service.primary &&
                        <img alt="service" src={service.primary}
                          className="block lg:hidden object-cover object-center w-full h-[350px] md:h-full" />
                      }
                    </div>

                    {
                      service.advantages.length > 0 &&
                      <div>
                        <div className="hidden lg:flex flex-col gap-5 justify-between">
                          {
                            service.advantages.map((advantage, i: number) => {
                              return (
                                <React.Fragment key={`advantage-item-${i}`}>
                                  <div className="flex flex-col gap-1">
                                    <p className="font-medium text-xl text-acem2-gray">
                                      {advantage.name}
                                    </p>
                                    <p className="text-xl text-acem2-gray"
                                      dangerouslySetInnerHTML={{
                                        __html: advantage.description
                                      }}>
                                    </p>
                                  </div>
                                </React.Fragment>
                              )
                            })
                          }
                        </div>
                        <div className="flex flex-col gap-2 lg:hidden">
                          <Accordion size="md">
                            {{
                              items: service.advantages.map((advantage, i: number) => {
                                return {
                                  header: <>
                                    <p className="font-medium text-xl">
                                      {advantage.name}
                                    </p>
                                  </>,
                                  content: <>
                                    <p className="text-xl"
                                      dangerouslySetInnerHTML={{
                                        __html: advantage.description
                                      }}>
                                    </p>
                                  </>,
                                  opened: i === 0,
                                }
                              })
                            }}
                          </Accordion>
                        </div>
                      </div>
                    }

                    {
                      service.meta.has("note") &&
                      <div className="flex flex-col gap-2 my-2 text-acem2-gray" dangerouslySetInnerHTML={{ __html: service.meta.get("note") }}></div>
                    }
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-6">
                  {
                    service.primary &&
                    <img alt="service" src={service.primary}
                      className="hidden lg:block object-cover object-center w-full h-[350px] md:h-full max-h-[650px]" />
                  }
                </div>
              </div>
            </Container>
          </div>

          {
            service.meta.has("ctaTitle") &&
            <ContactHero
              title={<>
                <p className="font-medium text-lg xl:text-xl 2xl:text-3xl text-acem2-gray">
                  {service.meta.get("ctaTitle")}
                </p>
              </>}
              ctaText="Contact Us"
              ctaUrl="/contact-us" />
          }

          <div className="my-10 md:my-20">
            <Container size="lg">
              <div className="flex flex-col gap-4">
                <p className="text-lg xl:text-xl 3xl:text-2xl text-acem2-gray">
                  Other Services
                </p>
                <div className="grid grid-cols-12 gap-2 justify-between items-start">
                  {
                    services.map((service, i: number) => {
                      return (
                        <React.Fragment key={`service-item-${i}`}>
                          <div className="col-span-6 md:col-span-4 xl:col-span-2 2k:col-span-1">
                            <div className={
                              "w-full xl:max-w-[180px] 3xl:max-w-[200px] h-[160px] p-2 flex flex-col gap-2 justify-center items-center " +
                              "rounded border-1 border-[rgba(254,_24,_16,_0.15)] " +
                              (service.hovered ? "bg-acem2-primary" : "")
                            }
                              onMouseEnter={() => {
                                setServices((prevState) => {
                                  return prevState.map((item, j: number) => {
                                    return {
                                      ...item,
                                      hovered: (i === j ? true : item.hovered)
                                    }
                                  })
                                })
                              }}
                              onMouseLeave={() => {
                                setServices((prevState) => {
                                  return prevState.map((item, j: number) => {
                                    return {
                                      ...item,
                                      hovered: (i === j ? false : item.hovered)
                                    }
                                  })
                                })
                              }}>
                              <img alt="service" src={service.hovered ? service.darkIcon : service.icon} className="w-6 h-6" />
                              <Link to={`/services/${service.slug}`}
                                className={
                                  "text-lg text-center " +
                                  (service.hovered ? "text-white" : "text-acem2-black")
                                }>
                                {service.shortName || service.name}
                              </Link>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              </div>
            </Container>
          </div>
        </>
      }
    </>
  )
}
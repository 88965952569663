import * as React from "react"
import { Button } from "@allied/react-web/Button"
import { Radio } from "@allied/react-web/Check"
import { Field, FieldNotif } from "@allied/react-web/Field"
import { InputEmail, InputPhone, InputText } from "@allied/react-web/Input"
import { Textarea } from "@allied/react-web/Textarea"

type ContactFormProps = {
  disabled?: boolean
  loading?: boolean
  data: ContactFormData
  info: Map<string, string[]>
  label: Map<string, string>
  typeItems?: TypeItem[]
  phoneCode?: string
  onFieldBlur?: (m: BlurMessage) => void
  onSubmit?: (m: SubmitMessage) => void
}

type BlurMessage = {
  field: string
}

export type SubmitMessage = {
  data: ContactFormData
}

type ContactFormData = {
  companyName: string
  location: string
  name: string
  phone: string
  email: string
  about: string
  message: string
}

type TypeItem = {
  label: string
  value: string
}

export function ContactForm(props: ContactFormProps): React.JSX.Element {
  const {
    disabled = false,
    loading = false,
    info = new Map(),
    label = new Map(),
    typeItems = [],
    phoneCode = "sg",
    data: inputData,
    onFieldBlur = () => { },
    onSubmit = () => { },
  } = props

  const [about, setAbout] = React.useState({
    items: [] as TypeItem[]
  })
  const [data, setData] = React.useState({
    verificationToken: "",
    companyName: "",
    name: "",
    location: "",
    phone: "",
    email: "",
    message: "",
    about: "",
  })

  function updateData(field: string, value: unknown) {
    setData((prevState) => {
      return {
        ...prevState,
        [field]: value
      }
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target

    updateData(name, value)
  }

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    const { name } = e.target

    onFieldBlur && onFieldBlur({
      field: name
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    onSubmit && onSubmit({
      data
    })
  }

  React.useEffect(() => {
    setData((prevState) => {
      return {
        ...prevState,
        companyName: inputData.companyName,
        location: inputData.location,
        name: inputData.name,
        email: inputData.email,
        phone: inputData.phone,
        message: inputData.message,
        about: inputData.about,
      }
    })
  }, [inputData])

  React.useEffect(() => {
    setAbout((prevState) => {
      return {
        ...prevState,
        items: typeItems
      }
    })
  }, [typeItems])

  return (
    <>
      <form method="POST" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-2">
          <Field>
            <label htmlFor="name" className="text-acem2-gray text-lg">
              {label.get("name")}
              <span className="text-red-400">*</span>
            </label>
            <InputText
              id="name" name="name"
              placeholder="Your Name"
              value={data.name}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              disabled={loading || disabled}
              required />
            {
              info.has("name") && info.get("name").length > 0 &&
              <FieldNotif>{info.get("name")[0]}</FieldNotif>
            }
          </Field>

          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 lg:col-span-6">
              <label htmlFor="companyName" className="text-acem2-gray text-lg">
                {label.get("companyName")}
                <span className="text-red-400">*</span>
              </label>
              <InputText
                id="companyName" name="companyName"
                placeholder="Company Name"
                value={data.companyName}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                disabled={loading || disabled}
                required />
              {
                info.has("companyName") && info.get("companyName").length > 0 &&
                <FieldNotif>{info.get("companyName")[0]}</FieldNotif>
              }
            </div>
            <div className="col-span-12 lg:col-span-6">
              <label htmlFor="email" className="text-acem2-gray text-lg">
                {label.get("email")}
                <span className="text-red-400">*</span>
              </label>
              <InputEmail
                id="email" name="email"
                placeholder="Company Email"
                value={data.email}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                disabled={loading || disabled}
                required />
              {
                info.has("email") && info.get("email").length > 0 &&
                <FieldNotif>{info.get("email")[0]}</FieldNotif>
              }
            </div>
          </div>

          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 lg:col-span-6">
              <label htmlFor="location" className="text-acem2-gray text-lg">
                {label.get("location")}
                <span className="text-red-400">*</span>
              </label>
              <InputText
                id="location" name="location"
                placeholder="Location"
                value={data.location}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                disabled={loading || disabled}
                required />
              {
                info.has("location") && info.get("location").length > 0 &&
                <FieldNotif>{info.get("location")[0]}</FieldNotif>
              }
            </div>
            <div className="col-span-12 lg:col-span-6">
              <label htmlFor="phone" className="text-acem2-gray text-lg">
                {label.get("phone")}
              </label>
              <InputPhone
                id="phone" name="phone"
                placeholder="Phone Number"
                value={data.phone}
                onUpdate={(m) => {
                  updateData("phone", m.phone_number)
                }}
                onBlur={handleInputBlur}
                defaultCountry={phoneCode}
                disabled={loading || disabled} />
              {
                info.has("phone") && info.get("phone").length > 0 &&
                <FieldNotif>{info.get("phone")[0]}</FieldNotif>
              }
            </div>
          </div>

          <Field>
            <label htmlFor="type" className="text-acem2-gray text-lg">
              {label.get("about")}
              <span className="text-red-400">*</span>
            </label>
            <div className="grid grid-cols-12 gap-2">
              {
                about.items.map((item, i: number) => {
                  return (
                    <React.Fragment key={`type-${i}`}>
                      <div className="col-span-6">
                        <Radio
                          id={`type-${i}`}
                          name="about"
                          value={item.label}
                          label={item.label}
                          checked={data.about === item.value}
                          onChange={(e) => {
                            updateData("about", e.target.value)
                          }}
                          onBlur={handleInputBlur}
                          disabled={loading || disabled}
                          required />
                      </div>
                    </React.Fragment>
                  )
                })
              }
            </div>
          </Field>

          <Field>
            <label htmlFor="message" className="text-acem2-gray text-lg">
              {label.get("message")}
            </label>
            <Textarea
              id="message" name="message"
              placeholder="Your Message"
              value={data.message}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              disabled={loading || disabled} />
            {
              info.has("message") && info.get("message").length > 0 &&
              <FieldNotif>{info.get("message")[0]}</FieldNotif>
            }
          </Field>

          <div className="flex flex-col gap-2 justify-center items-center md:items-start md:my-2">
            <Button type="submit" variant="primary" size="lg">
              {label.get("submit")}
            </Button>
          </div>
        </div>
      </form>
    </>
  )
}
import * as React from "react"
import {
	RouteProps, Route,
	Navigate, NavigateProps,
	generatePath, useParams
} from "react-router-dom"
import loadable from "@loadable/component"
import { Spinner } from "@allied/react-web/Spinner"

import Guest from "./layouts/Guest"
import Login from "./layouts/Login"
import Home from "./pages/Home"
import Story from "./pages/Story"
import DepotLocation from "./pages/DepotLocation"
import Company from "./pages/Company"
import Qualification from "./pages/Qualification"
import Service from "./pages/Service"
import ServiceDetail from "./pages/ServiceDetail"
import Project from "./pages/Project"
import Career from "./pages/Career"
import JobDetail from "./pages/JobDetail"
import Sustainability from "./pages/Sustainability"
import Contact from "./pages/Contact"
import Tool from "./pages/Tool"
import NotFound from "./pages/NotFound"

const Loader = <Spinner size="md" appendClassNames="m-4" labeled />
const Page = loadable((props: { name: string }) => import(`./pages/${props.name}`), {
	cacheKey: (props) => props.name,
	fallback: Loader
})

const NavigateParams = ({ to, ...props }: NavigateProps) => {
	const params = useParams()
	return <Navigate {...props} to={generatePath(to.toString(), params)} />
}

const guests: RouteProps[] = [
	{ path: "/", element: <Home /> },
	{ path: "/about-us", element: <Story /> },
	{ path: "/depot-locations", element: <DepotLocation /> },
	{ path: "/sister-companies", element: <Company /> },
	{ path: "/awards-iso-certifications", element: <Qualification /> },
	{ path: "/services", element: <Service /> },
	{ path: "/services/:slug", element: <ServiceDetail /> },
	{ path: "/projects", element: <Project /> },
	{ path: "/careers", element: <Career /> },
	{ path: "/jobs", element: <NavigateParams replace to="/careers" /> },
	{ path: "/jobs/:slug", element: <JobDetail /> },
	{ path: "/sustainability", element: <Sustainability /> },
	{ path: "/contact-us", element: <Contact /> },
	{ path: "/tools", element: <Tool /> },
	{ path: "*", element: <NotFound /> },
]

const logins: RouteProps[] = [
	{ path: "/haulier-login", element: <Page name="HaulierLogin" /> },
	{ path: "/truckersubcon-login", element: <Page name="TruckerLogin" /> },
	{ path: "/internal-login", element: <Page name="InternalLogin" /> },
]

export const routes: RouteProps[] = [
	{
		path: "/",
		element: <Guest />,
		children: guests.map((route, i: number) => {
			return <Route {...route} key={`guest-item-${i}`} />
		})
	},
	{
		path: "/",
		element: <Login />,
		children: logins.map((route, i: number) => {
			return <Route {...route} key={`login-item-${i}`} />
		})
	},
]
